import React, { useEffect, useState } from "react";
import "./Home.css";

import "@splidejs/splide/dist/css/splide.min.css";
import Latestnews from "../LatestNews/LatestNews";
import OfflineFooter from "../Footer/Footer";
import OfflineNavbar from "../navbar/navbar";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Image } from "primereact/image";
import offline1 from "../../asserts/offline1.png";
import offline2 from "../../asserts/offline2.png";
import IIT from "../../asserts/IIT.png";
import classroom from "../../asserts/offlineclassroom1.png";
import { Button } from "primereact/button";
import userback from "../../asserts/usertestimonialsback.png";
import userbacksmall from "../../asserts/userbacksmall.png";
import userfront from "../../asserts/usertestimonials.png";
import qstn from "../../asserts/qstnaskedimg.png";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Accordion, AccordionTab } from "primereact/accordion";
import { fetchHomePageoffline } from "../../Redux/Offline/OfflineAction/OfflineHome.action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { offlineDynamicCourseApi } from "../../Api";
import Feedbackimg1 from "../../asserts/offlinetestimonial1.png";
import Feedbackimg2 from "../../asserts/offlinetestimonial2.png";
import Feedbackimg3 from "../../asserts/offlinetestimonial3.png";
import { AddToCartFunction } from "../../Redux/SelfLearning/SelfAction/Cart.action";
import brain from "../../brain.gif";
// const arr=[1,2,3,4,5]
export const arr = [
  {
    id: "0",
    image: Feedbackimg1,
    name: "Raghu Nandan",
    profession: "Data Scientist",
    comment:
      "I have got an opportunity to learn many technical aspects of Data Science & Artificial Intelligence. Course curriculum is covering most of technologies which are important to become Data Scientist. Generate 6 similar random reviews with indian names.",
  },
  {
    id: "1",
    image: Feedbackimg2,
    name: "Sagar Chiluka",
    profession: "Data Scientist",
    comment:
      "Aspiring Data Scientist, the BrainyBits program is a goldmine. Real-world projects honed my skills, making interviews effortless. The comprehensive curriculum covers the pivotal technologies needed for a successful career in Data Science.",
  },
  {
    id: "2",
    image: Feedbackimg3,
    name: "Naresh S",
    profession: "AI Enthusiast",
    comment:
      "The Data Science & AI program at BrainyBits is exceptional. Real-time projects were a game-changer for me. The curriculum covers crucial technologies, making job interviews a breeze. Grateful for this enriching opportunity!",
  },
  {
    id: "3",
    image: Feedbackimg1,
    name: "Salman MD",
    profession: "Data Science Enthusiast",
    comment:
      "BrainyBits' Data Science & AI program is top-notch. Real projects gave me the edge in interviews. The curriculum is thorough, covering essential technologies. This opportunity was a career-defining move for me.",
  },
  {
    id: "4",
    image: Feedbackimg2,
    name: "Ramesh Deva",
    profession: "Tech Enthusiast",
    comment:
      "The Data Science & AI program at BrainyBits is a comprehensive learning experience. Real projects were instrumental in my interview success. The curriculum encompasses vital technologies, making it an ideal choice for aspiring Data Scientists.",
  },
  {
    id: "5",
    image: Feedbackimg3,
    name: "Pooja Singh",
    profession: "Data Science Engineer",
    comment:
      "BrainyBits' Data Science & AI program is exceptional. Real projects added practical depth, making interviews a breeze. The curriculum covers key technologies, setting a strong foundation for a successful career in Data Science.",
  },
];

export default function Home() {
  const [array, setArray] = useState([]);
  const [active, setActive] = useState(0);
  const [seemoreurl, setseemoreurl] = useState("");
  const { data, loading } = useSelector((store) => store.offlinehome);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchHomePageoffline());
  }, []);
  useEffect(() => {
    if (data.Popular_Categories) {
      click(0, data.Popular_Categories[0]);
    }
  }, [data]);
  const [loader, setLoader] = useState(false);
  const click = async (ind, el) => {
    setActive(ind);
    setseemoreurl(el);
    setLoader(true);
    try {
      let payload = { environment: "dev", category: el };
      let res = await axios.post(offlineDynamicCourseApi, payload);
      console.log(res.data);
      setLoader(false);
      setArray(res.data);
    } catch (e) {}
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CartAddfunction = (id, el) => {
    // console.log(id,el);
    dispatch(AddToCartFunction(id, { ...el, course_type: "OP" }));
    navigate("/office-classes/cart");
  };
  return (
    <>
      <OfflineNavbar background={"white"} />
      <br />
      <br />
      {loading == true || data.length == 0 ? (
        <div className="hidden md:flex justify-content-center align-items-center h-25rem">
          <img
            src={brain}
            width={80}
            className=" m-auto   hidden  md:block"
          ></img>
        </div>
      ) : (
        <div className="hidden md:block">
          <div className="flex flex-column pl-8 pr-8 ">
            <div className="flex w-full justify-content-between pl-4">
              <div className="firstImageDiv pt-5">
                <div
                  className=""
                  style={{
                    backgroundColor: "#0E8E60",
                    borderRadius: "1000px",
                    overflow: "hidden",
                  }}
                >
                  <Image src={offline1} style={{ height: "100%" }} />
                </div>
              </div>
              <div className="flex flex-column mt-6 w-6 pt-8 ">
                <h5 className="offlinetext1 mt-7 mb-0">
                  Learning Technologies in Digital Era
                </h5>
                <h3 className="offlinetext2 mt-1">
                  Find out the
                  <span className="offlinetext2_green">
                    {" "}
                    Best Office <br /> Programs
                  </span>{" "}
                  and Learn
                </h3>
              </div>
              <div className="pr-7 pt-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </div>
            </div>
            <div className="flex w-full justify-content-between">
              <div className="pl-8 pt-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </div>
              <div className="flex flex-column gap-1  w-6 pb-8 ml-8 pl-5 ">
                <h6 className="offlinetext3 mt-0 ">
                  Empower students with BrainyBits' internship-oriented office
                  programs. Learn cutting-edge technologies, master
                  industry-standard software, and bridge classroom knowledge
                  with real-world application. Our dynamic curriculum prepares
                  students for successful careers through hands-on experience
                  and innovation in learning.{" "}
                </h6>
                <Button
                  className="offlineexplbtn mt-3"
                  onClick={() => navigate("/office-classes/courses")}
                >
                  Explore Courses
                </Button>
              </div>
              <div className="firstImageDiv pb-8 -mt-6">
                <div
                  className="-mt-6"
                  style={{
                    backgroundColor: "#0E8E60",
                    borderRadius: "1000px",
                    overflow: "hidden",
                  }}
                >
                  <Image src={offline2} />
                </div>
              </div>
            </div>
          </div>
          <div className="pl-8 pr-8">
            <h5 className="offlinetext4 mb-4">
              Office <span className="offlinetext4_2">Programs Courses</span>
            </h5>
            <div className="w-full flex justify-content-between">
              <div className="w-3 flex flex-column gap-2 ">
                {/* <span className={`${active==1?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(1)}>Popular Courses {active==1?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==2?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(2)}>Development & IT {active==2?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==3?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(3)}>AI Services {active==3?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==4?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(4)}>Design & Creative {active==4?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==5?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(5)}>Data scientists {active==5?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==6?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(6)}>Artificial Intelligence {active==6?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span>
    <span className={`${active==7?"offlineprgmcrs1":"offlineprgmcrs2"} text-left flex align-items-center justify-content-between w-10 cursor-pointer`} onClick={()=>setActive(7)}>Financial Services {active==7?"":<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M1 11L6 6L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>}</span> */}
                {data &&
                  data.Popular_Categories?.map((el, ind) => (
                    <span
                      className={`${
                        active == ind ? "offlineprgmcrs1" : "offlineprgmcrs2"
                      } text-left flex align-items-center justify-content-between w-10 cursor-pointer`}
                      onClick={() => click(ind, el)}
                    >
                      {el}
                      {active == ind ? (
                        ""
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                        >
                          <path
                            d="M1 11L6 6L1 1"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  ))}
                <br /> <br />
                <svg
                  className="ml-6 mt-7"
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </div>
              {loader ? (
                <div className="w-9 flex justify-content-center align-items-center h-20rem">
                  <img
                    src={brain}
                    width={80}
                    className=" m-auto   hidden  md:block"
                  ></img>
                </div>
              ) : (
                <div className="w-9 flex flex-wrap gap-5">
                  {array?.slice(0, 6).map((el) => (
                    <div className="offlinecoursediv flex flex-column cursor-pointer">
                      <div
                        className="offlinecoursedivone"
                        onClick={() =>
                          navigate(
                            `/office-classes/individual-courses/${el.course_id}`
                          )
                        }
                      >
                        <img
                          src={el.course_photo}
                          style={{
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="flex flex-column px-3 p-2">
                        <div
                          className="flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                        >
                          <span
                            className="offlinecllgname"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* {el.instructor_name} */}
                          </span>
                          <span className="offlinecllgrating flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55"
                              height="11"
                              viewBox="0 0 55 11"
                              fill="none"
                            >
                              <path
                                d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 0
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 1
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 2
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 3
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 4
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                            </svg>
                            ({el.num_of_ratings})
                          </span>
                        </div>
                        <h5
                          className="offlinecllgcourse text-left mt-2 mb-0 flex align-items-center"
                          style={{ minHeight: "52px" }}
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                        >
                          {el.course_name.length > 45
                            ? `${el.course_name.substring(0, 50)}...`
                            : el.course_name}
                        </h5>
                        <h6
                          className="offlinecourseduration text-left mt-1 mb-2"
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {el.office_program_location}
                        </h6>
                        {/* <h4 className='offlinecoursefee text-left mt-1 mb-0' onClick={()=>navigate(`/office-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</h4> */}
                        {/* <Button className='offlinecllgcrsbtn mb-0 flex justify-content-center align-items-center'>Buy Now</Button> */}
                        <div className="flex justify-content-between align-items-center">
                          <span
                            className="tabprice"
                            onClick={() =>
                              navigate(
                                `/office-classes/individual-courses/${el.course_id}`
                              )
                            }
                          >
                            ₹ {el.course_cost}
                          </span>
                          <Button
                            className="tabbuybtn"
                            onClick={() => CartAddfunction(el.course_id, el)}
                          >
                            Buy now
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  {array && array.length == 0 ? (
                    <h5 className="offlinecllgcourse text-center mt-2 mb-0">
                      Courses Coming Soon
                    </h5>
                  ) : (
                    <h5
                      className="w-10 text-right categoriesSeeMorebtn0 mt-2 "
                      onClick={() =>
                        navigate(`/office-classes/categories/${seemoreurl}`)
                      }
                    >
                      See more
                    </h5>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex mt-8 gap-6 pl-8 pr-8">
            <div className="w-5">
              <img
                src={classroom}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div
              className="flex flex-column w-6"
              style={{ overflow: "hidden" }}
            >
              <span className="flex justify-content-end ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </span>
              <span className="offlinetext8">There are more reasons</span>
              <h3 className="offlinetext9 mt-2 mb-3">
                Why Choose{" "}
                <span className="offlinetext9_2">Office Courses?</span>
              </h3>
              <span className="offlinetext11">
                Opt for office courses to experience personalized learning in a
                traditional classroom setting. Benefit from face-to-face
                interactions with experienced instructors, engage in
                collaborative discussions, and build a supportive network.{" "}
              </span>
              <Button
                className="offlinefreebtn mt-4"
                onClick={() => navigate("/office-classes/contact-us")}
              >
                Free Mentorship
              </Button>
            </div>
          </div>
          <div className="flex mt-8 gap-6 pl-8 pr-8">
            <div
              className="flex flex-column w-6"
              style={{ overflow: "hidden" }}
            >
              <span className="flex justify-content-end ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </span>
              <span className="offlinetext8">Explore Office Courses </span>
              <h3 className="offlinetext9 mt-2 mb-3">
                Why Choose <span className="offlinetext9_2">BrainyBits?</span>
              </h3>
              <span className="offlinetext11">
                BrainyBits provides an unparalleled educational experience. We
                provide real time internship experience letter to enable you
                humongous opportunities.{" "}
              </span>
              <Button
                className="offlinefreebtn mt-4"
                onClick={() => navigate("/office-classes/about-us")}
              >
                Learn More
              </Button>
            </div>
            <div className="w-5">
              <img
                src={classroom}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="mb-5">
            <h5 className="offlinetext4_2 text-left mb-3  pl-8">
              Users <span className="offlinetext4">Testimonial</span>
            </h5>

            <Splide
              hasTrack={false}
              className=" "
              options={{
                // type: 'loop',
                rewind: false,
                autoplay: true,
                perMove: 1,
                perPage: 1,
                gap: "2rem",
                arrows: true,
                pagination: true,
              }}
            >
              <SplideTrack>
                {arr.map((el) => (
                  <SplideSlide className="h-30rem">
                    <div className="flex offlinereviews gap-4 mt-7">
                      <div
                        className="w-4 flex justify-content-center align-items-center  ml-3"
                        style={{
                          backgroundImage: `url(${userback})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center ",
                          backgroundSize: "70% ",
                        }}
                      >
                        <img src={el.image} width={"60%"} />
                      </div>
                      <div className="w-8 flex flex-column ml-8 pt-6">
                        <span className="usernaam">{el.name}</span>
                        <span className="userdesg pl-1">{el.profession}</span>
                        <p className="userdesc w-7 pl-1">{el.comment}</p>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>

              <div className="splide__arrows">
                <button
                  className="splide__arrow splide__arrow--prev shadow-3 ml-7 nextbtn"
                  style={{ marginTop: "200px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3rem w-15rem"
                    width="400px"
                    height="400px"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M25.1175 31.4306L28.3311 34.6443L37.9766 25.0011L28.3334 15.3579L25.1197 18.5738L29.272 22.7284H12.1425V27.2738H29.272L25.1175 31.4306Z"
                      fill="#0E8E60"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z"
                      fill="#0E8E60"
                    />
                  </svg>
                </button>
                <button
                  className="splide__arrow splide__arrow--next shadow-3 mr-7 nextbtn"
                  style={{ marginTop: "200px" }}
                >
                  <svg
                    className="h-5rem w-15rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M25.1175 31.4302L28.3311 34.6438L37.9766 25.0006L28.3334 15.3574L25.1197 18.5733L29.272 22.7279H12.1425V27.2733H29.272L25.1175 31.4302Z"
                      fill="#0E8E60"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z"
                      fill="#0E8E60"
                    />
                  </svg>
                </button>
              </div>
            </Splide>
          </div>
          <div className="pl-8 pr-8">
            <h5 className="offlinetext4_2 mb-0 text-left ">
              Frequently <span className="offlinetext4">Asked Questions</span>
            </h5>
            <div className="flex justify-content-betwen align-items-center">
              <span className="qstnshrtdesc w-4">
              These questions were asked frequently by students.
              </span>
              <span className="flex justify-content-end w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="142"
                  height="107"
                  viewBox="0 0 142 107"
                  fill="none"
                >
                  <circle cx="14" cy="3" r="3" fill="#24FF00" />
                  <circle cx="7" cy="93" r="7" fill="#FF0000" />{" "}
                  <circle cx="135" cy="100" r="7" fill="#0DB760" />{" "}
                  <circle cx="78" cy="50" r="3" fill="#0047FF" />{" "}
                  <circle cx="88" cy="13" r="7" fill="#F9F047" />
                </svg>
              </span>
            </div>
            <div className="w-full flex ">
              {/* <div className='w-6 flex flex-column' >
  <div className="card">
  <Accordion className="border-round-lg accordion-containeroffline "     expandIcon = "pi pi-plus custom-expand-iconoffline" collapseIcon = "pi pi-minus custom-expand-iconoffline" >
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
                <AccordionTab  header={<span className="acrhdroffline">Lorem ipsum marskaum teskna iusm sedren openka?</span>}  className=" accordion-taboffline "   >
                </AccordionTab>
            </Accordion>
        </div>
        
  </div> */}
              <div className="w-6 flex flex-column">
                <div className="card">
                  <Accordion
                    className="border-round-lg accordion-containeroffline "
                    expandIcon="pi pi-plus custom-expand-iconoffline"
                    collapseIcon="pi pi-minus custom-expand-iconoffline"
                  >
                    <AccordionTab
                      header={
                        <span className="acrhdroffline flex text-left">
                          1. Which services BrainyBits provides?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans">
                        BrainyBits provides three different kind of learning
                        modules. Self-learning resources, online live classes,
                        Additionally, we offer office job-oriented internship
                        learning programs. Allowing learners to access all kinds
                        of software technology learning courses in various
                        fields.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline flex text-left">
                          2. Why should I choose BrainyBits?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans">
                        BrainyBits offers the convenience of learning from
                        anywhere with an internet connection, expert-led
                        courses, and internship programs that prepare you for
                        real-world job opportunities. Our platform is designed
                        to provide a rich and effective learning experience.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline flex text-left">
                          3. What are the Learning Courses available ?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans">
                        BrainyBits offers a wide range of learning courses,
                        including but not limited to IT Software, Data Science,
                        Artificial Intelligence, Design and more. We cover
                        diverse topics to cater to your learning needs. Find
                        more details in courses page of each module of learning.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline flex text-left">
                          4. What are my prerequisites to learn over BrainyBits
                          platform?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans">
                        Prerequisites may vary depending on the specific course
                        you choose. However, for most courses, a basic
                        understanding of English language and mobile/computer
                        device is beneficial. Detailed prerequisites are usually
                        provided in the course descriptions.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline flex text-left">
                          5. Why only BrainyBits?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans">
                        BrainyBits stands out due to its comprehensive course
                        offerings, expert instructors, flexible learning
                        options, and office job-oriented internship programs. We
                        are committed to delivering high-quality education and
                        helping you acquire practical skills for success in your
                        chosen field. Choose BrainyBits for a rewarding learning
                        experience.
                      </p>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
              <div className="w-6">
                <img src={qstn} width={"70%"} style={{ objectFit: "cover" }} />
                <span style={{ marginRight: "55%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="142"
                    height="107"
                    viewBox="0 0 142 107"
                    fill="none"
                  >
                    <circle cx="14" cy="3" r="3" fill="#24FF00" />
                    <circle cx="7" cy="93" r="7" fill="#FF0000" />
                    <circle cx="135" cy="100" r="7" fill="#0DB760" />
                    <circle cx="78" cy="50" r="3" fill="#0047FF" />
                    <circle cx="88" cy="13" r="7" fill="#F9F047" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading == true || data.length == 0 ? (
        <div className="flex justify-content-center align-items-center h-25rem">
          <img src={brain} width={80} className="md:hidden m-auto"></img>
        </div>
      ) : (
        <div className="block md:hidden">
          <div className="flex flex-column pl-4 pr-4 w-full">
          <div className="flex justify-content-center align-items-center gap-6 ">
              <div className="w-4 flex flex-column">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="47"
                  viewBox="0 0 56 47"
                  fill="none"
                >
                  <circle cx="7" cy="2" r="2" fill="#24FF00" />
                  <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                  <circle cx="51.5" cy="42.5" r="4.5" fill="#0DB760" />
                  <circle cx="29" cy="23" r="2" fill="#0047FF" />
                  <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                </svg>
              </div>
              <div className="w-full firstImageDiv0 pt-3 ">
                <div
                  className="fID0"
                  style={{
                    backgroundColor: "#0E8E60",
                    borderRadius: "1000px",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Image src={offline1} />
                </div>
              </div>
                </div>
                <div className="w-4 flex flex-column">
                <div className="w-full firstImageDiv0  pb-4">
                <div
                  className=" fID0"
                  style={{
                    backgroundColor: "#0E8E60",
                    borderRadius: "1000px",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Image src={offline2} />
                </div>
              </div>
              <div className=" mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="47"
                  viewBox="0 0 56 47"
                  fill="none"
                >
                  <circle cx="7" cy="2" r="2" fill="#24FF00" />
                  <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                  <circle cx="51.5" cy="42.5" r="4.5" fill="#0DB760" />
                  <circle cx="29" cy="23" r="2" fill="#0047FF" />
                  <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                </svg>
              </div>
           
                </div>
            
            </div>
            <div className="w-full mt-6">
             
              <h5 className="offlinetext1_0 -mt-4 mb-0 px-1">
                Learning Technologies in Digital Era
              </h5>
              <h3 className="offlinetext2_0 mt-1 mb-0 text-center">
                Find out the
                <span className="offlinetext2_0_green">
                  {" "}
                  Best Offline Courses{" "}
                </span>{" "}
                and Learn
              </h3>
            </div>
          
            <div className="w-full pt-2">
              <h6 className="offlinetext3_0 mt-0 mb-3 px-1 text-center">
                Empower students with BrainyBits' internship-oriented office
                programs. Learn cutting-edge technologies, master
                industry-standard software, and bridge classroom knowledge with
                real-world application.
              </h6>
              <button
                className={`text-left mt-1`}
                style={{
                  width: "150px",
                  height: "45px",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  backgroundColor: "#0E8E60",
                  border: "1px solid #0E8E60",
                  borderRadius: "30px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                onClick={() => navigate("/office-classes/courses")}
              >
                Explore Courses
              </button>

           
            </div>
       
          </div>
          <div className="pl-4 pr-4 mt-6">
            <h5 className="offlinetext4_0 mb-2">
              Office <span className="offlinetext4_2_0">Programs Courses</span>
            </h5>
            <div className="w-full flex justify-content-between gap-2">
              <div className="w-4 flex flex-column  ">
                      {data &&
                  data.Popular_Categories?.map((el, ind) => (
                    <span
                      className={`${
                        active == ind
                          ? "offlineprgmcrs1_0"
                          : "offlineprgmcrs2_0"
                      } text-left flex align-items-center justify-content-between w-10 cursor-pointer`}
                      style={{overflow:"hidden",textOverflow:"ellipsis", whiteSpace: "nowrap" }}
                      onClick={() => click(ind, el)}
                    >
                      {el}
                      {active == ind ? (
                        ""
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                        >
                          <path
                            d="M1 11L6 6L1 1"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  ))}
              </div>
              {loader ? (
                <div className="w-8 flex justify-content-center align-items-center h-20rem">
                  <img
                    src={brain}
                    width={80}
                    className=" m-auto     md:hidden"
                  ></img>
                </div>
              ) : (
                <div className="w-8 flex flex-wrap gap-4">
                  {array?.slice(0, 1).map((el) => (
                    <div className="offlinecoursediv_0 flex flex-column">
                    {/* <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_photo} onClick={()=>navigate(`/office-classes/individual-courses/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div> */}

                      <div
                        className="offlinecoursedivone_0 flex justify-content-center align-items-center" style={{borderTopLeftRadius:"15px",borderTopRightRadius:"15px",overflow:"hidden"}}
                        onClick={() =>
                          navigate(
                            `/office-classes/individual-courses/${el.course_id}`
                          )
                        }
                      >
                        <img
                          src={el.course_photo}
                          style={{ width: "100%",borderTopLeftRadius:"15px",borderTopRightRadius:"15px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}}
                        />
                      </div>
                      <div className="flex flex-column p-2 px-3">
                        <div
                          className="flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                        >
                          <span
                            className="offlinecllgname text-left"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* {el.instructor_name} */}
                          </span>
                          <span className="offlinecllgrating flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55"
                              height="11"
                              viewBox="0 0 55 11"
                              fill="none"
                            >
                              <path
                                d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 0
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 1
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 2
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 3
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                              <path
                                d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z"
                                fill={
                                  Math.round(el.ratings) > 4
                                    ? "#0E8E60"
                                    : "#E0E0E0"
                                }
                              />
                            </svg>
                            ({el.num_of_ratings})
                          </span>
                        </div>
                        <h5
                          className="offlinecllgcourse0 text-left mt-2 mb-0 flex align-items-center"
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                        >
                          {el.course_name.length > 25
                            ? `${el.course_name.substring(0, 50)}...`
                            : el.course_name}
                        </h5>
                        <h6
                          className="offlinecourseduration text-left mt-1 mb-2"
                          onClick={() =>
                            navigate(
                              `/office-classes/individual-courses/${el.course_id}`
                            )
                          }
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {el.office_program_location}
                        </h6>
                        <div className="flex justify-content-between align-items-center">
                          <span
                            className="tabprice"
                            onClick={() =>
                              navigate(
                                `/office-classes/individual-courses/${el.course_id}`
                              )
                            }
                          >
                            ₹ {el.course_cost}
                          </span>
                          <Button
                            className="coursbuybtn px-3"
                            onClick={() => CartAddfunction(el.course_id, el)}
                          >
                            Buy now
                          </Button>
                        </div>

                        {/* <h4 className='offlinecoursefee text-left mt-1 mb-0'>₹ {el.course_cost}</h4>

   <Button className='offlinecllgcrsbtn_0 mb-0 flex justify-content-center align-items-center'>Guaranteed Job Opportunities</Button> */}
                      </div>
                    </div>
                  ))}
                  {array && array.length == 0 ? (
                    <h5 className="offlinecllgcourse text-center mt-2 mb-0">
                      Courses Coming Soon
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-column  gap-2 w-full pl-4 pr-4 mt-6">
            <div className="flex flex-column w-full">
              <span className="offlinetext8_0 text-center">
                There are more reasons
              </span>
              <h3 className="offlinetext9_0 text-center mt-0 mb-0">
                Why Choose{" "}
                <span className="offlinetext9_2_0">Office Courses?</span>
              </h3>
              <span className="offlinetext11_0 text-center w-full">
                Opt for office courses to experience personalized learning in a
                traditional classroom setting. Benefit from face-to-face
                interactions with experienced instructors, engage in
                collaborative discussions, and build a supportive network.
              </span>
              <Button
                className="offlinefreebtn_0 mt-4 "
                onClick={() => navigate("/office-classes/contact-us")}
              >
                Free Mentorship
              </Button>
              <span className="flex justify-content-end -mt-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="47"
                  viewBox="0 0 56 47"
                  fill="none"
                >
                  <circle cx="7" cy="2" r="2" fill="#24FF00" />
                  <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                  <circle
                    opacity="0.9"
                    cx="51.5"
                    cy="42.5"
                    r="4.5"
                    fill="#0DB760"
                  />
                  <circle cx="29" cy="23" r="2" fill="#0047FF" />
                  <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                </svg>
              </span>
            </div>
            <div className=" mt-4">
              <img
                src={classroom}
                style={{ width: "100%", height: "232px", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="flex flex-column mt-6 gap-2 pl-4 pr-4 w-full">
            <div className="flex flex-column w-full">
              <span className="offlinetext8_0 text-center">
                Explore Office Courses{" "}
              </span>
              <h3 className="offlinetext9_0 mt-0 mb-0 text-center">
                Why Choose <span className="offlinetext9_2_0">BrainyBits?</span>
              </h3>
              <span className="offlinetext11_0 text-center w-full">
                BrainyBits provides an unparalleled educational experience. We
                provide real time internship experience letter to enable you
                humongous opportunities.
              </span>
              <Button
                className="offlinefreebtn_0 mt-4"
                onClick={() => navigate("/office-classes/about-us")}
              >
                Learn More
              </Button>
              <span className="flex justify-content-start -mt-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="47"
                  viewBox="0 0 56 47"
                  fill="none"
                >
                  <circle cx="7" cy="2" r="2" fill="#24FF00" />
                  <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                  <circle
                    opacity="0.9"
                    cx="51.5"
                    cy="42.5"
                    r="4.5"
                    fill="#0DB760"
                  />
                  <circle cx="29" cy="23" r="2" fill="#0047FF" />
                  <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                </svg>
              </span>
            </div>
            <div className="w-full">
              <img
                src={classroom}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
          <div>
            <h5 className="offlinetext4_2_0 text-left mt-6 pl-4">
              Users <span className="offlinetext4_0">Testimonial</span>
            </h5>

            <Splide
              hasTrack={false}
              className=" "
              options={{
                // type: 'loop',
                rewind: false,
                autoplay: true,
                perMove: 1,
                perPage: 1,
                gap: "2rem",
                arrows: true,
                pagination: true,
              }}
            >
              <SplideTrack>
                {arr.map((el) => (
                  <SplideSlide className="h-15rem">
                    <div className="flex offlinereviews_0 ">
                      <div
                        className="w-5 flex justify-content-center align-items-center  ml-3"
                        style={{
                          backgroundImage: ` url(${userbacksmall})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center ",
                          backgroundSize: "100% ",
                        }}
                      >
                        <img
                          src={el.image}
                          width={"75%"}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="flex w-6 flex-column ml-2 py-2" >
                        <span className="usernaam_0">{el.name}</span>
                        <span className="userdesg_0 pl-1">{el.profession}</span>
                        <p className="userdesc_0  pl-1">{el.comment}</p>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>

              <div className="splide__arrows">
                <button
                  className="splide__arrow splide__arrow--prev shadow-3 ml-4  nextbtn"
                  style={{ marginTop: "30%" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3rem w-15rem"
                    width="400px"
                    height="400px"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M25.1175 31.4306L28.3311 34.6443L37.9766 25.0011L28.3334 15.3579L25.1197 18.5738L29.272 22.7284H12.1425V27.2738H29.272L25.1175 31.4306Z"
                      fill="#0E8E60"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z"
                      fill="#0E8E60"
                    />
                  </svg>
                </button>
                <button
                  className="splide__arrow splide__arrow--next shadow-3 mr-4 nextbtn"
                  style={{ marginTop: "30%" }}
                >
                  <svg
                    className="h-5rem w-15rem"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M25.1175 31.4302L28.3311 34.6438L37.9766 25.0006L28.3334 15.3574L25.1197 18.5733L29.272 22.7279H12.1425V27.2733H29.272L25.1175 31.4302Z"
                      fill="#0E8E60"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z"
                      fill="#0E8E60"
                    />
                  </svg>
                </button>
              </div>
            </Splide>
          </div>
          <div className="pl-4 pr-4 mt-6">
            <h5 className="offlinetext4_2_0 mb-0 text-center ">
              Frequently <span className="offlinetext4_0">Asked Questions</span>
            </h5>
            <div className="flex flex-column justify-content-betwen align-items-center">
              <span className="qstnshrtdesc_0 w-full text-center mb-2">
              These questions were asked frequently by students.
              </span>
              <span className="flex justify-content-end -mr-8 ml-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="47"
                  viewBox="0 0 56 47"
                  fill="none"
                >
                  <circle cx="7" cy="2" r="2" fill="#24FF00" />
                  <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                  <circle cx="51.5" cy="42.5" r="4.5" fill="#0DB760" />
                  <circle cx="29" cy="23" r="2" fill="#0047FF" />
                  <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                </svg>
              </span>
            </div>
            <div className="w-full flex flex-column">
              <div className="w-full">
                <img src={qstn} width={"70%"} />
                <span style={{ marginRight: "55%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="47"
                    viewBox="0 0 56 47"
                    fill="none"
                  >
                    <circle cx="7" cy="2" r="2" fill="#24FF00" />
                    <circle cx="4.5" cy="30.5" r="4.5" fill="#FF0000" />
                    <circle cx="51.5" cy="42.5" r="4.5" fill="#0DB760" />
                    <circle cx="29" cy="23" r="2" fill="#0047FF" />
                    <circle cx="41.5" cy="8.5" r="4.5" fill="#F9F047" />
                  </svg>
                </span>
              </div>
              <div className="w-12 flex  flex-column mt-2">
                <div className="card">
                  <Accordion
                    className="border-round-lg accordion-containeroffline "
                    expandIcon="pi pi-plus custom-expand-iconoffline"
                    collapseIcon="pi pi-minus custom-expand-iconoffline"
                  >
                    <AccordionTab
                      header={
                        <span className="acrhdroffline0 flex text-left">
                          1. Which services BrainyBits provides?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans0">
                        BrainyBits provides three different kind of learning
                        modules. Self-learning resources, online live classes,
                        Additionally, we offer office job-oriented internship
                        learning programs. Allowing learners to access all kinds
                        of software technology learning courses in various
                        fields.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline0 flex text-left">
                          2. Why should I choose BrainyBits?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans0">
                        BrainyBits offers the convenience of learning from
                        anywhere with an internet connection, expert-led
                        courses, and internship programs that prepare you for
                        real-world job opportunities. Our platform is designed
                        to provide a rich and effective learning experience.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline0 w-11 flex text-left">
                          3. What are the Learning Courses available ?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans0">
                        BrainyBits offers a wide range of learning courses,
                        including but not limited to IT Software, Data Science,
                        Artificial Intelligence, Design and more. We cover
                        diverse topics to cater to your learning needs. Find
                        more details in courses page of each module of learning.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline0 w-11 flex text-left">
                          4. What are my prerequisites to learn over BrainyBits
                          platform?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans0">
                        Prerequisites may vary depending on the specific course
                        you choose. However, for most courses, a basic
                        understanding of English language and mobile/computer
                        device is beneficial. Detailed prerequisites are usually
                        provided in the course descriptions.
                      </p>
                    </AccordionTab>
                    <AccordionTab
                      header={
                        <span className="acrhdroffline0 flex text-left">
                          5. Why only BrainyBits?
                        </span>
                      }
                      className=" accordion-taboffline "
                    >
                      <p className="qsans0">
                        BrainyBits stands out due to its comprehensive course
                        offerings, expert instructors, flexible learning
                        options, and office job-oriented internship programs. We
                        are committed to delivering high-quality education and
                        helping you acquire practical skills for success in your
                        chosen field. Choose BrainyBits for a rewarding learning
                        experience.
                      </p>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <br />
      <br />
      <br />
      <Latestnews />
      <br />

      <OfflineFooter />
    </>
  );
}
