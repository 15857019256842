import React, { useState, useRef } from 'react';
import "./BuyThisComponent.css";

const CustomVideo = ({ videoSrc,mobile }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const controlsRef = useRef(null);
// console.log(videoSrc);
  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMouseOver = () => {
    if (isPlaying) {
      controlsRef.current.style.display = 'flex';
    }
  };

  const handleMouseOut = () => {
    if (isPlaying) {
      controlsRef.current.style.display = 'none';
    }
  };

  const handleVideoEnded = () => {
    controlsRef.current.style.display = 'flex';
    setIsPlaying(false);
  };

  return (
    <div className="custom-video ">
      <div
        className="custom-video__container w-full"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <video
        style={{borderTopLeftRadius:mobile?"":"15px",borderTopRightRadius:mobile?"":"15px",maxHeight: "100%",objectFit:"cover", objectPosition: "0% -50%"}}
        
          ref={videoRef}
          className="custom-video__video w-full"
          // muted
          src={videoSrc}
          onClick={togglePlay}
          onEnded={handleVideoEnded}
        >
          {/* <source src={videoSrc}/> */}
          Your browser does not support the video tag.
        </video>
        <div ref={controlsRef} className="custom-video__control">
          {isPlaying ? '| |' : '▶'}
        </div>
      </div>
    </div>
  );
};


export default CustomVideo;