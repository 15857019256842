import axios from "axios"
import { addtoCartApi, checkOutApi, environment, fetchCartApi, priceSchedulApi, removeCartApi } from "../../../Api"
import { CHECKOUT_FAIL, CHECKOUT_SUCCESS, FETCHCART_REQUEST, FETCHCART_SUCCESS, PRICE_SCHEDULE_SUCCESS, REMOVECART_SUCCESS } from "../SelfTypes/Cart.types";



export const AddToCartFunction=(id,el)=>async(dispatch)=>{
    console.log("hit cart");
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
    if(brainystudenttoken){
        const payload={
            environment: environment,
            user_id: brainystudenttoken&&brainystudenttoken.user_id,
            course_id: id
        }
        console.log(payload);
        try{
         let response=await axios.post(addtoCartApi,payload)
         console.log(response.data)
        }
        catch(e){
    
        }
    }
    else{
       console.log(el,"el");
       let payld={ message: "Cart Fetched successfully",
       cart: {
        course_name: el&&el.course_name,
        instructor_name: el&&el.instructor_name,
        amount: el&&el.course_cost,
        course_id: el&&el.course_id,
        course_image: el&&el.course_photo,
        rating: el&&el.ratings,
        num_of_reviews: 1,
        course_type:el&&el.course_type
       }
    }
    if(el&&el.course_type=="SL"){
        localStorage.setItem("SLbrainyCartItem", JSON.stringify(payld));
    }
    else if(el&&el.course_type=="OL"){
        localStorage.setItem("OLbrainyCartItem", JSON.stringify(payld));
    }else{
       localStorage.setItem("brainyCartItem", JSON.stringify(payld));
    }
    }
  
}

export const FetchCartFunction=(status)=>async(dispatch)=>{
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
    if(brainystudenttoken){
        dispatch({type:FETCHCART_REQUEST})
        const payload={
            environment: environment,
            user_id: brainystudenttoken&&brainystudenttoken.user_id,
        }
        try{
            
            let response=await axios.post(fetchCartApi,payload)
            console.log(response.data)
            dispatch({type:FETCHCART_SUCCESS,payload:response.data})
    
        }catch(e){
            console.log(e);
            let cariItem=JSON.parse(localStorage.getItem("SLbrainyCartItem"));
            dispatch({type:FETCHCART_SUCCESS,payload:cariItem})
        }
    }
    else{
        if(status=="SL"){
            let cariItem=JSON.parse(localStorage.getItem("SLbrainyCartItem"));
        dispatch({type:FETCHCART_SUCCESS,payload:cariItem}) 
        }
        else if(status=="OL"){
            let cariItem=JSON.parse(localStorage.getItem("OLbrainyCartItem"));
            dispatch({type:FETCHCART_SUCCESS,payload:cariItem})
        }
        else{
            let cariItem=JSON.parse(localStorage.getItem("brainyCartItem"));
            dispatch({type:FETCHCART_SUCCESS,payload:cariItem})
        }
       
    }
   
}


export const RemoveCart=(id,status)=>async(dispatch)=>{
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
    if(brainystudenttoken){
        dispatch({type:FETCHCART_REQUEST})
    const payload={
        environment: environment,
        user_id: brainystudenttoken&&brainystudenttoken.user_id,
        course_id: id
    }
console.log(id);
    try{
let response=await axios.post(removeCartApi,payload)
console.log(response.data)
dispatch({type:REMOVECART_SUCCESS})
    }
    catch(e){
console.log(e);
    }
}
else{
    if(status=="SL"){
        localStorage.removeItem("SLbrainyCartItem");
        dispatch({type:REMOVECART_SUCCESS})
    }
    else if(status=="OL"){
        localStorage.removeItem("OLbrainyCartItem");
        dispatch({type:REMOVECART_SUCCESS})
    }
    else{
        localStorage.removeItem("brainyCartItem");
        dispatch({type:REMOVECART_SUCCESS})
    }
    

}
}

export const PriceSchedule=(id)=>async(dispatch)=>{
    let payload={
        environment: environment,
        course_id: id
    }
    console.log(payload);
    try{
        dispatch({type:FETCHCART_REQUEST})
let response=await axios.post(priceSchedulApi,payload)
console.log(response.data);
dispatch({type:PRICE_SCHEDULE_SUCCESS,payload:response.data})
    }
    catch(e){
    console.log(e);
    }
}

export const CheckoutApiCall=(data)=>async(dispatch)=>{
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
    dispatch({type:FETCHCART_REQUEST})
    let payload={
        first_name  : data&&data.first_name,
        last_name   : data&&data.last_name,
        phone_number: data&&data.phone_number,
        address     : data&&data.address,
        country     : data&&data.country,
        state       : data&&data.state,
        pincode    : data&&data.pincode,
        user_id:     brainystudenttoken&&brainystudenttoken.user_id,
        environment:environment,
        redirect_url:data&&data.redirect_url
       }
       console.log(payload);

     try{
let resp=await axios.post(checkOutApi,payload)
console.log(resp.data);
dispatch({type:CHECKOUT_SUCCESS,payload:resp.data})
    }
    catch(e){
console.log(e);
dispatch({type:CHECKOUT_FAIL})
    }
}