import axios from "axios"
import { INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS, OPDYNAMICCOURSES_SUCCESS } from "../OfflineTypes/offlinecourse.types"
import { environment, offlineDynamicCourseApi, offlineIndividualCourseDetailsApi } from "../../../Api"




export const OpIndividualFetchCourse=(id)=>async(dispath)=>{
    dispath({type:INDIVIDUALCOURSEDETAILS_REQUEST})
    const payload={
        environment: environment,
        course_id: id
    }
    // console.log(id,payload)
    try{
     let response=await axios.post(offlineIndividualCourseDetailsApi,payload)
     console.log(response.data)
     dispath({type:INDIVIDUALCOURSEDETAILS_SUCCESS,payload:response.data})
    }
    catch(e){
        console.log(e);
        dispath({type:INDIVIDUALCOURSEDETAILS_SUCCESS,payload:[]})
    }
}


export const OpFetchDynamicCourse=(param)=>async(dispatch)=>{
    const payload={
        category: param
}
    try{
        let response=await axios.post(offlineDynamicCourseApi,payload)

        dispatch({type:OPDYNAMICCOURSES_SUCCESS,payload:response.data})
    }
    catch(e){

    }
}