export const environment="dev"
export const Mainurl='http://dev.brainybits.in'
// export const Mainurl='http://localhost:3000'
export const signupApi="https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/signup"
export const ReviewApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/course-review'
export const instructorEnquiry='https://xwqquzd3b7.execute-api.ap-south-2.amazonaws.com/Prod/instructor-enquiry'

export const resetPasswordApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/reset-password'
export const updatePasswordApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/update-password'
export const forgotPasswordApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/forgot-password'
export const loginApi="https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/login"
export const fetchProfileApi="https://l6wusnur30.execute-api.ap-south-2.amazonaws.com/Prod/fetch-profile"
export const updateProdileApi="https://l6wusnur30.execute-api.ap-south-2.amazonaws.com/Prod/update-profile"
export const updateProfileImageApi="https://l6wusnur30.execute-api.ap-south-2.amazonaws.com/Prod/update-profile"
export const fetchCoursePageApi='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/slhomepage'
export const enquiryApi= 'https://5nedawdyng.execute-api.ap-south-2.amazonaws.com/Prod/contact-us'
export const fetchCourseListApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/sl-courses-list'
export const fetchCourseDetailsApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/continue-learning'
export const individualCourseDetailsApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/sl-course-details'
export const addtoCartApi='https://hu9f2zryhb.execute-api.ap-south-2.amazonaws.com/Prod/add-cart'
export const fetchCartApi='https://hu9f2zryhb.execute-api.ap-south-2.amazonaws.com/Prod/fetch-cart'
export const removeCartApi='https://hu9f2zryhb.execute-api.ap-south-2.amazonaws.com/Prod/remove-cart'
export const priceSchedulApi='https://p7xn9vgs5m.execute-api.ap-south-2.amazonaws.com/Prod/price-schedule'
export const checkOutApi='https://p7xn9vgs5m.execute-api.ap-south-2.amazonaws.com/Prod/pay-page'
export const watchhistoryupdateApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/watch-history-update'
export const certificategenerateApi='https://sbn9vfk8d9.execute-api.ap-south-2.amazonaws.com/Prod/certificate-generate'
export const recentpaymentstatusApi='https://p7xn9vgs5m.execute-api.ap-south-2.amazonaws.com/Prod/recent-payment-status'




export const fetchblogApi='https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/fetch-all-blogs'
export const fetchIndividualBlogApi='https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/fetch-blog-post'
export const LikeBlogApi="https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/like-blog"
export const dislikeBlogApi='https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/unlike-blog'
export const commentBlogApi='https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/comment-blog'
export const fetchblogcategorywiseApi='https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/list-blogs-categorywise'
export const DynamicCourseApi='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/popular-sl-course'


/**onlinr */
export const onlineSignupApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/signup'
export const onlineHomepageApi='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/olhomepage'
export const onlineIndividualCourseDetailsApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/ol-course-details'
export const onlineDynamicCourseApi='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/popular-ol-course'
export const onlinefetchbatchaccrdtocourseApi='https://svfkw73n4h.execute-api.ap-south-2.amazonaws.com/Prod/fetch-batches'
export const onlinerecentpaymentStatusApi='https://p7xn9vgs5m.execute-api.ap-south-2.amazonaws.com/Prod/recent-payment-status'
export const onlineforgotpasswordApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/forgot-password'


export const offlinehomepage_Api='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/ophomepage'
export const offlineIndividualCourseDetailsApi='https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/op-course-details'
export const offlineDynamicCourseApi='https://wk9p0ax65f.execute-api.ap-south-2.amazonaws.com/Prod/popular-op-course'
export const offlinefetchbatchesacctocourseApi='https://zz67ps4c7g.execute-api.ap-south-2.amazonaws.com/Prod/fetch-batches'
export const offlinerecentpaymentStatusApi='https://p7xn9vgs5m.execute-api.ap-south-2.amazonaws.com/Prod/recent-payment-status'
export const offlineforgotpasswordApi='https://4kms5ids6d.execute-api.ap-south-2.amazonaws.com/Prod/forgot-password'
