import { CHECKOUT_FAIL, CHECKOUT_SUCCESS, FETCHCART_REQUEST, FETCHCART_SUCCESS, PRICE_SCHEDULE_SUCCESS, REMOVECART_SUCCESS } from "../SelfTypes/Cart.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    message: "",
    data:[],
    priceSchedule:[],
    checkoutdata:[]

  };
  
  export const CartReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCHCART_REQUEST:{
        return{
          ...state,loading:true
        }
      }
      case FETCHCART_SUCCESS: {
        return {
          ...state,
          loading: false,data:payload,
        };
      }
   case REMOVECART_SUCCESS:{
    return{
        ...state,data:[],loading:false
    }
   } 
   case PRICE_SCHEDULE_SUCCESS:{
    return{
        ...state,priceSchedule:payload,loading:false
    }
   }
     case CHECKOUT_SUCCESS:{

      // localStorage.removeItem("brainystudenttoken");
      localStorage.removeItem("SLbrainyCartItem");
      localStorage.removeItem("OLbrainyCartItem");
      localStorage.removeItem("brainyCartItem");
      return{
        ...state,checkoutdata:payload,loading:false
      }
     }
     case CHECKOUT_FAIL:{
      return{
        ...state,loading:false
      }
     }
      default:
        return state;
    }
  };
  