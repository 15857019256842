import axios from "axios";
import { environment, onlineHomepageApi,  } from "../../../Api";
import { FETCH_SUCCESS } from "../OnlineTypes/OnlineHome.types";





export const fetchHomePage = () => async (dispatch) => {
//   dispatch({ type: REGISTER_REQUEST });
  try {

    let res=await axios.post(onlineHomepageApi,{environment:environment})
    console.log(res.data)
    // alert(`your otp is ${res.data.otp}`)
     dispatch({ type: FETCH_SUCCESS,payload:res.data });
  } catch (e) {
    // dispatch({ type: REGISTER_ERROR });
  }
};




