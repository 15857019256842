import "./LatestNews.css";
import Statisticksmodel from "../../asserts/Statisticksmodel.jpg";
import trending_bussinessss from "../../asserts/trending_bussinessss.jpg";
import Top8innovations from "../../asserts/Top8innovations.jpg";
import { Grid, Calender } from "../../figmaicons/figma";
import { newsletter } from "../../asserts/index";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomePageoffline } from "../../Redux/Offline/OfflineAction/OfflineHome.action";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const array = [
  {
    id: 0,
    image: Statisticksmodel,
    topic: "Data Science ",
    name: "Why Statistical model are important..",
    date: "20-12-2022",
  },
  {
    id: 1,
    image: trending_bussinessss,
    topic: "Artificial Intelligence ",
    name: "Trending Businesses with Natural Processing",
    date: "20-12-2022",
  },
  {
    id: 2,
    image: Top8innovations,
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
];

function Latestnews() {
  const dispatch=useDispatch()
  const {data}=useSelector(store=>store.offlinehome)
  const navigate=useNavigate()
  let k = 4;
  // if (window.innerWidth < 768) {
  //   k = 1;
  // }
  const cities = [
    { name: 'Business & GDP', code: 'BGDP' },
    { name: 'Politics', code: 'POLI' },
    { name: 'Movies & Art', code: 'MVAT' },
    { name: 'Data Science & AI', code: 'DSAI' },
    { name: 'Space Science & Technology', code: 'SSTC' },
    { name: 'Sports', code: 'SPRT' },
    { name: 'LifeStyle', code: 'LFST' },
    { name: 'Education', code: 'EDUC' }

];

const checkcategory=(cat)=>{
   for(let i=0;i<cities.length;i++){
    if(cat==cities[i].code){
      return cities[i].name
    }
   }
}
  useEffect(()=>{
    dispatch(fetchHomePageoffline())
   },[])
  return (
    <>
    <div className=" hidden md:flex ourlatestnewscontainer  flex-column gap-7 py-0" >
      <div className="ourlatestnews w-full font-family flex flex-column  pt-7 align-items-center">
        <h1 className="py-3 flex w-17rem">Our Latest news & Article</h1>
        <ul className="list-none w-8  flex flex-wrap align-items-center h-25rem">
          {data&& data.Articles_List&&data.Articles_List.slice(0,3).map((each) => (
            <li key={each.blog_id} onClick={()=>navigate(`/office-classes/read-blog/${each.blog_id}`)} className="w-full flex justify-content-center w-4  p-2 cursor-pointer" style={{height:"370px"}}>
              <div className="w-11 shadow-5 border-round-xl bg-white" style={{height:"100%"}}>
                <img
                  src={each.blog_image}
                  alt="img"
                  style={{height:"60%"}}

                  className="border-round-top-xl w-full"
                />
                <div className="w-full h-7rem flex py-2 px-3 flex-column  justify-content-between align-items-start" style={{height:"40%"}}>
                  <span className="w-full flex align-items-center">
                    <Grid />
                    <span className="newsarticlehead pl-1">{checkcategory(each.blog_category)}</span>
                  </span>
                  <h5 className="w-8  text-left">{each.blog_title}</h5>
                  <span className="flex w-full align-items-center justify-content-between">
                    <span className="flex align-items-center gap-1">
                      <Calender />
                      <span className="text-xs">{each.blog_post_date.split(" ")[0]}</span>
                    </span>
                    <span className="newsarticlereadmorebtn font flex justify-content-center align-items-center gap-1 text-sm">Read More<svg xmlns="http://www.w3.org/2000/svg" className="mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M4.16406 10H15.8307M15.8307 10L10.8307 5M15.8307 10L10.8307 15" stroke="#0E8E60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Button
          label="Read more"
          className="bg-greenn-500 flex md:hidden align-self-center h-3rem  my-3"
        />
      </div>
      <div className="h-auto px-9 w-9 align-self-center flex align-items-center gap-7">
        <img
          src={newsletter}
          alt="img"
          className="w-5  flex  ml-9"
        />
        <div className="w-6 font-family py-3 flex flex-column text-left ">
          <h1 className="py-2 px-0 font text-left subscribe">
            Subscribe to our newsletter
          </h1>
          <p className="paragraph my-2 font px-0">
            Stay up-to-date with the latest news, tips, and insights from our
            blog by subscribing to our newsletter. Join our community of
            like-minded individuals and never miss a post.
          </p>

        
          <div className="p-inputgroup font-family  my-4 px-4 md:px-0">
            <InputText
              placeholder="Enter Your Email Here"
              className="shadow-2 font-family "
              id="input"
              style={{backgroundColor:"#fff",border:"none"}}
            />
            <Button label="Join Now" className="shadow-2 joinNowbtn b-0 font-family" />
          </div>
        </div>
      </div>
    </div>

    <div  className=" md:hidden flex   flex-column ">
      <div  className="ourlatestnews w-full  flex flex-column px-3   align-items-center">
        <h1 className="newlatestnewshd0 py-4">Our Latest news & Article</h1>
        <ul  className="list-none w-10  flex  align-items-center p-0">
        {data&& data.Articles_List&&data.Articles_List.slice(0,1).map((each) => (
            <li  key={each.blog_id} onClick={()=>navigate(`/office-classes/read-blog/${each.blog_id}`)} className="w-full  flex justify-content-center cursor-pointer   h-26rem" >
              <div className="w-11 shadow-5 border-round-xl bg-white px-2" style={{height:"100%"}}>
                <img
                  src={each.blog_image}
                  alt="img"
                  className="border-round-top-xl w-full"
                />
                <div className="w-full h-7rem flex p-2 px-3 flex-column  justify-content-between align-items-start" style={{height:"40%"}}>
                  <span className="w-full flex align-items-center">
                    <Grid />
                    <span className="newsarticlehead pl-1">{checkcategory(each.blog_category)}</span>
                  </span>
                  <h5 className="w-10 newsarticledesc text-left">{each.blog_title}</h5>
                  <span className="flex w-full align-items-center justify-content-between">
                    <span className="flex align-items-center gap-1">
                      <Calender />
                      <span className="text-xs">{each.blog_post_date.split(" ")[0]}</span>
                    </span>
                    <span className="newsarticlereadmorebtn font flex justify-content-center align-items-center gap-1 text-sm">Read More<svg xmlns="http://www.w3.org/2000/svg" className="mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M4.16406 10H15.8307M15.8307 10L10.8307 5M15.8307 10L10.8307 15" stroke="#0E8E60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      
      </div>
      <div className="w-full  flex flex-column p-2 mt-4 px-4">
       
        <h1 className="text-left mb-2 subscribe0">Subscribe to our newsletter </h1>
          <p className="paragraph0 ">
            Stay up-to-date with the latest news, tips, and insights from our
            blog by subscribing to our newsletter. Join our community of
            like-minded individuals and never miss a post.
          </p>
        <div className="w-full font-family py-3 flex flex-column text-left p-2">
        <img src={newsletter}alt="img"/>

        
          <div className="p-inputgroup font-family  my-4 px-4 md:px-0">
            <InputText
              placeholder="Enter Your Email Here"
              className="shadow-2 font-family "
              style={{backgroundColor:"#fff",border:"none"}}
            />
            <Button label="Join Now" className="shadow-2 joinNowbtn b-0 font-family" />
          </div>
        </div>
      </div>
    </div>

    {/* <div  className=" md:hidden flex   flex-column ">
      <div  className="ourlatestnews w-full  flex flex-column px-3   align-items-center">
        <h1 className="newlatestnewshd0 py-4">Our Latest news & Article</h1>
        <ul  className="list-none w-10  flex  align-items-center p-0">
        {data&& data.Articles_List&&data.Articles_List.slice(0,1).map((each) => (
            <li  key={each.blog_id} onClick={()=>navigate(`/office-classes/read-blog/${each.blog_id}`)} className="w-full flex justify-content-center  cursor-pointer  h-26rem" >
              <div className="w-11 shadow-5 border-round-xl bg-white" style={{height:"60%"}}>
                <img
                  src={each.blog_image}
                  alt="img"
                  className="border-round-top-xl w-full"
                />
                <div className="w-full h-7rem flex p-2 px-3 flex-column  justify-content-between align-items-start" style={{height:"40%"}}>
                  <span className="w-full flex align-items-center">
                    <Grid />
                    <span className="newsarticlehead pl-1">{each.blog_category}</span>
                  </span>
                  <h5 className="w-10 newsarticledesc text-left">{each.blog_title}</h5>
                  <span className="flex w-full align-items-center justify-content-between">
                    <span className="flex align-items-center gap-1">
                      <Calender />
                      <span className="text-xs">{each.blog_post_date.split(" ")[0]}</span>
                    </span>
                    <span className="newsarticlereadmorebtn font flex justify-content-center align-items-center gap-1 text-sm">Read More<svg xmlns="http://www.w3.org/2000/svg" className="mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M4.16406 10H15.8307M15.8307 10L10.8307 5M15.8307 10L10.8307 15" stroke="#0E8E60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Button
          label="Read more"
          className="bg-greenn-500 flex  align-self-center h-3rem  my-3 newsreadmorebtn"
        />
      </div>
      <div className="w-full  flex flex-column p-2 mt-4 px-4">
       
        <h1 className="text-left mb-2 subscribe0">Subscribe to our newsletter </h1>
          <p className="paragraph0 ">
            Stay up-to-date with the latest news, tips, and insights from our
            blog by subscribing to our newsletter. Join our community of
            like-minded individuals and never miss a post.
          </p>
        <div className="w-full font-family py-3 flex flex-column text-left p-2">
        <img src={newsletter}alt="img"/>

        
          <div className="p-inputgroup font-family  my-4 px-4 md:px-0">
            <InputText
              placeholder="Enter Your Email Here"
              className="shadow-2 font-family "
              style={{backgroundColor:"#fff",border:"none"}}
            />
            <Button label="Join Now" className="shadow-2 joinNowbtn b-0 font-family" />
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
}

export default Latestnews;
