import axios from "axios";
import { environment, fetchCoursePageApi } from "../../../Api";
import { FETCH_SUCCESS } from "../SelfTypes/SelfHome.types";




export const fetchCoursePage = () => async (dispatch) => {
//   dispatch({ type: REGISTER_REQUEST });
  try {

    let res=await axios.post(fetchCoursePageApi,{environment:environment})
    console.log(res.data)
    // alert(`your otp is ${res.data.otp}`)
     dispatch({ type: FETCH_SUCCESS,payload:res.data });
  } catch (e) {
    // dispatch({ type: REGISTER_ERROR });
  }
};




