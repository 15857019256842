import React, { useEffect, useRef, useState } from 'react'
import "./Read-blog.css"
import ReadBlog5 from "../../../asserts/ReadBlog5.png"
import ReadBlog3 from "../../../asserts/ReadBlog3.png"
import ReadBlog6 from "../../../asserts/ReadBlog6.png"

import Heart from "react-animated-heart";
import { Image } from "primereact/image";
import { useDispatch, useSelector } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { AddCommnentBlog, DisLikeBlog, LikeBlog, ResetMessageBlog } from "../../../Redux/SelfLearning/SelfAction/Blog.action";
import { Toast } from "primereact/toast";
import bloguser from "../../../asserts/blogusercomment.png"
import blogeye from "../../../asserts/blogeye.png"
import blogheart from "../../../asserts/blogheart.png"
import blogcomment from "../../../asserts/blogcomment.png"
import bloglike from "../../../asserts/bloglike.png"
import blogdislike from "../../../asserts/blogdislike.png"
import brain from "../../../brain.gif"
export default function Read_blog() {
  const {indivualBlog,message}=useSelector(store=>store.blog)
  const [isClick, setClick] = useState(false);
  const [value, setValue] = useState('');
  const dispatch=useDispatch()
  const toast = useRef(null);
  const handleLike2=(val)=>{
    if(val=="dislike" ){
      dispatch(DisLikeBlog(indivualBlog.blog.blog_id))
    }
    else{
      dispatch(LikeBlog(indivualBlog.blog.blog_id))
    }
    setClick(!isClick)
  }
const handleLike=()=>{
  if(isClick){
    dispatch(DisLikeBlog(indivualBlog.blog.blog_id))
  }
  else{
    dispatch(LikeBlog(indivualBlog.blog.blog_id))
  }
  setClick(!isClick)
}
  const handleSubmit=()=>{
    console.log(value);
    let data={
         blog_id:indivualBlog.blog.blog_id,
    comment :value
    }
    dispatch(AddCommnentBlog(data))
  }

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  // console.log(indivualBlog); 
  useEffect(()=>{
    if(message=="successfully commented"){
    showSuccess("Comment Added")
    dispatch(ResetMessageBlog())
    window.scrollTo(0,0)
    }
    else if(message=='successfully unliked'){
      showSuccess("successfully unliked")
    dispatch(ResetMessageBlog())
    window.scrollTo(0,0)
    }
    else if(message=='successfully liked'){
      showSuccess("successfully liked")
    dispatch(ResetMessageBlog())
    window.scrollTo(0,0)
    }
    else if(message){
      showError("add comment")
    }
      },[message])

  const replaceAnchorWithMediaTags0 = (htmlResponse) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlResponse, 'text/html');
    const anchorTags = doc.querySelectorAll('a');

    anchorTags.forEach((anchorTag) => {
        const hrefUrl = anchorTag.getAttribute('href');
        console.log(hrefUrl);

        if (hrefUrl && /(https?:\/\/.*\.(?:jpg|jpeg|png|gif|bmp|svg|mp4|avi|mov|wmv))/i.test(hrefUrl)) {
            let mediaTag;
            // console.log("checking",hrefUrl);
            if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
                mediaTag = document.createElement('video');
                mediaTag.controls = true;
                const sourceTag = document.createElement('source');
                sourceTag.src = hrefUrl;
                sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
                mediaTag.appendChild(sourceTag);
            }
            else if (/(https?:\/\/.*\.(?:png|jpg))/i.test(hrefUrl)) {
              console.log("img");
                mediaTag = document.createElement('img');
                mediaTag.src = hrefUrl;
            } 

            const wrapperDiv = document.createElement('div');
            wrapperDiv.appendChild(mediaTag);

            // Replace the anchor tag with the contents of the wrapper div
            anchorTag.parentNode.replaceChild(wrapperDiv.firstChild, anchorTag);
        }
    });

    return doc.documentElement.outerHTML;
};
const replaceAnchorWithMediaTags1 = (htmlResponse,width) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlResponse, 'text/html');
  const anchorTags = doc.querySelectorAll('a');

  anchorTags.forEach((anchorTag) => {
      const hrefUrl = anchorTag.getAttribute('href');

      if (hrefUrl) {
          let mediaTag;

          // Check for YouTube links
          if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = width; // Set width to 100% of the container
              mediaTag.height = '315';
              mediaTag.src = `https://www.youtube.com/embed/${RegExp.$4}`;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
          } else if (/(https?:\/\/.*\.(?:png|jpg))/i.test(hrefUrl)) {
              console.log("img");
              mediaTag = doc.createElement('img');
              mediaTag.src = hrefUrl;
          } else if (/(https?:\/\/.*\.(?:mp4|avi|mov|wmv))/i.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('video');
              mediaTag.controls = true;
              const sourceTag = doc.createElement('source');
              sourceTag.src = hrefUrl;
              sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
              mediaTag.appendChild(sourceTag);
          }

          if (mediaTag) {
              const wrapperDiv = doc.createElement('div');
              wrapperDiv.appendChild(mediaTag);
              // Replace the anchor tag with the contents of the wrapper div
              anchorTag.parentNode.replaceChild(wrapperDiv.firstChild, anchorTag);
          }
      }
  });

  return doc.documentElement.outerHTML;
};

const replaceAnchorWithMediaTags2 = (htmlResponse, width) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlResponse, 'text/html');
  const anchorTags = Array.from(doc.querySelectorAll('a'));

  const replacements = [];

  anchorTags.forEach((anchorTag) => {
      const hrefUrl = anchorTag.getAttribute('href');

      if (hrefUrl) {
          let mediaTag;

          // Check for YouTube links
          if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = width; // Set width to 100% of the container
              mediaTag.height = '315';
              mediaTag.src = `https://www.youtube.com/embed/${RegExp.$4}`;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
          } else if (/(https?:\/\/.*\.(?:png|jpg))/i.test(hrefUrl)) {
              console.log("img");
              mediaTag = doc.createElement('img');
              mediaTag.src = hrefUrl;
          } else if (/(https?:\/\/.*\.(?:mp4|avi|mov|wmv))/i.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('video');
              mediaTag.controls = true;
              const sourceTag = doc.createElement('source');
              sourceTag.src = hrefUrl;
              sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
              mediaTag.appendChild(sourceTag);
          } else if (/^https?:\/\/www\.example\.com\/iframe-page$/.test(hrefUrl)) {
              console.log("iframe");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = width;
              mediaTag.height = '315';
              mediaTag.src = hrefUrl;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
          }

          if (mediaTag) {
              const wrapperDiv = doc.createElement('div');
              wrapperDiv.appendChild(mediaTag);

              // Add the replacement to the array
              replacements.push({
                  anchorTag,
                  replacement: wrapperDiv.firstChild
              });
          }
      }
  });

  // Apply the anchor replacements
  replacements.forEach(({ anchorTag, replacement }) => {
      anchorTag.parentNode.replaceChild(replacement, anchorTag);
  });

  // Now, replace duplicate img, video, and iframe tags
  const mediaTags = Array.from(doc.querySelectorAll('img, video, iframe'));
  const processedMediaUrls = new Set();

  mediaTags.forEach((mediaTag) => {
      const mediaUrl = mediaTag.src || mediaTag.querySelector('source')?.src || mediaTag.src;

      if (mediaUrl && !processedMediaUrls.has(mediaUrl)) {
          processedMediaUrls.add(mediaUrl);
      } else {
          mediaTag.parentNode.removeChild(mediaTag);
      }
  });

  return doc.documentElement.outerHTML;
};
const replaceAnchorWithMediaTags3 = (htmlResponse) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlResponse, 'text/html');
  const anchorTags = Array.from(doc.querySelectorAll('a'));

  const replacements = [];

  anchorTags.forEach((anchorTag) => {
      const hrefUrl = anchorTag.getAttribute('href');

      if (hrefUrl) {
          let mediaTag;

          // Check for YouTube links
          if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%"; // Set width to 100% of the container
              mediaTag.height = '500';
              mediaTag.src = `https://www.youtube.com/embed/${RegExp.$4}`;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="20px";
          } else if (/(https?:\/\/.*\.(?:png|jpg|jpeg))/i.test(hrefUrl)) {
              console.log("img");
              mediaTag = doc.createElement('img');
              mediaTag.src = hrefUrl;
             mediaTag.style.margin="auto"
             mediaTag.style.display = 'flex';
             mediaTag.style.justifyContent = 'center';
             mediaTag.style.alignItems = 'center';
          } else if (/(https?:\/\/.*\.(?:mp4|avi|mov|wmv))/i.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('video');
              mediaTag.controls = true;
              const sourceTag = doc.createElement('source');
              sourceTag.src = hrefUrl;
              sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
              mediaTag.appendChild(sourceTag);
          } else if (/^https?:\/\/www\.example\.com\/iframe-page$/.test(hrefUrl)) {
              console.log("iframe");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%";
              mediaTag.height = '500';
              mediaTag.src = hrefUrl;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="10px";
          }

          if (mediaTag) {
              const wrapperDiv = doc.createElement('div');
              wrapperDiv.appendChild(mediaTag);

              // Add the replacement to the array
              replacements.push({
                  anchorTag,
                  replacement: wrapperDiv.firstChild
              });
          }
      }
  });

  // Apply the anchor replacements
  replacements.forEach(({ anchorTag, replacement }) => {
      anchorTag.parentNode.replaceChild(replacement, anchorTag);
  });

  // Now, replace duplicate img, video, and iframe tags
  const mediaTags = Array.from(doc.querySelectorAll('img, video, iframe'));
  const processedMediaUrls = new Set();

  mediaTags.forEach((mediaTag) => {
      const mediaUrl = mediaTag.src || mediaTag.querySelector('source')?.src || mediaTag.src;

      if (mediaUrl && !processedMediaUrls.has(mediaUrl)) {
          processedMediaUrls.add(mediaUrl);
          if (mediaTag.tagName === 'IMG') {
          //for centering all img
          mediaTag.style.marginTop="10px";
          mediaTag.style.marginBottom="20px";
            mediaTag.style.display = 'block'; // Make the image a block element
            // mediaTag.style.margin = 'auto'; // Center the image horizontally
            mediaTag.style.marginLeft = 'auto';
            mediaTag.style.marginRight = 'auto';
        }
      } else {
          mediaTag.parentNode.removeChild(mediaTag);
      }
  });
  console.log(doc.documentElement);

  return doc.documentElement.outerHTML;
};
const [loader,setLoader]=useState(false)

const replaceAnchorWithMediaTags =async (htmlResponse) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlResponse, 'text/html');
  const anchorTags = Array.from(doc.querySelectorAll('a'));
setLoader(true)
  const replacements = [];
  const getImageSource = async (url) => {
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await fetch(url);
      console.log(response.url);
      const blob = await response.blob();
      // console.log(blob);
      return (response.url);
    } catch (error) {
      console.error('Error fetching image source:', error);
      return null;
    }
  };
  for (const anchorTag of anchorTags) {
    const hrefUrl = anchorTag.getAttribute('href');

      if (hrefUrl) {
          let mediaTag;
//console.log(hrefUrl);
          // Check for YouTube links
          if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%"; // Set width to 100% of the container
              mediaTag.height = "400";
              mediaTag.src = `https://www.youtube.com/embed/${RegExp.$4}`;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="20px";
          }
           else if (/(https?:\/\/.*\.(?:mp4|avi|mov|wmv))/i.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('video');
              mediaTag.controls = true;
              const sourceTag = doc.createElement('source');
              sourceTag.src = hrefUrl;
              sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
              mediaTag.appendChild(sourceTag);
          } else if (/^https?:\/\/www\.example\.com\/iframe-page$/.test(hrefUrl)) {
              console.log("iframe");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%";
              mediaTag.height = '400';
              mediaTag.src = hrefUrl;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="10px";
          } else  {
            // const imageUrlMatches = hrefUrl.match(/https?:\/\/[^&?]+\/.*\.(?:png|jpg|jpeg)(?:\?.*|)/i);
            // const imageUrl2 = imageUrlMatches ? imageUrlMatches[0] : null;
            const imageUrl = await getImageSource(hrefUrl);
           // console.log(imageUrl);
            if(imageUrl){
              mediaTag = doc.createElement('img');
              mediaTag.src = imageUrl;
              mediaTag.style.width = '100%';
              mediaTag.style.height = 'auto';
              mediaTag.style.display = 'block';
              mediaTag.style.margin = 'auto';
            }else{
              mediaTag = doc.createElement('img');
              mediaTag.src = hrefUrl;
              mediaTag.style.width = '100%';
              mediaTag.style.height = 'auto';
              mediaTag.style.display = 'block';
              mediaTag.style.margin = 'auto';
            }
             console.log("img");
          }

          if (mediaTag) {
              const wrapperDiv = doc.createElement('div');
              wrapperDiv.appendChild(mediaTag);

              // Add the replacement to the array
              replacements.push({
                  anchorTag,
                  replacement: wrapperDiv.firstChild
              });
          }
      }
  };

  for (const { anchorTag, replacement } of replacements) {
    anchorTag.parentNode.replaceChild(replacement, anchorTag);
  }

  // Now, replace duplicate img, video, and iframe tags
  const mediaTags = Array.from(doc.querySelectorAll('img, video, iframe'));
  const processedMediaUrls = new Set();

  for (const mediaTag of mediaTags) {
          const mediaUrl = mediaTag.src || mediaTag.querySelector('source')?.src || mediaTag.src;

      if (mediaUrl && !processedMediaUrls.has(mediaUrl)) {
          processedMediaUrls.add(mediaUrl);
          if (mediaTag.tagName === 'IMG') {
          //for centering all img
          mediaTag.style.marginTop="10px";
          mediaTag.style.marginBottom="20px";
            mediaTag.style.display = 'block'; // Make the image a block element
            // mediaTag.style.margin = 'auto'; // Center the image horizontally
            mediaTag.style.marginLeft = 'auto';
            mediaTag.style.marginRight = 'auto';
        }
      } else {
          mediaTag.parentNode.removeChild(mediaTag);
      }
  };
// console.log(doc.documentElement);
setLoader(false)
  return doc.documentElement.outerHTML;
};

const [formattedHTML, setFormattedHTML] = useState('');

useEffect(() => {
  const fetchFormattedHTML = async () => {
    const formattedHTML = await replaceAnchorWithMediaTags(
      indivualBlog && indivualBlog.blog && indivualBlog.blog.content
    );
    setFormattedHTML(formattedHTML);
  };

  fetchFormattedHTML();
}, [indivualBlog]);
  return (
    <>
            <Toast ref={toast} />

            {loader?<div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:

            <div className="hidden md:flex flex-column gap-3 w-9" >
        <div className="w-full mb-0 flex align-items-center" >
          <h1 id="main1tt" className="text-left flex align-items-center w-11"  >
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title}
          </h1>
         
          <Heart styles={{height:"80px",marginTop:"-5px"}} isClick={isClick} onClick={ handleLike} />
         {/* <a href="#id"> <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" className="mt-3 -ml-1 cursor-pointer" viewBox="0 0 512 512"><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>  </a>     */}
         
           </div>
        
        <div className="flex -mt-3 flex-column gap-1">
          
          <div id="main3tt" className="text-left mb-3">
            Published on &nbsp;
             {/* THU, DEC 1 20228:09 AM ESTUPDATED THU, DEC 1 202210:36 AM EST */}
             {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.datetime}
          </div>
          <div className="ridblgimg flex " style={{ width: "100%", height: "437px" }}>
            <Image src={indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title_image} className='w-full' style={{objectFit:"cover"}} />
          </div>
          <div id="main3tt" className="text-left h-5rem">
          
          </div>
        </div>
        <div className="w-full">
        <h1 className="w-full" id="briefdesc" dangerouslySetInnerHTML={{ __html: formattedHTML }} >
           
          </h1> 
            <div className="w-full mt-6" id="id">
              {/* <label style={{color:"#0E8E60"}}>Comment</label> */}
          <div className='flex justify-content-end gap-3 mb-4'>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogeye} width={30}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_views}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogheart} width={28}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_likes}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogcomment} width={23}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_comments}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-4 ml-4'>
                <img src={blogdislike} width={28} className='mb-2 cursor-pointer' onClick={()=> handleLike2("like")}  />
              <img src={bloglike} width={28} className='mt-1 cursor-pointer' onClick={()=> handleLike2("dislike")}/>
            
            </div>
          </div>
            <InputTextarea autoResize value={value} onChange={(e) => setValue(e.target.value)} rows={4} className="cmmnttxtarea border-round-lg" placeholder='Post Your Comment' />
             <div className="flex justify-content-end mt-4"><Button className="addcmntbotn mt-1" onClick={handleSubmit}>Post Comment</Button></div> 
            </div>
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.comments.length>0&&<h1 className='individualrequirementrewieshd text-left mt-4'>Comments</h1>}
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.comments.slice(0,10).map((el)=>(
  <div  className='w-6 flex my-4'>
  <div className='w-2 pt-1'><img src={bloguser} width={40} /></div>
  <div className='w-10 flex flex-column'>
    <h3 className='text-left individualreviewtext mt-0'>Unknown</h3>
    <span className='flex justify-content-start align-items-center mt-0 mb-0  '>
{/*     
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
 <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.rating)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.rating)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.rating)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.rating)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.rating)>4?"#0E8E60":"#E0E0E0"}/>
                    </svg> */}
    {/* <span className='individualdate '>6 months ago</span> */}
    </span>
    <span className='text-left individualreviewname'>{el}</span>
    {/* <div className='flex gap-3 mt-1'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div> */}
  </div>

</div>
                  ))}
        </div>
      </div>
}
{loader?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:

      <div className="flex md:hidden flex-column gap-2 w-full">
        {/* <div className='flex justify-content-between align-items-center'>
            <div  className='flex justify-content-center align-items-center gap-3'><Image style={{width:"30px",height:"30px"}} src={ReadBlog5} /><span id='main2tt'>Joana Marie Jones</span><Image  src={ReadBlog3} /></div>
            <i className="pi pi-ellipsis-v" style={{ fontSize: '1rem' }}></i>
        </div> */}
        <div className="flex align-items-center">
          <h1 id="main1tt_0" className="text-left w-10">
          {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title}

          </h1>
          <Heart styles={{height:"80px",marginTop:"-5px"}} isClick={isClick} onClick={() => setClick(!isClick)} />

        </div>
        <div className="flex flex-column gap-3">
          <div className="w-full text-left mb-2" id="main3tt_0">
          Published on &nbsp;
             {/* THU, DEC 1 20228:09 AM ESTUPDATED THU, DEC 1 202210:36 AM EST */}
             {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.datetime}
          </div>
          <div className="ridblgimg flex " style={{ width: "300px" }}>
            <Image src={indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title_image} width="100%" style={{objectFit:"cover"}}/>
          </div>
          <div className="w-full text-left" id="main3tt_0">
           
          </div>
        </div>
        <div className="pl-2 pr-2 w-full mt-4">
        <h2 className="w-full" id="briefdesc" dangerouslySetInnerHTML={{ __html: formattedHTML }} >
       

          </h2>
          <div className="w-full mt-6" id="id">
              {/* <label style={{color:"#0E8E60"}}>Comment</label> */}
          <div className='flex justify-content-end gap-3 mb-4'>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogeye} width={24}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_views}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogheart} width={22}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_likes}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-2'>
              <img src={blogcomment} width={17}  />
              <span className="bglstnmbr ">{indivualBlog&&indivualBlog.blog&&indivualBlog.blog.num_of_comments}</span>
            </div>
            <div className='flex justify-content-center align-items-center gap-4 ml-4'>
                <img src={blogdislike} width={22} className='mb-2 cursor-pointer' onClick={()=> handleLike2("like")}  />
              <img src={bloglike} width={22} className='mt-1 cursor-pointer' onClick={()=> handleLike2("dislike")}/>
            
            </div>
          </div>
            <InputTextarea autoResize value={value} onChange={(e) => setValue(e.target.value)} rows={4} className="cmmnttxtarea border-round-lg" placeholder='Post Your Comment' />
             <div className="flex justify-content-end mt-4"><Button className="addcmntbotn mt-1" onClick={handleSubmit}>Post Comment</Button></div> 
            </div>
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.comments.length>0&&<h1 className='individualrequirementrewieshd0 text-left mt-4'>Comments</h1>}
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.comments.slice(0,10).map((el)=>(
  <div  className='w-full flex my-4'>
  <div className='w-2 pt-1'><img src={bloguser} width={34} /></div>
  <div className='w-10 flex flex-column'>
    <h3 className='text-left individualreviewtext mt-0'>Unknown</h3>
    <span className='flex justify-content-start align-items-center mt-0 mb-0  '>

    </span>
    <span className='text-left individualreviewname0'>{el}</span>

  </div>

</div>
                  ))}
        </div>
      </div>
}
    </>
  );
}
