import axios from "axios";
import { environment, offlinehomepage_Api,  } from "../../../Api";
import { FETCH_REQUEST, FETCH_SUCCESS } from "../OfflineTypes/OfflineHome.types";





export const fetchHomePageoffline = () => async (dispatch) => {
  dispatch({ type: FETCH_REQUEST });
  try {

    let res=await axios.post(offlinehomepage_Api,{environment:environment})
    console.log(res.data)
    // alert(`your otp is ${res.data.otp}`)
     dispatch({ type: FETCH_SUCCESS,payload:res.data });
  } catch (e) {
    // dispatch({ type: REGISTER_ERROR });
  }
};
