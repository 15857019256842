import React, { useEffect, useState } from "react";

import img1 from "../../components/images/img1.png";
import img2 from "../../components/images/img2.png";
import "../selfLearningComponents/SelfCard/SelfCard.css";
import "./mylearningcards.css";
import ranjith from "../../../asserts/Ranjith.png"
import robotplaying from "../../../asserts/robotPlaying.png"
import brain from "../../../brain.gif"
import { ProgressBar } from "primereact/progressbar";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseList } from "../../../Redux/SelfLearning/SelfAction/Course.action";
const selfCardsData = [
  {
    id: 1,
    username: "Nizam Mohammad",
    program: "Master program in data science",
    time: "33 min",
    progress: 50,
    userImage: img1,
    cardImage: img2,
    courseField: "IT & Software",
  },
  {
    id: 2,
    username: "John Doe",
    program: "Advanced Web Development",
    time: "33 min",
    progress: 100,
    userImage: img2,
    cardImage: img1,
    courseField: "Data Science",
  },
  {
    id: 3,
    username: "abhijit",
    program: "data science and Ai learning",
    time: "33 min",
    progress: 80,
    userImage: img1,
    cardImage: img2,
    courseField: "Artificial Intelligence",
  },
  {
    id: 4,
    username: "John wick",
    program: "Full stack Web Development",
    time: "33 min",
    progress: 60,
    userImage: img2,
    cardImage: img1,
    courseField: "Design",
  },

  // Add more card data objects here if needed

  {
    id: 5,
    username: "khan Doe",
    program: "Advanced Web Development",
    time: "33 min",
    progress: 80,
    userImage: img2,
    cardImage: img1,
    courseField: "IT & Software",
  },
  {
    id: 6,
    username: "abhijit",
    program: "data science and Ai learning",
    time: "33 min",
    progress: 100,
    userImage: img1,
    cardImage: img2,
    courseField: "Data Science",
  },
  {
    id: 7,
    username: "John wick",
    program: "Full stack Web Development",
    time: "33 min",
    progress: 40,
    userImage: img2,
    cardImage: img1,
    courseField: "Artificial Intelligence",
  },
  {
    id: 8,
    username: "sai kumar",
    program: "Full stack Web Development",
    time: "33 min",
    progress: 60,
    userImage: img2,
    cardImage: img2,
    courseField: "Design",
  },
];

const SelfCard = ({ data }) => {
  const valueTemplate = () => {
    return <React.Fragment>{""}</React.Fragment>;
  };
  const isSmallScreen = window.innerWidth < 1030;
  const { username, program, time, progress, userImage, cardImage } = data;

  return (
    <div
      className="self-crd-container my-3"
      style={{
        display: "flex",          border:"1px solid red"

      }}
    >
      <div
        className="crd"
        style={{
          width: isSmallScreen ? "150px" : "290px",
          height: isSmallScreen ? "auto" : "377px",
          flexShrink: 0,
          borderRadius: isSmallScreen ? "10px" : "20px",
          // border: "0.5px solid black",
          backgroundColor: "#FFF",
          marginRight: "45px",
          border:"1px solid red"
        }}
      >
        <div
          className="flex justify-content-start align-items-center"
          style={{
            gap: isSmallScreen ? "0px" : "5px",
            marginLeft: "10%",
            paddingTop: "10px",

            marginTop: isSmallScreen ? "1%" : "4%",
            marginBottom: "3%",
          }}
        >
          <img
            style={{
              width: isSmallScreen ? "24px" : "35px",
              height: isSmallScreen ? "24px" : "35px",
              flexShrink: 0,objectFit:"cover"
            }}
            src={userImage}
            alt="User"
            className="rounded-circle user-image"
          />
          <p
            className="ml-3 mb-0"
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "7px" : "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: isSmallScreen ? "-0.024px" : "-0.029px",
              width: isSmallScreen ? "72px" : "",
              height: isSmallScreen ? "15px" : "",
              paddingBottom: "10px",
            }}
          >
            {username}
          </p>
        </div>
        <img
          src={cardImage}
          className=" md:pl-3 md:pr-3 container d-flex justify-content-center align-items-center"
          alt="crd"
          style={{
            // marginLeft: "5%",
            // width: isSmallScreen ? "133px" : "260px",
            height: isSmallScreen ? "88px" : "50%",
            marginTop: isSmallScreen ? "10px" : "",
            flexShrink: 0,
            display: "flex",objectFit:"cover",

            justifyContent: "center",
            alignItems: "center",
            borderRadius: isSmallScreen ? "5px" : "10px",
            marginBottom: isSmallScreen ? "-25px" : "-2px",
          }}
        />
        <div className="crd-body">
          <h6
            className="crd-title text-left"
            style={{
              width: isSmallScreen ? "130px" : "257px",
              height: isSmallScreen ? "30px  " : "",
              color: "#000",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "10px" : "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineWeight: "normal",
              letterSpacing: isSmallScreen ? "-0.024px" : "-0.036px",
              marginLeft: isSmallScreen ? "10px" : "25px",
              marginBottom: "10px",
              paddingTop: "30px",
            }}
          >
            {program}
          </h6>

          <ProgressBar
            value={progress}
            displayValueTemplate={valueTemplate}
            style={{
              width: isSmallScreen ? "130px" : "243px",
              height: "5px",
              flexShrink: 0,
              borderRadius: "5px",
              color: "green !important",
              background: "#BDB9B9",
              marginLeft: "6.5%",
              marginTop: isSmallScreen ? "30%" : "0%",
            }}
          ></ProgressBar>

          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              gap: isSmallScreen ? "18%" : "170px",
              marginBottom: isSmallScreen ? "0px" : "3px",
              paddingBottom: isSmallScreen ? "0px" : "20px",
            }}
          >
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {time}
            </h3>
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {100 - progress}% Left
            </h3>
          </div>
        </div>
        <Link
          className="text-left"
          to="/self-coursepage"
          style={{
            color: "#888",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.038px",
            padding: isSmallScreen ? "0px" : "0 0 20px 10px",
            textDecoration: "none",
            marginBottom: isSmallScreen ? "1px" : "10px",
          }}
        >
          Start Course
        </Link>
      </div>
    </div>
  );
};

const MyLearningCards = () => {
  const [showCount, setShowCount] = useState(4); 
const navigate =useNavigate()

  const handleSeeMoreClick = () => {
    setShowCount(CourseList?.courses?.length);
  };



const {CourseList}=useSelector(store=>store.course)
const dispatch=useDispatch()

useEffect(()=>{
dispatch(fetchCourseList())
},[])
console.log(CourseList,"courseList",showCount)


  const isSmallScreen = window.innerWidth < 1030;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const cardsPerPage = isSmallScreen ? 2 : 4;
  const totalPages = Math.ceil(selfCardsData.length / cardsPerPage);

  const handlePrevious = () => {
    const newIndex = Math.max(currentIndex - cardsPerPage, 0);
    setCurrentIndex(newIndex);
    setActivePage(Math.floor(newIndex / cardsPerPage));
  };

  const handleNext = () => {
    const newIndex = Math.min(
      currentIndex + cardsPerPage,
      (totalPages - 1) * cardsPerPage
    );
    setCurrentIndex(newIndex);
    setActivePage(Math.floor(newIndex / cardsPerPage));
  };

  const handlePageClick = (page) => {
    setCurrentIndex(page * cardsPerPage);
    setActivePage(page);
  };

  useEffect(() => {
    const handleResize = () => {
      if (isSmallScreen) {
        setCurrentIndex(0);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  const valueTemplate = () => {
    return <React.Fragment>{""}</React.Fragment>;
  };

  return (
    <>
  {CourseList.length==0?<div className="flex justify-content-center align-items-center w-full -mt-8 md:mb-8 h-25rem md:h-20rem" ><img src={brain} width={isSmallScreen?80:80} className=" m-auto   " ></img></div>:
(CourseList&&CourseList.courses?.slice(0, showCount).map((el) => (
  <>
 <div key={el.course_id} style={{width:"24%"}} className='tabcrslbox  p-3 mb-4 hidden md:flex flex-column justify-content-between' >
                       <div  className='flex h-2rem align-items-center gap-2' onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}><img src={el.instructor_photo} style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname'>{el.instructor_name}</span></div>
                       <div className='h-12rem pt-2' onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}><img src={el.course_image}   style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover"}} /></div>
                       <span className='tabcrslcoursename pt-2' style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}} onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}>{el.course_name}</span>
                       <ProgressBar className="mt-2" onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}
            value={el.percentage_watched}
            displayValueTemplate={valueTemplate}
            style={{   height: "5px",
              flexShrink: 0,
              color: "green !important",
              background: "#BDB9B9",
            }}
          ></ProgressBar>
            <div onClick={()=>navigate("/self-learning/self-coursepage")} className="flex justify-content-between mb-1 align-items-center">
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {el.video_length} mins
            </h3>
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {100-el.percentage_watched}% Left
            </h3>
          </div>
                       <div className='flex justify-content-start align-items-center'><Button className='tabbuybtn' onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}>{el.percentage_watched==0?"Start":"Continue"} Learning</Button></div>
                    </div>





                    <div style={{width:"100%"}}  className='tabcrslbox align-self-center  p-3 mb-4 md:hidden flex flex-column justify-content-between' >
                       <div  className='flex h-2rem align-items-center gap-2' onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}><img src={el.instructor_photo} style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname'>{el.instructor_name}</span></div>
                       <div className='h-12rem pt-2' onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}><img src={el.course_image}  style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover"}} /></div>
                       <span className='tabcrslcoursename pt-2' style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}} onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}>{el.course_name}</span>
                       <ProgressBar className="mt-2" onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}
            value={el.percentage_watched}
            displayValueTemplate={valueTemplate}
            style={{   height: "5px",
              flexShrink: 0,
              color: "green !important",
              background: "#BDB9B9",
            }}
          ></ProgressBar>
            <div className="flex justify-content-between mb-1 align-items-center">
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {el.video_length} mins
            </h3>
            <h3
              style={{
                color: "#888",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.029px",
              }}
            >
              {100-el.percentage_watched}% Left
            </h3>
          </div>
                       <div className='flex justify-content-start align-items-center'><Button className='tabbuybtn'onClick={()=>navigate(`/self-learning/course-page/${el.course_id}`)}>{el.percentage_watched==0?"Start":"Continue"} Learning</Button></div>
                    </div>



        </>  )))
            }
                
       {showCount < CourseList?.courses?.length ? (
          <h5 className='w-full text-right categoriesSeeMorebtn pr-4 mb-4 md:mb-0' onClick={handleSeeMoreClick} >see more</h5>
       ):""}
    </>
  );
};

export default MyLearningCards;
