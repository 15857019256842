import { COMMENT_ADD_SUCCESS, FETCH_BLOG_SUCCESS, FETCH_INDIVIDUAL_BLOG_REQUEST, FETCH_INDIVIDUAL_BLOG_SUCCESS, RESET } from "../SelfTypes/Blog.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    homeData:[],
    message: "",
    indivualBlog:[]
  };
  
  export const BlogReducer = (state = initialState, { type, payload }) => {
    switch (type) {

  
      case FETCH_BLOG_SUCCESS: {
        return {
          ...state,
          loading: false,
          homeData: payload,
          error:false,
        };
      }
  case FETCH_INDIVIDUAL_BLOG_REQUEST:{
    return{
      ...state,loading:true
    }
  }
    case FETCH_INDIVIDUAL_BLOG_SUCCESS:{
        return{
            ...state,indivualBlog:payload,loading:false
        }
    }
   case COMMENT_ADD_SUCCESS:{
    return{
      ...state,message:payload
    }
   }
   case RESET:{
    return{
      ...state,message:""
    }
   }
      default:
        return state;
    }
  };
  