export const COURSELIST_REQUEST = "COURSELIST_REQUEST";
export const COURSELIST_SUCCESS = "COURSELIST_SUCCESS";
export const COURSELIST_FAIL = "COURSELIST_FAIL";


export const COURSEDETAILS_REQUEST="COURSEDETAILS_REQUEST";
export const COURSEDETAILS_SUCCESS="COURSEDETAILS_SUCCESS";
export const COURSEDETAILS_fAIL="COURSEDETAILS_FAIL";


export const INDIVIDUALCOURSEDETAILS_REQUEST="INDIVIDUALCOURSEDETAILS_REQUEST";
export const INDIVIDUALCOURSEDETAILS_SUCCESS="INDIVIDUALCOURSEDETAILS_SUCCESS";
export const INDIVIDUALCOURSEDETAILS_fAIL="INDIVIDUALCOURSEDETAILS_FAIL";


export const DYNAMICCOURSES_REQUEST='DYNAMICCOURSES_REQUEST_REQUEST'
export const DYNAMICCOURSES_SUCCESS='DYNAMICCOURSES_REQUEST_SUCCESS'
export const DYNAMICCOURSES_FAIL='DYNAMICCOURSES_REQUEST_FAIL'