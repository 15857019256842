import React from "react";
// import Individual from "../../components/IndividualCourse";
import Navbar from "../../components/SelfNavbar";
import BuyThisComponent from "../../components/BuyThisCourseComponents/SelfBuyThisComponent";
// import SelfCardList from "../../components/selfLearningComponents/SelfCard";
import Foot from "../../components/Foot";
import YouMightLike from "../../components/BuyThisCourseComponents/SelfYouMightLike";
const BuyThisCoursePage = () => {
  return (
    <>
      <Navbar />
      <BuyThisComponent />
      {/* <h3
        className="text-left"
        style={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Poppins",
          fonSize: "36px",
          fonStyle: "normal",
          fontWeight: 600,
          lineHeight: "44.5px",
          marginLeft: "6%",
        }}
      >
        You Might also{" "}
        <span
          className="b"
          style={{
            color: "#0E8E60",
          }}
        >
          Like
        </span>
      </h3> */}
      {/* <SelfCardList /> */}
      {/* <YouMightLike /> */}
      {/* <Foot /> */}
      {/* <Individual /> */}
    </>
  );
};

export default BuyThisCoursePage;
