import { FETCH_REQUEST, FETCH_SUCCESS } from "../OfflineTypes/OfflineHome.types";

const initial = {
    loading:false,
    error:false,
    data:[],
   };
   
   export const OfflineHomeReducer = (state = initial, { type, payload }) => {
     switch (type) {
      
     //   case LOGOUT: {
     //     console.log("logout")
     //     localStorage.removeItem("token");
     //     return {
     //       ...state,
     //       isAuth: false,
     //       token: ""
     //     };
     //   }
     case FETCH_REQUEST:{
      return{
        ...state,loading:true
      }
     }
     case FETCH_SUCCESS:{
       return{
         ...state,data:payload,loading:false
       }
     }
 
       default:
         return state;
     }
   };
   