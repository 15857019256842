import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

export default function PrivateRoute({children}) {
    const {token}=useSelector(store=>store.auth)
    const navigate=useNavigate()

if(token && token.status === 'ACTIVE'){
    console.log(token);
    //   navigate("/self-learning")
    return children

}

 else {
    return ( <Navigate  to='/self-learning'  />)
 }

}
