import axios from "axios"
import { FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, PROFILE_PHOTO, PROFILE_RESET, UPDATE_PROFILE_SUCCESS } from "../SelfTypes/Profile.types";
import { environment, fetchProfileApi, updateProdileApi, updateProfileImageApi } from "../../../Api";




export const ProfileFetch = () => async (dispatch) => {
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
    dispatch({type:FETCH_PROFILE_REQUEST})

     console.log(brainystudenttoken);
   const payload = {
        environment: environment,
        user_id: brainystudenttoken&&brainystudenttoken.user_id,
        role: "student"
    }
    // console.log(payload,"payload");
    
        try{
            let response= await axios.post(fetchProfileApi,payload)
            console.log(response.data);
            dispatch({ type: FETCH_PROFILE_SUCCESS,payload:response.data});
        }
        catch(e){
    console.log(e);
        }
    
    }


    export const UpdateProdile=(data)=>async(dispatch)=>{
// console.log(data);  {key: 'basic details updated'}
let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
dispatch({type:FETCH_PROFILE_REQUEST})
const payload={
    environment: environment,
    details:{...data,user_id: brainystudenttoken&&brainystudenttoken.user_id},
    key_word: "PROFILE"
}
console.log(payload, "final");
        try{
          let response=await axios.post(updateProdileApi,payload)
          console.log(response)
          dispatch({type:UPDATE_PROFILE_SUCCESS,payload:response.data.key})
        }
        catch(e){

        }

    }

export const ProfileReset=()=>async(dispatch)=>{
        dispatch({type:PROFILE_RESET})
    }



    export const ProfilePhoto=(data)=>async(dispatch)=>{
        let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
dispatch({type:FETCH_PROFILE_REQUEST})
        console.log(data);
        const payload={
            environment: environment,
            key_word: "PHOTO",
            details: {
                user_id: brainystudenttoken&&brainystudenttoken.user_id,
                profile_photo: data}
        }
        try{
            let response=await axios.post(updateProfileImageApi,payload)
            console.log(response.data)
            dispatch({type:PROFILE_PHOTO,payload:response.data.message})


        }
        catch(e){

        }
    }