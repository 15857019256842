import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Blog from "../SelfBlog/blog";
import axios from "axios";
// import { AllBlog_Api, environment_for_Api, fetchBlog_Api } from '../apis';
import { useNavigate } from "react-router-dom";
import { FetchBlogHome } from "../../../Redux/SelfLearning/SelfAction/Blog.action";
import { useDispatch, useSelector } from "react-redux";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

export default function Carausels() {
  let arr = ["one", "two", "three", "four", "five", "six"];
  const [data, setData] = useState([]);


  const dispatch=useDispatch()
  const {homeData}=useSelector(store=>store.blog)
  console.log(homeData);
  //   useEffect(()=>{
  // dispatch(FetchBlogHome())
  //   },[])
    // useEffect(()=>{
    //   if(homeData){
    //     setData(homeData)
    //   }
    // },[homeData])
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 712 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 712, min: 0 },
      items: 1,
    },
  };
  return (
    <>
 
<div className="hidden md:block ">
<Splide hasTrack={ false } className=' ' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 4, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
        > 
          <SplideTrack>

        {homeData&&homeData.popular_blogs?.slice(0,10).map((el)=>(
 <SplideSlide key={el.blog_id}>
   <Blog key={el.blog_id} elem={el} />
</SplideSlide>
        ))}
        </SplideTrack>

   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-2 -ml-5"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-2 -mr-5"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
       </div>

       <div className=" md:hidden">
<Splide hasTrack={ false } className=' ' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 1, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
        > 
          <SplideTrack>

        {homeData&&homeData.popular_blogs?.slice(0,10).map((el)=>(
 <SplideSlide key={el.blog_id}>
   <Blog key={el.blog_id} elem={el} />
</SplideSlide>
        ))}
        </SplideTrack>

   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-2 -ml-1"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-2 -mr-1"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
       </div>

    </>
  );
}
