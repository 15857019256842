import { INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS, OLDYNAMICCOURSES_SUCCESS } from "../OnlineTypes/OnlineCourse.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    message: "",
    dynamicCourses:[],
    IndividualCourseData:[]
  };
  
  export const OnlineCourseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case INDIVIDUALCOURSEDETAILS_REQUEST:{
        console.log("yesreq");
        return{
          ...state,loading:true
        }
      }
    case INDIVIDUALCOURSEDETAILS_SUCCESS:{
      return {
        ...state,IndividualCourseData:payload,loading:false
      }
    }
    case OLDYNAMICCOURSES_SUCCESS:{
      return{
        ...state,dynamicCourses:payload
      }
     }
      default:
        return state;
    }
  };
  