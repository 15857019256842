import { COURSEDETAILS_SUCCESS, COURSELIST_SUCCESS, DYNAMICCOURSES_SUCCESS, INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS } from "../SelfTypes/Course.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    message: "",
    Coursedata:[],
    CourseList:[],
    IndividualCourseData:[],
    dynamicCourses:[]
  };
  
  export const CourseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case COURSELIST_SUCCESS: {
        return {
          ...state,
          loading: false,CourseList:payload
        };
      }
    case COURSEDETAILS_SUCCESS:{
      return{
        ...state,Coursedata:payload,IndividualCourseData:[]
      }
    }
    case INDIVIDUALCOURSEDETAILS_REQUEST:{
      console.log("yesreq");
      return{
        ...state,loading:true
      }
    }
    case INDIVIDUALCOURSEDETAILS_SUCCESS:{
      return {
        ...state,IndividualCourseData:payload,loading:false
      }
    }
     case DYNAMICCOURSES_SUCCESS:{
      return{
        ...state,dynamicCourses:payload,IndividualCourseData:[]
      }
     }
      default:
        return state;
    }
  };
  