import "../privacypolicy/privacypolicy.css";
import Navbar from "../SelfNavbar";
import Footer from "../Foot";
import { useEffect } from "react";

function SelfRefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="flex flex-column justify-content-between align-items-center h-full w-full overflow-y-auto">
      <Navbar />
      <div className="flex w-11 flex-column px-2 md:px-5 text-left pb-6 ">
        <h1 className="text-5xl font text_color my-4 md:my-6">Refund Policy</h1>
        <p className="font para-lines my-1">
          At BrainyBits, we strive to provide high-quality e-learning courses
          and services to our users. We understand that sometimes, you may need
          to request a refund for a course or service that you have purchased
          from us. This refund policy (“Policy”) outlines our policies and
          procedures for refunds.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Eligibility for Refunds
        </h4>
        <p className="font para-lines my-1">
          We offer refunds for courses and services purchased from BrainyBits.in
          within the following conditions:
        </p>
        <ol className="pl-4 liststyletype">
          <li className="font para-lines my-1 listparalines">
            You have requested a refund within 7 days of the purchase date.
          </li>
          <li className="font para-lines my-1 listparalines">
            You have not completed more than 10% of the course content.
          </li>
          <li className="font para-lines my-1 listparalines">
            You have not accessed or downloaded any course materials.
          </li>
          <li className="font para-lines my-1 listparalines">
            The course or service does not meet your expectations.
          </li>
        </ol>
        <p className="font para-lines my-1">How to Request a Refund</p>
        <p className="font para-lines my-1">
          To request a refund, please send an email to info@brainybits.in with
          the following information:
        </p>
        <ol className="pl-4 liststyletype">
          <li className="font para-lines my-1 listparalines">
            Your name and email address associated with your BrainyBits account.
          </li>
          <li className="font para-lines my-1 listparalines">
            The name and date of the course or service for which you are
            requesting a refund.
          </li>
          <li className="font para-lines my-1 listparalines">
            A brief explanation of the reason for your refund request.
          </li>
        </ol>
        <p className="font para-lines my-1">
          We will review your request and respond to you within 3 business days.
        </p>

        <h4 className="font text_color para-lines font-bold mt-4">
          Refund Process
        </h4>
        <p className="font para-lines my-1">
          If your refund request is approved, we will process your refund within
          7 business days. The refund will be credited to the payment method
          used to purchase the course or service.
        </p>
        <p className="font para-lines my-1">Exceptions to Refunds</p>
        <p className="font para-lines my-1">
          We do not offer refunds in the following situations:
        </p>
        <ol className="pl-4 liststyletype">
          <li className="font para-lines my-1 listparalines">
            You have completed more than 10% of the course content.
          </li>
          <li className="font para-lines my-1 listparalines">
            You have accessed or downloaded any course materials.
          </li>
          <li className="font para-lines my-1 listparalines">
            The course or service meets your expectations.
          </li>
          <li className="font para-lines my-1 listparalines">
            The refund request is made after 7 days of the purchase date.
          </li>
        </ol>
        <h4 className="font text_color para-lines font-bold mt-4">
          Contact Us
        </h4>
        <p className="font para-lines my-1">
          If you have any questions or comments about this Policy, please
          contact us at info@brainybits.in By using our Platform and purchasing
          our courses and services, you acknowledge that you have read and
          understood this Policy and agree to be bound by it.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default SelfRefundPolicy;
