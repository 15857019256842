import {
    combineReducers,
    legacy_createStore,
    applyMiddleware,
    compose
  } from "redux";
  import thunk from "redux-thunk";
import { AuthReducer } from "./SelfLearning/SelfReducer/Auth.reducer";
import { ProfileReducer } from "./SelfLearning/SelfReducer/Profile.reducer";
import { SelfHomeReducer } from "./SelfLearning/SelfReducer/SelfHome.reducer";
import { CourseReducer } from "./SelfLearning/SelfReducer/Course.reducer";
import { CartReducer } from "./SelfLearning/SelfReducer/Cart.reducer";
import { BlogReducer } from "./SelfLearning/SelfReducer/Blog.reducer";
import { OnlineHomeReducer } from "./Online/OnlineReducer/Online.reducer";
import { OnlineCourseReducer } from "./Online/OnlineReducer/OnlineCourse.reducer";
import { OfflineHomeReducer } from "./Offline/OfflineReducer/OfflineHome.reducer";
import { OfflineCourseReducer } from "./Offline/OfflineReducer/offlineCourse.reducer";

 
  
  const rootReducer = combineReducers({
    auth: AuthReducer,
  profile:ProfileReducer,
  selfhome:SelfHomeReducer,
  course:CourseReducer,
  cart:CartReducer,
  blog:BlogReducer,
//   cart:cartReducer
onlinehome:OnlineHomeReducer,
OnlineCourse:OnlineCourseReducer,
offlinehome:OfflineHomeReducer,
offlineCourse:OfflineCourseReducer

   
  });
  
  const createComposer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
  
  export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));