import { InputText } from "primereact/inputtext";
import { useState,useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import Brainybits from "../../../asserts/Brainybits.png";
import Girlwithlaptop from "../../../asserts/Girlwithlaptop.png";
// import callTheServer from "../apicalling/apicall";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import axios from "axios";
import { Toast } from "primereact/toast";
import { updatePasswordApi } from "../../../Api";

const UpdatePassword = () => {
  const history = useNavigate();
  const [message, setMessage] = useState("");
  const [oldPassword,setOldPassword]=useState("")
  const [password, setpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [passwordView, setPasswordView] = useState({
    password: false,
    newPassword: false,
  });
  const [loading,setLoading]=useState(false)
//   const searchParams = new URLSearchParams(location.search);
//   const x = searchParams.get("x");
//   const y = searchParams.get("y");
// console.log(searchParams,x,y);
const toast = useRef(null);

   

const Reject = (res) => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: res, life: 3000 });
}
const accept = (res) => {
    toast.current.show({ severity: 'success', summary: 'Confirmed', detail: res, life: 3000 });
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    const regx = new RegExp( "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    // console.log(regx.test("uI(90ee11"));
    console.log(regx.test(password));
    if(password==="" || newPassword==="" || oldPassword===""){
     setErrorMessage("Please fill all fields")
    }
    else if (password !== newPassword) {
      setErrorMessage("Password Doesn't match");
    } else if (!regx.test(password)) {
      setErrorMessage("Minimum eight characters, at least one upper letter and one number and special char ! @ # $ % ^ &" );
    } else {
      setLoading(true)
     try{
        
        let data=JSON.parse(localStorage.getItem('brainystudenttoken'))
        let payload={
            environment:"dev",
            "user_id":data&&data.user_id ,
    "old_password":oldPassword, 
    "new_password":password
         }
        console.log(payload)
    let res=await axios.post(updatePasswordApi,payload)
    setLoading(false)
    console.log(res.data)
    if(res.data.key=="Password is updated sucessfully."){
        accept(res.data.key)
        setTimeout(()=>{
            history("/self-learning")
        },3000)
    }
    else {
        Reject(res.data.key)
    }
     
        }
     catch(e){
 Reject("Something wrong")
console.log(e)
     }







    //   if (data.key === "sucessfully_updated") {
    //     setMessage("Password Updated Successfully");
    //     setVisible(true);
    //     setLoader(true);
    //     setTimeout(() => {
    //       setLoader(false);
    //     }, 2000);
    //   } else {
    //     setMessage("Password is not updated");
    //     setVisible(true);
    //     setLoader(true);
    //     setTimeout(() => {
    //       setLoader(false);
    //     }, 3000);
    //   }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          setVisible(false);
          history("/login");
        }}
        autoFocus
      />
    </div>
  );

  return (
    <>
       <Toast ref={toast} />
       <div className=" w-full flex flex-column md:flex-row align-items-center justify-content-center" style={{height:"100vh"}}>
      <section className="md:w-7 w-full h-full flex flex-order-1 md:flex-order-0 align-items-center justify-content-center">
        <div className="md:h-8 my-auto h-full md:w-8 w-full p-4 md:p-0 flex flex-column justify-content-center align-items-start">
          <h1 className="text-4xl font-bold">Update password</h1>
          <p className="text-sm my-2 md:w-9 w-full text-left">
            Input your new desired password in the input fields below to create
            a new password. We strongly advise you to store it safely.
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-column align-items-start md:w-9 w-full"
          >
            <label className="text-sm mt-3 mb-1">Old password</label>
            <span className="p-input-icon-right w-full">
             
     <Password  className="p-password-input h-3rem w-full"  strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$" id="password" placeholder="Password" name='password'    onChange={(e) => {
                  setOldPassword(e.target.value);
                  setErrorMessage("");
                }}   toggleMask/>
            </span>

            <label className="text-sm mt-3 mb-1">New password</label>
            <span className="p-input-icon-right w-full">
             
     <Password  className="p-password-input h-3rem w-full"  strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$" id="password" placeholder="Password" name='password'    onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrorMessage("");
                }}   toggleMask/>
            </span>

            <label className="text-sm mt-3 mb-1">Confirm new password</label>
            <span className="p-input-icon-right w-full">
             
                <Password  className="p-password-input h-3rem w-full"  strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$" id="password" placeholder="Password" name='password'     onChange={(e) => {
                  setpassword(e.target.value);
                  setErrorMessage("");
                }}  toggleMask/>
            </span>
            {errorMessage !== "" && (
              <span className="text-red-400 text-xs text-left">{errorMessage}</span>
            )}
{loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>}   className="w-full mt-5 border-round-lg dgsignupbtn"/>: <Button
              type="submit"
              label="Change password "
              className="w-full mt-5 border-round-lg dgsignupbtn"
            />}
            {/* <Button
              type="submit"
              label="Change password "
              className="w-full mt-5 border-round-lg"
            /> */}
          </form>
          {/* <Dialog
            header=""
            visible={visible}
            style={{ width: "50vw" }}
            footer={footerContent}
            onHide={() => {
              setVisible(false);
            }}
          >
            <div className="flex justify-content-center align-items-center h-full w-full">
              {loader ? (
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="3"
                  color="var(--greenn-500)"
                  fill="#ffffff"
                  animationDuration=".5s"
                />
              ) : (
                <p>{message}</p>
              )}
            </div>
          </Dialog> */}
        </div>
      </section>
      <section className="md:w-5 w-full p-4 pt-7 md:pt-0 md:p-0 md:flex-order-1 flex-order-0 md:h-full  flex align-items-center justify-content-center" style={{backgroundColor:"#0E8E60"}}>
        <div className="h-11 md:gap-4 gap-2 w-8 flex flex-column align-items-center mt-8 md:mt-0 mb-4 md:mb-0">
          <img className="w-11" src={Brainybits} alt="KKKK" />
          <img className="md:w-9 w-7" src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
    </div>
    </>
  );
};

export default UpdatePassword;
