
import React, { useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import "./CreateBlog.css"
import axios from 'axios';
// import { createBlog_Api, environment_for_Api, uploadBlogImage_Api } from '../apis';
import { useNavigate } from 'react-router-dom';
export default function ConfirmModal({data}) {
   
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
const navigate=useNavigate()

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Blog Posted', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: '! You have Changed your mind', life: 3000 });
    }
    const cancel = () => {
        toast.current.show({ severity: 'error', summary: 'Rejected', detail: '! Please fill all input', life: 3000 });
    }

    const confirm1 = () => {
        if(data.title.length>0 && data.content.length>0){
           setVisible(true)
        console.log(data)
        }
        else{
          cancel()
        }
       
       
    };

  
const post=async()=>{
    
    


// try{

// let response=await axios.post(uploadBlogImage_Api,{"environment":environment_for_Api, "img":data.title_image})
// console.log(response.data)

// if(response.data.img_link){ 
//    let main={...data,title_image:response.data.img_link}


//    try{
//     let response=await axios.post(createBlog_Api,main)
//     console.log(response.data)
    
//     if(response.data.key=="ingestion_sucessfull"){ 
//         accept()
//     setTimeout(()=>{
//         navigate("/home_blog")
//     },4000)
//     }
//     else reject()
//     }
//     catch(e){
//     console.log("error",e)
//     }


// console.log(main)
// }
// // else reject()
// }
// catch(e){
// console.log("error",e)
// }






    }

  
    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)}  message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={post} reject={cancel} />
            <div className="card flex flex-wrap gap-2 justify-content-center">
                {/* <Button onClick={confirm1} icon="pi pi-check" label="Confirm" className="mr-2"></Button> */}
                <div id='crtbtn' onClick={confirm1}>Submit</div>
            </div>
        </>
    )
}
        