import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGOUT,
  SIGNUP_RESET,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  PAYMENT_SUCCESS,
} from "../SelfTypes/Auth.types";

let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
const initialState = {
  loading: false,
  error: false,
  isAuth: !!brainystudenttoken && brainystudenttoken.user_id,
  token: brainystudenttoken ,
  message: "",
};

export const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGNUP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SIGNUP_SUCCESS: {
      localStorage.setItem("brainystudenttoken", JSON.stringify(payload));
      return {
        ...state,
        loading: false,
        isAuth: true,
        token: payload,
        message: payload.key,
        error: "",
      };
    }

    case SIGNUP_RESET: {
      return {
        ...state,
        message: "",
      };
    }

    case SIGNUP_FAIL: {
      return {
        ...state,
        loading: false,
        message: payload,
      };
    }
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      localStorage.setItem("brainystudenttoken", JSON.stringify(payload));
      return {
        ...state,
        loading: false,
        isAuth: true,
        token: payload,
        error: "",
      };
    }
    case PAYMENT_SUCCESS:{
      return{
        loading: false,
        isAuth: true,
        token: payload,
        error: "",
      }
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        loading: false,
        isAuth: false,
        message: payload.key,
      };
    }
    case LOGOUT: {
      localStorage.removeItem("brainystudenttoken");
      return { ...state, loading: false, isAuth: false, token: "", error: "" };
    }

    default:
      return state;
  }
};
