import React, { useEffect, useRef, useState } from "react";
//import { useRouter } from 'next/router';
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { json, useNavigate } from "react-router-dom";
import "./profile.css";
import { useDispatch, useSelector } from "react-redux";
import { SignOutfuntion, SignupReset } from "../../../Redux/SelfLearning/SelfAction/Auth.action";
import { Dialog } from "primereact/dialog";
export default function Profile({background}) {
  const navigate = useNavigate();
  //   const menuLeft = useRef(null);
  const dispatch=useDispatch()
  const {token,message}=useSelector(store=>store.auth)
  const menuRight = useRef(null);
  //const router = useRouter();
  const handleLogout=()=>{
    dispatch(SignOutfuntion())
    navigate("/self-learning")
  }
  const toast = useRef(null);
  let name= token&& token.full_name?token.full_name.split(" "):"User"

  const generateMenuItems = () => {

  const baseItems = [
    {
      label: (
        <>
          <div
            className="flex justify-content-center align-items-center "
            style={{ width: "fitContent", gap: "3%" }}
          >
            <div className="flex  justify-content-center align-items-center " >
              <p
                className="flex  align-items-center justify-content-center "
                style={{
                  backgroundColor: "#0E8E60",
                  fontSize: "1.6rem",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  color: "#fff",
                }}
              >
                {name[0][0]}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // marginTop: "10px",
                // border:"1px solid red"
              }}
            >
              <span
                style={{
                  color: "#0E8E60",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "100%" /* 24px */,
                }}
              >
                {name[0]}
              </span>

              <p className="mt-1"
                style={{
                  color: "#263238",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "100%" /* 16px */,
                }}
              >
                {token&&token.email_id&&token.email_id.substring(0, 15)+"..."}
              </p>
            </div>
          </div>
        </>
      ),

      
      items: [
        {
          label: "My Learning",
          command: () => {
            navigate("/self-learning/mylearning");
          },
        },
        {
          label: "Edit Profile",

          command: () => {
            handleClick()
          },
        },
        {
          label: "My Cart",

          command: () => {
            navigate("/self-learning/cart");
          },
        },
        {
          label: "Update Password",

          command: () => {
            navigate("/self-learning/update-password")
          },
        },
        {
          label: "Logout",

          command: () => { handleLogout()},
        },
      ],
    },
  ];
  if (token && token.status === 'INACTIVE') {
    // Include the "My Learning" item for active users
    baseItems[0].items.shift({
      label: "My Learning",
      command: () => {
        navigate("/self-learning/mylearning");
      },
    });
  }
  return baseItems;
};
const items = generateMenuItems();

  const handleClick=()=>{
    if(token && token.status === 'INACTIVE') navigate("/self-learning/cart")
    else if(token && token.status === "ACTIVE") navigate("/self-learning/edit-profile")
  }
  const [visible2, setVisible2] = useState(false);
  const footerContent = (
    <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => {setVisible2(false)}} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
  );
    useEffect(()=>{
      //  console.log(message);
    if(message=='login successful'){ 
      // console.log(message);
      setVisible2(true)
      setTimeout(()=>{
        dispatch(SignupReset())
      },3000)
    // dispatch(SignupReset())
    }
    else{
      dispatch(SignupReset())
      setVisible2(false)
      // setVisible2(true)
    }
    // console.log(visible2);
    // dispatch(SignupReset())

    },[message])
// console.log(visible2);
  return (
    <div className=" flex justify-content-center">
      <Dialog header=""  className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0    " visible={visible2} onHide={()=>{setVisible2(false)}}  >
       <style>
    {`
      .p-dialog .p-dialog-header {
        display: none !important;
      }
      .p-dialog .p-dialog-content:last-of-type {
        border-Top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    `}
  </style>
              <h3 className="my-4  m-auto text-center " >You have successfully Login.</h3>
              <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => {setVisible2(false)}} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
              </Dialog>
      <Toast ref={toast}></Toast>
      {/* <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            <Button label="Show Left" icon="pi pi-align-left" className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup /> */}
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />
      <Button
        icon="pi pi-user"
        className={` mr-1`}
        onClick={(event) => menuRight.current.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
        style={{
          backgroundColor: background=="green"?"#FFF":"#0E8E60",
          color:background=="green"?"#0E8E60":"#FFF",
          border: "none",
          borderRadius: "50%",
          outline: "none !important",
        
        }}
      />
    </div>
  );
}
