import React, { useEffect, useState } from "react";
import Navbar from "../../components/SelfNavbar/index";
import "./SelfLearningPage.css";
import SelfLearningTop from "../../components/selfLearningComponents/SelfLearnTop";
import SelfCard from "../../components/selfLearningComponents/SelfCard";
import Testimonial from "../../components/selfLearningComponents/Testimonial";
import dot from "../../utils/selfpageimgs/Ellipse 186.svg";
import FaqsComponent from "../../components/selfLearningComponents/FaqsComponent";
import Foot from "../../components/Foot";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import ranjith from "../../../asserts/Ranjith.png";
import { Button } from "primereact/button";
import Rectanglerobot from "../../../asserts/Rectanglerobot.png";
import Artificialinteligence from "../../../asserts/Artificialinteligence.png";
import RectangleDeepLearning from "../../../asserts/RectangleDeepLearning.png";
import RectangleMechineLearning from "../../../asserts/Test_Automation.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoursePage } from "../../../Redux/SelfLearning/SelfAction/SelfHome.action";
import { AddToCartFunction } from "../../../Redux/SelfLearning/SelfAction/Cart.action";
import brain from "../../../brain.gif"

const SelfLearningPage = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dispatch=useDispatch()
  const {data}=useSelector(store=>store.selfhome)
  const {isAuth}=useSelector(store=>store.auth)

  useEffect(()=>{
     dispatch(fetchCoursePage())
  },[])
// console.log(data,"homepage");
const CartAddfunction=(id,el)=>{
// console.log(id,el);
dispatch(AddToCartFunction(id,{...el,course_type:"SL"}))
navigate('/self-learning/cart')
}

  return (
    <>
      <Navbar />
      {data.length==0?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   " ></img></div>:
<>
      <SelfLearningTop />

     

      <div style={{ justifyContent: "center" }}>
        <div
          className="container d-flex justify-content-center mt-4 md:mt-0  md:my-3 "
          style={{
            paddingTop: isSmallScreen ? "10%":"70px",
            margin: isSmallScreen ? "auto" : "",
          }}
        >
          <h1 className=" md:pl-0 "
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: `${isSmallScreen ? "16px" : "36px"}`,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: isSmallScreen ? "15px" :"44.5px",
              // marginLeft: isSmallScreen ? "px" : "",
            }}
          > Explore{" "}
            <span
              style={{
                color: "#0E8E60",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: `${isSmallScreen ? "16px" : "36px"}`,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight:isSmallScreen ? "15px" : "44.5px",
              }}
            >
              Self Learning Online
            </span>{" "}
            Courses
          </h1>
        </div>
      </div>
    
      <div className="w-full pl-8 pr-8 hidden md:flex  gap-4" >
      <div className="card w-full pr-4 mb-4" >
            <TabView  className=''>
            
              {data&& data.Course_List? Object.entries(data.Course_List).map(([category, datam]) => (
                 <TabPanel className='tabhdr' key={category} header={category }>
                  {/* <style>
    {`
      .p-tabview-nav {
        display: flex;
         justify-content: space-around; 
        gap: 0%;
        align-items: center;
        list-style-type: none;
        flex: 1 1 auto;
      
    }
    .p-tabview .p-tabview-nav li {
      margin-right: 0;
      margin-left: 0;
  }
    `}
  </style> */}
                 <Splide  hasTrack={ false } className='mt-5 sdbox' options={{
             rewind:false, 
             autoplay: true,
             perMove: 1,
             perPage: 4, 
             gap: '1rem',
             arrows: true,
             pagination: true
            
          }}
          
         > 
           <SplideTrack >
   
         {datam&&datam.course_list.map((el)=>(
   <SplideSlide >
   <div key={el.course_id} className='tabcrslbox w-12 p-3  flex flex-column justify-content-between cursor-pointer' >
                        <div  className='flex h-2rem align-items-center gap-2 mb-1' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.instructor_photo } style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.instructor_name}</span></div>
                        <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_photo} onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>
                        <span className='tabcrslcoursename pt-2' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                        <span className='offlinecllgrating text-left flex gap-1 align-items-center' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{height:"15px"}}> <span className='tabcrslname'>{el.ratings} </span> <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={el.ratings>=1?"#0E8E60":"#E0E0E0"}/>
                          <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={el.ratings>=2?"#0E8E60":"#E0E0E0"}/>
                          <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={el.ratings>=3?"#0E8E60":"#E0E0E0"}/>
                          <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={el.ratings>=4?"#0E8E60":"#E0E0E0"}/>
                          <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={el.ratings>=5?"#0E8E60":"#E0E0E0"}/>
                        </svg> <span className='tabcrslname'> ({el.total_people_rated})</span></span>
                        <div className='flex justify-content-between align-items-center'><span className='tabprice' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                     </div></SplideSlide>
         ))}
         </SplideTrack>
   
       
    <div className="splide__arrows">
     <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
     <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>
   
   </button>
   </div>
        </Splide>
   
                   
   
                 </TabPanel>
              )
              ) :""}
              {/* <TabPanel className='tabhdr'  header='IT & Software'>
              <Splide  hasTrack={ false } className=' sdbox' options={{
          rewind:false, 
          autoplay: true,
          perMove: 1,
          perPage: 4, 
          gap: '1rem',
          arrows: true,
          pagination: true
         
       }}
       
      > 
        <SplideTrack >

      {array.map((el)=>(
<SplideSlide >
<div key={el.id} className='tabcrslbox w-12 p-3 flex flex-column justify-content-between' >
                     <div  className='flex h-2rem align-items-center gap-2'><img src={ranjith} style={{borderRadius:"50%",height:"100%",borderRadius:"15px"}} /><span className='tabcrslname'>{el.mentor}</span></div>
                     <div className='h-12rem pt-2'><img src={el.image} onClick={()=>navigate('/self-learning/selfbuythiscourse')} style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer"}} /></div>
                     <span className='tabcrslcoursename pt-2'>{el.courseName}</span>
                     <span className='text-left flex align-items-center' style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                       <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
                       <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
                       <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
                       <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
                       <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#E0E0E0"/>
                     </svg></span>
                     <div className='flex justify-content-between align-items-center'><span className='tabprice'>{el.price}</span><Button className='tabbuybtn' onClick={()=>navigate('/self-learning/cart')}>Buy now</Button></div>
                  </div></SplideSlide>
      ))}
      </SplideTrack>

     
 <div className="splide__arrows">
  <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
  <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
</div>
     </Splide>


              </TabPanel>
                <TabPanel className='tabhdr'  header='Data Science'>
                <Splide  hasTrack={ false } className=' sdbox' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 4, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
         
        > 
          <SplideTrack >

        {array.map((el)=>(
 <SplideSlide >
<div key={el.id} className='tabcrslbox w-12 p-3 flex flex-column justify-content-between' >
                       <div  className='flex h-2rem align-items-center gap-2'><img src={ranjith} style={{borderRadius:"50%",height:"100%",borderRadius:"15px"}} /><span className='tabcrslname'>{el.mentor}</span></div>
                       <div className='h-12rem pt-2'><img src={el.image} onClick={()=>navigate('self-learning/selfbuythiscourse')} style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer"}} /></div>
                       <span className='tabcrslcoursename pt-2'>{el.courseName}</span>
                       <span className='text-left flex align-items-center' style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                         <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#E0E0E0"/>
                       </svg></span>
                       <div className='flex justify-content-between align-items-center'><span className='tabprice'>{el.price}</span><Button className='tabbuybtn' onClick={()=>navigate('/self-learning/cart')}>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
                </TabPanel>
                <TabPanel  className='tabhdr' header='Artificial Intelligence'>
                <Splide  hasTrack={ false } className=' sdbox' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 4, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
         
        > 
          <SplideTrack >

        {array.map((el)=>(
 <SplideSlide >
<div key={el.id} className='tabcrslbox w-12 p-3 flex flex-column justify-content-between' >
                       <div  className='flex h-2rem align-items-center gap-2'><img src={ranjith} style={{borderRadius:"50%",height:"100%",borderRadius:"15px"}} /><span className='tabcrslname'>{el.mentor}</span></div>
                       <div className='h-12rem pt-2'><img src={el.image} onClick={()=>navigate('self-learning/selfbuythiscourse')} style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer"}} /></div>
                       <span className='tabcrslcoursename pt-2'>{el.courseName}</span>
                       <span className='text-left flex align-items-center' style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                         <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#E0E0E0"/>
                       </svg></span>
                       <div className='flex justify-content-between align-items-center'><span className='tabprice'>{el.price}</span><Button className='tabbuybtn' onClick={()=>navigate('/self-learning/cart')}>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
                </TabPanel>
                <TabPanel className='tabhdr' header='Design'>
                <Splide  hasTrack={ false } className=' sdbox' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 4, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
         
        > 
          <SplideTrack >

        {array.map((el)=>(
 <SplideSlide >
<div key={el.id} className='tabcrslbox w-12 p-3 flex flex-column justify-content-between' >
                       <div  className='flex h-2rem align-items-center gap-2'><img src={ranjith} style={{borderRadius:"50%",height:"100%",borderRadius:"15px"}} /><span className='tabcrslname'>{el.mentor}</span></div>
                       <div className='h-12rem pt-2'><img src={el.image} onClick={()=>navigate('self-learning/selfbuythiscourse')} style={{height:"100%",width:"100%",borderRadius:"10px",cursor:"pointer"}} /></div>
                       <span className='tabcrslcoursename pt-2'>{el.courseName}</span>
                       <span className='text-left flex align-items-center' style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                         <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#E0E0E0"/>
                       </svg></span>
                       <div className='flex justify-content-between align-items-center'><span className='tabprice'>{el.price}</span><Button className='tabbuybtn' onClick={()=>navigate('/self-learning/cart')}>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

       
   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
                </TabPanel> */}
            </TabView>

        </div>
        
      </div>
      <div className="pl-5 pr-3 mt-1 md:hidden">
      <div  className="card w-full mb-4" >
            <TabView   className=' '>
            {data&& data.Course_List? Object.entries(data.Course_List).map(([category, datam]) => (
                <TabPanel   className='tabhdr0 ' key={category}  header={category}>
                <Splide hasTrack={ false } className='-ml-4 sdbox' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 1, 
            gap: '1rem',
            arrows: true,
            pagination: true
           
         }}
        > 
          <SplideTrack>

        {datam&&datam.course_list.map((el)=>(
 <SplideSlide> 
<div key={el.id} className='tabcrslbox mt-4 w-10 m-auto p-3 flex flex-column cursor-pointer'  >
                       <div  className='flex h-2rem align-items-center gap-2 cursor-pointer mb-1'  onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} ><img src={el.instructor_photo} style={{borderRadius:"50%",height:"100%",objectFit:"cover"}} /><span onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}  className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.instructor_name}</span></div>
                       <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_photo} onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>
                       {/* <div className='h-12rem pt-2 cursor-pointer' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.course_photo} style={{height:"100%",width:"100%",borderRadius:"10px"}} /></div> */}
                       <span className=' tabcrslcoursename pt-2 cursor-pointer' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                      <span className="flex align-items-center gap-1 justify-content-start"><span className='tabcrslname'>{el.ratings} </span> <svg xmlns="http://www.w3.org/2000/svg" className=' cursor-pointer' width="55" height="11" viewBox="0 0 55 11" fill="none" onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}>
                         <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={el.ratings>=1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={el.ratings>=2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={el.ratings>=3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={el.ratings>=4?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={el.ratings>=5?"#0E8E60":"#E0E0E0"}/>
                       </svg><span className='offlinecllgrating text-left flex align-items-center' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{height:"15px"}}><span className='tabcrslname'> ({el.total_people_rated})</span></span></span>
                       <div className='flex justify-content-between cursor-pointer align-items-center'><span className='tabprice ' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-2 -ml-3"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-2 -mr-3"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>

                

                </TabPanel>
              )
              ) :""}
               
                {/*  */}
            </TabView>
            {/* <h5 className='w-full text-right categoriesSeeMorebtn pr-4'>see more</h5> */}

        </div>
      </div>
      <div
        className="dots"
        style={{
          display: isSmallScreen ? "none" : "flex",
          gap: "2%",
          margin: "4% 0 3% 7% ",
        }}
      >
        <img src={dot} alt="..." style={{ width: "10px", height: "10px" }} />
        <img src={dot} alt="..." style={{ width: "9px", height: "9px" }} />
        <img src={dot} alt="..." style={{ width: "8px", height: "8px" }} />
        <img src={dot} alt="..." style={{ width: "7px", height: "7px" }} />
        <img src={dot} alt="..." style={{ width: "6px", height: "6px" }} />
        <img src={dot} alt="..." style={{ width: "5px", height: "5px" }} />
        <img src={dot} alt="..." style={{ width: "4px", height: "4px" }} />
        <img src={dot} alt="..." style={{ width: "2px", height: "2px" }} />
        <img src={dot} alt="..." style={{ width: "1px", height: "1px" }} />
      </div>
      <Testimonial />

      <div
        className="dots2"
        style={{
          display: isSmallScreen ? "none" : "flex",
          gap: "10%",
          margin: "-1% 0 3% 80% ",
        }}
      >
        <img src={dot} alt="..." style={{ width: "10px", height: "10px" }} />
        <img src={dot} alt="..." style={{ width: "9px", height: "9px" }} />
        <img src={dot} alt="..." style={{ width: "8px", height: "8px" }} />
        <img src={dot} alt="..." style={{ width: "7px", height: "7px" }} />
        <img src={dot} alt="..." style={{ width: "6px", height: "6px" }} />
        <img src={dot} alt="..." style={{ width: "5px", height: "5px" }} />
        <img src={dot} alt="..." style={{ width: "4px", height: "4px" }} />
        <img src={dot} alt="..." style={{ width: "2px", height: "2px" }} />
        {/* <img src={dot} alt="..." style={{ width: "1px", height: "1px" }} /> */}
      </div>
      <FaqsComponent />
      </>}
      <Foot />
    </>
  );
};

export default SelfLearningPage;
