import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "./Signup.css"
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { OnlineSignupfunction, SignupReset } from "../../Redux/SelfLearning/SelfAction/Auth.action";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
const initial={
    first_Name: "",
    last_Name: "",
    phone_number: "",
    email_id: "",
    password: "",
    course_id: "BB-IT-SWTS-AA25",
    gender: "",
    age: "",
    confirm_password:"",
    address:"",
    batch:""
 

}
export default function Signup({background,flag,SetFlag,batch }) {
    const [loginData,setLoginData]=useState(initial)
    const [visible, setVisible] = useState(false);
    const [check,setCheck]=useState(false)
    const toast = useRef(null);
    const dispatch=useDispatch()
    const {message,token,loading}=useSelector(store=>store.auth)
const navigate=useNavigate()
const [value2, setValue2] = useState()
const handleChange2=async(e)=>{
  if(e){
    console.log(e);
  
  //  setLoginData({...loginData,["phone_number"]:x})
  setLoginData({...loginData,["phone_number"]:e})

  }
 }
      const handleChange=(e)=>{
        setCheck(false)
        const {name,value}=e.target 
        setLoginData({...loginData,[name]:value})
        }

        const cities1 = [
            { name: "Male", code: "Male" },
            { name: "Female", code: "Female" },
          ];
          const batch_list = batch || [];
          const showError = (message) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: message,
              life: 3000,
            });
          };
        
          const showSuccess = (message) => {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: message,
              life: 1000,
            });
          };
  const handleSubmit=(e)=>{
e.preventDefault()
setCheck(true)
console.log(loginData);
if(loginData.first_Name != "" && loginData.last_Name != "" && loginData.phone_number != "" && loginData.batch != "" && loginData.email_id != "" && loginData.password != "" && loginData.course_id != "" && loginData.gender != "" && loginData.age != "" && loginData.confirm_password != "" && loginData.password===loginData.confirm_password  && /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i.test(loginData.email_id)) dispatch(OnlineSignupfunction(loginData,"OP"))
// else dispatch(Signupfunction(loginData))  

}
useEffect(()=>{
if(message=="email_id already registered"){
     showError("email_id already registered")
     dispatch(SignupReset())
    }
else if( message=="Something went wrong! Please try after Sometime"){
    showError("Something went wrong! Please try after Sometime")
    dispatch(SignupReset())
}
else if(message){ 
    showSuccess(message)
    dispatch(SignupReset())
    navigate("/office-classes/checkout")
}
},[message])
        useEffect(()=>{
           if(flag === true) setVisible(true)
        },[flag])


      const hidefunction=()=>{
        setVisible(false)
        SetFlag(false)
      }
    return (
        <> 
           <div className="card flex justify-content-center">
              {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
              {/* <Button className={`${background==='green'?'signupbtn' : 'signupbtn2'}  pt-2`}  onClick={() => setVisible(true)} >Sign up</Button>  */}
              <Toast ref={toast} />

              <Dialog header="Sign up" className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0  w-full md:w-4 md:max-w-full" visible={visible} onHide={hidefunction} maximizable  breakpoints={{ '960px': '75vw', '638px': '100vw' }}>
              <span className="mt-2  dgdesc" >We are looking for amazing people just like you! Become a part of our community and grow towards the future.</span>
   <form className="px-2" onSubmit={handleSubmit}>
      <div className="flex justify-content-between align-items-center gap-2">
      <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">First Name <span className="lblimp">*</span></label>
      <InputText id="username" name="first_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
     <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.first_Name==""?"First Name is missing":"":""}</span> 
          </div>
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Last Name  <span className="lblimp">*</span></label>
      <InputText id="username" name="last_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.last_Name==""?"Last Name is missing":"":""}</span> 
          </div>
      </div>
      <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Email ID  <span className="lblimp">*</span></label>
      <InputText  id="username" name="email_id" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.email_id==""?"Email Id is missing":/^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i.test(loginData.email_id)?"":"Please enter valid email address":""}</span> 
          </div>
          <div className="flex justify-content-between align-items-center gap-2">
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Phone Number  <span className="lblimp">*</span></label>
      <PhoneInput className="h-3rem border-round" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />
      {/* <InputText id="username" type="number" name="phone_number" onChange={handleChange} aria-describedby="username-help" className=" border-round"/> */}
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.phone_number==""?"Phone Number is missing":"":""}</span> 
          </div>
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Batch <span className="lblimp">*</span></label>
      <Dropdown  name='batch' value={loginData.batch} onChange={handleChange}  options={batch_list} optionLabel="name" placeholder=""  className="w-full h-3rem  border-round" />
          </div>
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Address  <span className="lblimp">*</span></label>
      <InputText id="username" name="address" onChange={handleChange} aria-describedby="username-help"  className=" border-round"/>
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.address==""?"Address is missing":"":""}</span> 
          </div>
          <div className="flex justify-content-between align-items-center gap-2">
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Gender  <span className="lblimp">*</span></label>
      <Dropdown  id="username" name='gender' value={loginData.gender} onChange={handleChange}  options={cities1} optionLabel="name"  className="w-full  border-round" />
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.gender==""?"Gender is missing":"":""}</span> 
          </div>
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Age  <span className="lblimp">*</span></label>
      <InputText id="username" type="number" name="age" onChange={handleChange} aria-describedby="username-help"  className=" border-round"/>
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.age==""?"Age is missing":"":""}</span> 
          </div>
      </div>
          
          
          <div className="flex justify-content-between align-items-center gap-2">
      <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Password <span className="lblimp">*</span></label>
      <InputText id="username" type="password" name="password" onChange={handleChange} className=" border-round" aria-describedby="username-help" />
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.password==""?"Password is missing":"":""}</span> 

          </div>
          <div className=" w-6 flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Confirm Password  <span className="lblimp">*</span></label>
      <InputText id="username" type="password" name="confirm_password" onChange={handleChange}  aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.confirm_password==""?"Password is missing":loginData.password != loginData.confirm_password?"password should match":"":""}</span> 
          </div>
      </div>
          {/* <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Course  <span className="lblimp">*</span></label>
      <Dropdown  name='course' value={loginData.course} onChange={handleChange}  options={yr} optionLabel="name" placeholder="Choose your course"  className="w-full h-3rem  border-round" />
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Select Batch <span className="lblimp">*</span></label>
      <Dropdown  name='batch_date' value={loginData.batch_date} onChange={handleChange}  options={yr1} optionLabel="name" placeholder="Select Batch"  className="w-full h-3rem  border-round" />
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3">Batch Time  <span className="lblimp">*</span></label>
      <Dropdown  name='batch_time' value={loginData.batch_time} onChange={handleChange}  options={yr2} optionLabel="name" placeholder="Select Batch Time"  className="w-full h-3rem  border-round" />
          </div> */}
                        {loading? <Button className="dgsignupbtn w-full mt-4" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} />:
                           <Button label="Sign up"  type="submit" className="dgsignupbtn w-full mt-5"/>}

          {/* <Button className="dgsignupbtn w-full mt-4" type="submit">{loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Sign up"}</Button> */}
   </form> 
              </Dialog>
          </div>
        </>
    )
}
        

        