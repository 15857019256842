import React, { useEffect, useState } from "react";

import Accordians from "../Accordian";

const FaqsComponent = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1030);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1030);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div
        style={{
          display: isSmallScreen ? "" : "flex",
          justifyContent: "center",
          paddingTop: isSmallScreen ? "15%":"0%",
          margin: "2% 0% 2% 8%",
        }}
      >
        <div
          style={{
            width: isSmallScreen ? "90%" : "42%",
            marginTop: isSmallScreen ? "10%" : "5%",
            alignItems: "center",
          }}
        >
          <h1
            className="text-left"
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "16px" : "36px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "135%" /* 48.6px */,
              letterSpacing: "0.72px",
            }}
          >
            What is{" "}
            <span
              style={{
                color: "#0E8E60",
              }}
            >
              BrainyBits?
            </span>
          </h1>
          <p
            style={{
              width: isSmallScreen ? "" : "401px",
              color: "#000",
              textAlign: "start",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "12px" : "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "167.5%" /* 37.5px */,
            }}
          >
            BrainyBits is a cutting-edge e-learning platform designed to bring
            the classroom experience right to your fingertips. With our self
            learning online classes, you can access expert-led, learning from
            the comfort of your home or anywhere with an internet connection.
          </p>
        </div>
        <div  style={{ width: isSmallScreen ? "" : "58%", textAlign: "center",            marginTop: isSmallScreen ? "10%" : "5%", }}>
          <h1 className={` mb-0`}
            style={{
              textAlign:"left",
              color: "#0E8E60",
              fontVariantNumeric: "lining-nums proportional-nums",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "16px" : "36px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight:  isSmallScreen ? "30px" :"63.984px" /* 177.733% */,
            }}
          >
            <span
              style={{
                color: "#000",
                fontVariantNumeric: "lining-nums proportional-nums",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "16px" : "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight:  isSmallScreen ? "30px" :"63.984px" /* 177.733% */,
              }}
            >
              Frequently
            </span>{" "}
            Asked Questions
          </h1>
          <p className="mt-0 mb-2"
            style={{
              color: "black",

              textAlign: "left",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "11px" : "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "150%",
            }}
          >
            These questions were asked frequently by students.
          </p>
          <Accordians />
        </div>
      </div>
    </>
  );
};

export default FaqsComponent;
