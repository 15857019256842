import React, { useEffect, useState } from "react";
import "./SelfInstructor.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { instructorEnquiry } from "../../Api";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";

const initial={
    first_Name:"",last_Name:"",phone_number:"",resume:"",category:"",email:"",qualification:"",experience:""
}

export default function OnlineInstructor() {
const [loginData,setLoginData]=useState(initial)
const [check,setCheck]=useState(false)
const [value2, setValue2] = useState()
const [visible2, setVisible2] = useState(false);
const [message,setMessage]=useState("")
const [loader,setLoader]=useState(false)
const navigate=useNavigate()
const cities= [
    { name: "Design", code: "DE" },
    { name: "Data Science & AI", code: "DA" },
    { name: "Marketing", code: "MK" },
    { name: "IT Certifications", code: "IT" },
    { name: "Personal Developmen", code: "PD" },
    { name: "Business", code: "BS" },
    { name: "Photography", code: "PH" },
    { name: "Music", code: "MU" },
  ];
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
const handleChange2=async(e)=>{
  if(e){
    console.log(e);
  
  //  setLoginData({...loginData,["phone_number"]:x})
  setLoginData({...loginData,["phone_number"]:e})

  }
 }
const handleChange=async(e)=>{
        setCheck(false)
        const {name,value}=e.target 
        if(name=="resume"){
            console.log(value);
          let x= await fileToBase64(e.target.files[0])
            setLoginData({...loginData,["resume"]:x})
        }
        else{
            setLoginData({...loginData,[name]:value})
        }
       
 }

const handleSubmit=async(e)=>{
e.preventDefault()
setCheck(true)
if(loginData.first_Name !="" && loginData.last_Name !="" && loginData.phone_number !="" && loginData.resume !="" && loginData.category !="" && loginData.email !="" && loginData.qualification !="" && loginData.experience !=""){
console.log(loginData);
setLoader(true)
try{
    const payload={
    "full_name": `${loginData.first_Name} ${loginData.last_Name}`,
    "mobile": loginData.phone_number,
    "email_id": loginData.email,
    "certification":"none",
    "teaching_experience": loginData.experience,
    "qualification":loginData.qualification,
    "category": loginData.category.name,
    "course_type": "Online Live Classes",
    "resume_file": loginData.resume
    }
    console.log(payload);
let res=await axios.post(instructorEnquiry,payload)
console.log(res.data);
setLoader(false)
if(res.data.key=="We will contact you if your qualities match with our required needs"){
  setMessage(res.data.key)
  setVisible2(true)
}
else{
  setMessage("Something went wrong, Please try after sometime!")
  setVisible2(true)
}
}
catch(e){
  setMessage("Something went wrong, Please try after sometime!")
  setVisible2(true)
  setLoader(false) 
}
}
}

  return (
    <>
       <Navbar background={"white"} />
       <br/>
      
      <div className=" hidden md:flex lg:flex   w-full  pt-6 " style={{height:"100vh"}}>
      <section className="w-7  flex  justify-content-center">
        <div className=" w-10 flex flex-column align-items-center">
          <form onSubmit={handleSubmit} className="flex flex-column align-items-start w-9 ">
            <h1 className="text-4xl font-bold align-self-start " style={{color:"#0E8E60"}}>
              Instructor Enquiry
            </h1>
            <hr className=" w-full my-4" style={{color:"#0E8E60"}}/>
            <section className="flex gap-4 w-full" >
              <section className="w-6">
              <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">First Name <span className="lblimp">*</span></label>
      <InputText id="username" name="first_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
     <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.first_Name==""?"First Name is missing":"":""}</span> 
          </div>
                
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Phone Number  <span className="lblimp">*</span></label>
      <PhoneInput className="h-3rem border-round" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.phone_number==""?"Phone Number is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Qualification<span className="lblimp">*</span></label>
      <InputText id="username" name="qualification" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.qualification==""?"qualification is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Category <span className="lblimp">*</span></label>
       <Dropdown name="category" value={loginData.category} onChange={handleChange}  options={cities}  optionLabel="name"   placeholder="Select" className="w-full  border-round" />
    {/* //   <Dropdown  id="username" name='gender' value={loginData.gender} onChange={handleChange}  options={cities1} optionLabel="name"  className="w-full  border-round" /> */}
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.category==""?"category is missing":"":""}</span> 
          </div>
              
              </section>
              <section className="w-6">
              <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Last Name  <span className="lblimp">*</span></label>
      <InputText id="username" name="last_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.last_Name==""?"Last Name is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Email <span className="lblimp">*</span></label>
      <InputText type="email" pattern="/^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i" id="username" name="email" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.email==""?"Email is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Experience<span className="lblimp">*</span></label>
      <InputText id="username" name="experience" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.experience==""?"experience is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Resume <span className="lblimp">*</span></label>
      <span className="p-input-icon-right">
               <i className="pi pi-upload " />
                <InputText type="file" name="resume" onChange={handleChange} id="resume" accept=".pdf" className=" border-round"/>
            </span>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.resume==""?"resume is missing":"":""}</span> 
          </div>

               
              </section>
            </section>
            {loader? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg"/>: 
               <Button className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg" type="submit">Submit</Button>}
{/* <Button className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg" type="submit">Submit</Button> */}
           
          </form>
        </div>
      </section>
      <section className="w-5   flex  justify-content-center pt-7" style={{backgroundColor:"#fff"}} >
        <div className=" gap-4 w-8 flex flex-column align-items-center ">
          <h1 className="inshead" style={{color:"#0E8E60"}}>Teach.</h1>
          <h2 className="inshead" style={{color:"#0E8E60"}}>Create.</h2>
          <h3 className="inshead" style={{color:"#0E8E60"}}>Earn.</h3>
        </div>
      </section>
    </div>


    <div className=" md:hidden   w-full  " >
    <section className="w-full   flex  justify-content-center" style={{backgroundColor:"#fff"}} >
        <div className=" gap-2 w-8 flex flex-column align-items-center ">
          <h1 className="inshead0" style={{color:"#0E8E60"}}>Teach.</h1>
          <h2 className="inshead0" style={{color:"#0E8E60"}}>Create.</h2>
          <h3 className="inshead0" style={{color:"#0E8E60"}}>Earn.</h3>
        </div>
      </section>
      <section className="w-full  flex align-items-center justify-content-center">
        <div className="w-full flex flex-column align-items-center">
          <form onSubmit={handleSubmit} className="flex flex-column align-items-start w-full px-4 my-6">
            <h1 className="text-4xl font-bold align-self-start" style={{color:"#0E8E60"}}>
              Instructor Enquiry
            </h1>
            <hr className=" w-full my-4" style={{color:"#0E8E60"}}/>
            <section className=" w-full" >
              <section className="w-full">
              <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">First Name <span className="lblimp">*</span></label>
      <InputText id="username" name="first_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
     <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.first_Name==""?"First Name is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Last Name  <span className="lblimp">*</span></label>
      <InputText id="username" name="last_Name" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.last_Name==""?"Last Name is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Email <span className="lblimp">*</span></label>
      <InputText type="email" pattern="/^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i" id="username" name="email" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.email==""?"Email is missing":"":""}</span> 
          </div>  
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Phone Number  <span className="lblimp">*</span></label>
      <PhoneInput className="h-3rem border-round" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.phone_number==""?"Phone Number is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Qualification<span className="lblimp">*</span></label>
      <InputText id="username" name="qualification" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.qualification==""?"qualification is missing":"":""}</span> 
          </div>
        
         
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Experience<span className="lblimp">*</span></label>
      <InputText id="username" name="experience" onChange={handleChange} aria-describedby="username-help" className=" border-round"/>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.experience==""?"experience is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Category <span className="lblimp">*</span></label>
       <Dropdown name="category" value={loginData.category} onChange={handleChange}  options={cities}  optionLabel="name"   placeholder="Select" className="w-full  border-round" />
    {/* //   <Dropdown  id="username" name='gender' value={loginData.gender} onChange={handleChange}  options={cities1} optionLabel="name"  className="w-full  border-round" /> */}
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.category==""?"category is missing":"":""}</span> 
          </div>
          <div className=" w-full flex flex-column gap-2">
      <label htmlFor="" className="lbl mt-3 text-left">Resume <span className="lblimp">*</span></label>
      <span className="p-input-icon-right">
               <i className="pi pi-upload " />
                <InputText type="file" name="resume" id="resume" onChange={handleChange} accept=".pdf" className=" border-round"/>
            </span>
      <span className="-mt-2 text-xs text-left" style={{color:"red"}}>{check==true?loginData.resume==""?"resume is missing":"":""}</span> 
          </div>
              </section>
            
            </section>
            {loader? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg"/>: 
               <Button className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg" type="submit">Submit</Button>}
{/* <Button className="dgsignupbtn w-6rem font-bold align-self-center mt-5 border-round-lg" type="submit">Submit</Button> */}
           
          </form>
        </div>
      </section>
     
    </div>

    <Dialog header=""  className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0   md:w-3 w-full " visible={visible2} onHide={()=>{setVisible2(false);setMessage("")}}  >
       <style>
    {`
      .p-dialog .p-dialog-header {
        display: none !important;
      }
      .p-dialog .p-dialog-content:last-of-type {
        border-Top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    `}
  </style>
              <h3 className="my-4  m-auto text-center " >{message}</h3>
              <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => {setVisible2(false);setMessage(""); navigate("/online-classes")}} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
              </Dialog>

      <Footer/>
    </>
  );
}
