import "./navbar.css";
import { Button } from "primereact/button";
import WebsiteLogo from "../../../asserts/WebsiteLogo.png";
import bbcolor from "../../../asserts/bbColor.png";
import { Sidebar } from "primereact/sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Signup from "../SelfSignup/Signup";
import Profilesmall from "../Profilesmall";
import Profile from "../Profile";
import { TieredMenu } from 'primereact/tieredmenu';
import SignIn from "../SelfSignin/Signin";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../Redux/store";
import { LogOutfuntion, SignupReset } from "../../../Redux/SelfLearning/SelfAction/Auth.action";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";

function Navbar({ background }) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 886);
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 876);
  };
const {isAuth,message} =useSelector(store=>store.auth)
  useEffect(() => {
    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [visibleRight, setVisibleRight] = useState(false);
  const navigate=useNavigate()
  const menu = useRef(null);
  const menu2=useRef(null)
const dispatch=useDispatch()
  const items = [
    {
      label: 'Self Learning',
      icon: 'pi pi-book',
      command: () => {navigate("/self-learning") }
  },
  {
      separator: true
  },
  {
    label: 'Online Classes',
    icon: 'pi pi-desktop',
    command: () => { dispatch(LogOutfuntion());navigate("/online-classes") }
},
{
    separator: true
},
{
  label: 'Office Programs',
  icon: 'pi pi-users',
  command: () => {  dispatch(LogOutfuntion());navigate("/office-classes") }
}
  ]
  const items2 = [
    {
      label: 'About Us',
      icon: 'pi pi-book',
      command: () => {navigate("/self-learning/about-us") }
  },
  {
      separator: true
  },
  {
    label: 'Contact Us',
    icon: 'pi pi-desktop',
    command: () => { navigate("/self-learning/contact-us") }
},
{
    separator: true
},
{
  label: 'Became an Instructor',
  icon: 'pi pi-users',
  command: () => { navigate("/self-learning/instructor-registration") }
}
  ]


  // console.log(message)
  return (
    <>
     <nav 
      className={`w-full p-4 md:px-8 md:py-6 h-4rem md:h-4rem flex align-items-center  justify-content-between  ${
        background === "green" ? "navdiv" : "navdiv2"
      }`}
    >
        <Menu model={items} popup ref={menu} className="mt-2 lftmnu smalllftmenu"  />
        <Menu model={items2} popup ref={menu2} className="mt-2 lftmnu smalllftmenu" style={{ width: '14rem' }}  />

        {background === "green" ? (
          <img src={WebsiteLogo}  className="h-3rem cursor-pointer" alt="logo"  onClick={()=>navigate("/self-learning")}/>
        ) : (
          <img src={bbcolor} className="h-2rem cursor-pointer" alt="logo"  onClick={()=>navigate("/self-learning")}/>
        )}
  <ul className="list-none hidden w-8  ml-8 pl-8 -mr-8 md:flex justify-content-around text-white" >
      <span className="md:flex gap-2 justify-content-center align-items-center text-white" onClick={(e) => menu.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline cursor-pointer`} >Self Learning</li><i className={`pi pi-angle-down`} style={{ color: `${background=="green"?'white':'green'}`,cursor:"pointer" }}></i>
</span>
        <Link
          to="/self-learning/courses"
          className="no-underline text-white"
        >
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Courses
          </li>
        </Link>
       
        {/* <Link to="/self-learning/about-us" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            About Us
          </li>
        </Link>

        <Link to="/self-learning/contact-us" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Contact Us
          </li>
        </Link> */}
        <Link
          to="/self-learning/cart"
          className="no-underline text-white "
        >
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Cart
          </li>
        </Link>
        <Link to="/self-learning/blog" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Blog
          </li>
        </Link>
        <span className="md:flex gap-2 justify-content-center align-items-center text-white" onClick={(e) => menu2.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline cursor-pointer`} >Connect</li><i className={`pi pi-angle-down`} style={{ color: `${background=="green"?'white':'green'}`,cursor:"pointer" }}></i>
</span>
      </ul>
      <div className="ml-5" style={{ display: isSmallScreen ? "none" : "", }} >
        {isAuth? <Profile background={background}/>:<SignIn background={background}/>}
       
      </div>

      {/* <Button className={`${background==='green'?'signupbtn' : 'signupbtn2'} hidden md:block pt-2`} >Sign up</Button>  */}
      {/* <Signup background={background} /> */}

      <Button
        icon="pi pi-bars"
        rounded
        text
        severity="success"
        aria-label="Cancel"
        className={`md:hidden flex ${
          background === "green" ? "text-white" : ""
        }  text-2xl`}
        onClick={(e) => {
          setVisibleRight(true);
        }}
      />
      <Sidebar
        visible={visibleRight}
        closeIcon={<i className="pi pi-times" style={{ color: "white" }}></i>}
        position="right"
        className="sidebarofmobile w-6 navdiv"
        onHide={() => setVisibleRight(false)}
      >
        <ul className="list-none flex flex-column gap-1 w-full h-12rem justify-content-between p-0 pt-3  text-white">
        <span  className="flex gap-2 align-items-center text-white" onClick={(e) => menu.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-sm text-white no-underline cursor-pointer`} >Self Learning</li><i className={`pi pi-angle-down`} style={{ color: "white",cursor:"pointer" }}></i></span>
        {/* <span className="flex gap-2  align-items-center text-white" onClick={(e) => menu2.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-sm text-white no-underline cursor-pointer`} >Connect</li><i className={`pi pi-angle-down`} style={{ color: 'white',cursor:"pointer" }}></i>
</span> */}
          <Link
            to="/self-learning/courses"
            className="no-underline  text-white"
          >
            <li className="headers text-sm">Courses</li>
          </Link>
          <Link
            to="/self-learning/about-us"
            className="no-underline  text-white"
          >
            <li className="headers text-sm">About Us</li>
          </Link>
          <Link
            to="/self-learning/contact-us"
            className="no-underline text-white"
          >
            <li className="headers text-sm">Contact Us</li>
          </Link>
          <Link
            to="/self-learning/cart"
            className="no-underline text-white "
          >
            <li className="headers text-sm">Cart</li>
          </Link>
          <Link to="/self-learning/blog " className="no-underline  text-white">
            <li className="headers text-sm">Blog</li>
          </Link>
          <Link
            to="/self-learning/instructor-registration"
            className="no-underline text-white"
          >
            <li className="headers text-sm">Become an Instru...</li>
          </Link>
        </ul>
        {/* <Profilesmall /> */}
        {isAuth? <Profilesmall />:<SignIn background={"green"}/>}

        {/* <Button
          className="signupbtn "
          style={{
            marginLeft: "-12px",
          }}
        >
          Sign up
        </Button> */}
      </Sidebar>
    </nav>

   
    </>
   
  );
}

export default Navbar;
