import React, { useEffect, useState } from "react";
import Navbar from "../SelfNavbar";
import "./AllBlogs.css";
import { Image } from "primereact/image";
import heart from "../../../asserts/HeartIcon.png";
import eye from "../../../asserts/EyeIcon.png";
import message from "../../../asserts/MessageIcon.png";
import { useNavigate, useParams } from "react-router-dom";
// import { AllBlog_Api, environment_for_Api, fetchBlog_Api } from '../apis'
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Footer from "../Foot";
import { useDispatch, useSelector } from "react-redux";
import { FetchBlogByCategory, FetchBlogHome } from "../../../Redux/SelfLearning/SelfAction/Blog.action";
import brain from "../../../brain.gif"
export default function SelfAllBlogs() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  console.log(params);
  const dispatch=useDispatch()
  const {homeData}=useSelector(store=>store.blog)
  console.log(homeData);
  useEffect(()=>{
    if(params.category){
     dispatch(FetchBlogByCategory(params.category))
    }
    else{
dispatch(FetchBlogHome())
    }
  },[params])
  useEffect(()=>{
    if(homeData.length!=0 ){
      let x=homeData&&homeData.list_category_blogs
      console.log(x,"x");
      if(x && !params.category){
        setData(x)
        setTotalRecords(x.length)
      }
      else{
        const allData = [];
         
        // Loop through each category and add its data to the allData array 
        for (const category in homeData.list_category_blogs[0]) {
          console.log(category);
          if(category==params.category){
            allData.push(...homeData.list_category_blogs[0][category]);
          }
        }       
        setData(allData)
        setTotalRecords(allData.length)  
     }
    
    }
  },[homeData])


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const template3 = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",

    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const formatTime = (dateString) => {
    const currentDate = new Date();
    const targetDate = new Date(dateString);
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - targetDate;
  
    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
    if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes}m ago`;
    } else {
      return `Just now`;
    }
  };
  const navigate = useNavigate();
  
useEffect(() => {
  window.scrollTo(0, 0)
}, [])
const Replaceimg=(item)=>{
  const filteredContent = item.content.replace(/<img[^>]*>/g, '');
  return filteredContent
}
const extractFirst50Characters1 = (htmlContent) => {
  // Create a temporary div element
  const tempDiv = document.createElement('div');

  // Set the innerHTML of the div with your HTML content
  tempDiv.innerHTML = htmlContent;
  // tempDiv.dangerouslySetInnerHTML={ __html: htmlContent }
  // Extract the text content and slice the first 50 characters
  const extractedText = tempDiv.textContent.slice(0, 90);

  // Return the extracted text
  return extractedText;
};
const extractFirst50Characters = (htmlContent) => {
  // Create a temporary div element
  const tempDiv = document.createElement('div');

  // Set the innerHTML of the div with your HTML content
  tempDiv.innerHTML = htmlContent;
  // tempDiv.dangerouslySetInnerHTML={ __html: htmlContent }
  // Extract the text content and slice the first 50 characters
  const extractedText = tempDiv.textContent.slice(0, 110);

  // Return the extracted text
  return extractedText;
};
  return (
    <div className="h-full w-full flex-column overflow-x-hidden">
      <Navbar />
      {data.length==0 ?<div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
      <div className="w-full hidden md:flex flex-column h-full overflow-y-scroll gap-5 pl-7 pr-7">
        <h1 className="bgttl pl-6 my-4 text-left">
          {params.category ? params.category : "All Blogs"}
        </h1>

        <div className="w-full flex flex-wrap   gap-4 align-items-center pl-6">
          {data.slice(first, first + rows).map((item, index) => {
            const key = Object.keys(item)[0];
            const value = item[key];

            return (
              <div
                key={index}
                style={{ borderRadius: "15px" }}
                onClick={() => navigate(`/self-learning/read-blog/${item.blog_id}`)}
                className="w-16rem h-22rem flex flex-column cursor-pointer"
              >
                <div className="w-full h-10rem border-round-2xl border-noround-bottom">
                  <img
                    src={item.title_image}
                    style={{
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      width: "100%",
                      height: "100%",objectFit:"cover"
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#0E8E60",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                  className="w-full h-12rem flex flex-column justify-content-between gap-2 pb-3 px-3 p-2"
                >
                  <div className="">
                    <h5 className="bgttl2 text-left h-1rem mb-1" >
                      {formatDate(item.datetime)} .{" "}
                      {formatTime(item.datetime)} 
                    </h5>
                    <div className="w-full  h-3rem">
                      <h1 className="bgttl3 text-left">{item.title&&item.title.length>40?`${item.title.substring(0,39)}...`:item.title } </h1>
                    </div>
                    <div className="w-full h-3rem">
                      <h3
                        className="bgttl4 text-left mt-1"
                       
                      >{extractFirst50Characters1(item&&item.content)}...</h3>
                    </div>
                  </div>
                  <div>
                    <hr className="bg-white my-2"></hr>
                    <div className="flex justify-content-between align-items-center w-full">
                      <div className="flex w-6 gap-2">
                        <div className="flex justify-content-center align-items-center gap-1">
                          <Image src={eye} width="80%" />
                          <span className="bglst ">{item.num_of_views}</span>
                        </div>
                        <div className="flex justify-content-center align-items-center gap-1">
                          {/* <Image src={heart} width="100%" /> */}
                          <i class="pi pi-heart" style={{fontSize: "1rem",color:"#fff"}}></i> 
                          <span className="bglst">{item.num_of_likes}</span>
                        </div>
                        <div className="flex justify-content-center align-items-center gap-1">
                          <Image src={message} width="80%" />
                          {/* <i class="pi pi-comment" style={{fontSize: "1rem",color:"#fff"}}></i> */}
                          <span className="bglst">{item.num_of_comments}</span>
                        </div>
                      </div>
                      {/* <div className="flex justify-content-center align-items-center gap-1">
                        <span className="bglst">{item.num_of_likes}</span>
                        <Image src={heart} width="80%" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div
            style={{ borderRadius: "15px" }}
            className="w-15rem h-22rem flex flex-column"
          >
            <div className="w-full h-10rem border-round-2xl border-noround-bottom">
              <img
                src="https://cdn.britannica.com/03/130603-050-37F7F535/Alliman-Administration-Center-Hesston-College-Mennonite-college.jpg"
                style={{
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: "#0E8E60",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
              className="w-full h-12rem flex flex-column gap-2 p-2"
            >
              <h5 className="bgttl2 text-left my-0">May 18, 2021 . 3 min</h5>
              <div className="w-full  ">
                <h1 className="bgttl3 text-left my-0">
                  Real Interpretation and Future ...
                </h1>
              </div>
              <div className="w-full">
                <h3 className="bgttl4 text-left my-0">
                  Artificial intelligence is a field of Computer science in
                  whi..
                </h3>
              </div>
              <hr className="bg-white my-2"></hr>
              <div className="flex justify-content-between align-items-center w-full">
                <div className="flex w-4 gap-2">
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={eye} width="100%" />
                    <span className="bglst">81</span>
                  </div>
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={message} width="100%" />
                    <span className="bglst">0</span>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center gap-1">
                  <span className="bglst">1</span>
                  <Image src={heart} width="80%" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex justify-content-end mt-4">
          <Paginator
            first={first}
            template={template3}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            className="p-mt-4 p-paginator p-paginator-pages p-paginator-page custom-paginator"
          ></Paginator>
        </div>
      </div>}

      {data.length==0?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:
      <div className="w-full flex md:hidden flex-column h-full overflow-y-scroll gap-5 mb-4 px-5">
        <h1 className="bgttl0 text-left my-4">
          {params.category ? params.category : "All Blogs"}
        </h1>

        <div className="w-full flex flex-wrap   gap-4 align-items-center  justify-content-center" >
        {data.slice(first, first + rows).map((item, index) => {
            const key = Object.keys(item)[0];
            const value = item[key];

            return (
              <div
              key={index}
              style={{ borderRadius: "15px" }}
              onClick={() => navigate(`/self-learning/read-blog/${item.blog_id}`)}
              className="w-17rem h-22rem flex flex-column cursor-pointer"
            >
              <div className="w-full h-10rem border-round-2xl border-noround-bottom indvlblgdabba">
                <img
                  src={item.title_image}
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",objectFit:"cover"
                   
                  }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#0E8E60",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
                className="w-full h-12rem flex flex-column justify-content-around gap-2 p-2 px-3"
              >
                <div className="flex flex-column gap-2">
                  <h5 className="bgttl2 text-left" >
                    {formatDate(item.datetime)} .{" "}
                    {formatTime(item.datetime)} 
                  </h5>
                  <div className="w-full  my-1">
                    <h1 className="bgttl3 text-left">{item.title&&item.title.length>40?`${item.title.substring(0,39)}...`:item.title }</h1>
                  </div>
                  <div className="w-full">
                    <h3
                      className="bgttl4 text-left"
                      // dangerouslySetInnerHTML={{ __html: Replaceimg(item) }}
                    >{extractFirst50Characters(item&&item.content)}...</h3>
                  </div>
                </div>
                <div>
                  <hr className="bg-white my-2"></hr>
                  <div className="flex justify-content-between align-items-center w-full">
                      <div className="flex w-6 gap-2">
                        <div className="flex justify-content-center align-items-center gap-1">
                          <Image src={eye} width="80%" />
                          <span className="bglst ">{item.num_of_views}</span>
                        </div>
                        <div className="flex justify-content-center align-items-center gap-1">
                          {/* <Image src={heart} width="100%" /> */}
                          <i class="pi pi-heart" style={{fontSize: "1rem",color:"#fff"}}></i> 
                          <span className="bglst">{item.num_of_likes}</span>
                        </div>
                        <div className="flex justify-content-center align-items-center gap-1">
                          <Image src={message} width="80%" />
                          {/* <i class="pi pi-comment" style={{fontSize: "1rem",color:"#fff"}}></i> */}
                          <span className="bglst">{item.num_of_comments}</span>
                        </div>
                      </div>
                      {/* <div className="flex justify-content-center align-items-center gap-1">
                        <span className="bglst">{item.num_of_likes}</span>
                        <Image src={heart} width="80%" />
                      </div> */}
                    </div>

                  {/* <div className="flex justify-content-between align-items-center w-full">
                    <div className="flex w-4 gap-2">
                      <div className="flex justify-content-center align-items-center gap-1">
                        <Image src={eye} width="100%" />
                        <span className="bglst">81</span>
                      </div>
                      <div className="flex justify-content-center align-items-center gap-1">
                        <Image src={message} width="100%" />
                        <span className="bglst">{item.num_of_comments}</span>
                      </div>
                    </div>
                    <div className="flex justify-content-center align-items-center gap-1">
                      <span className="bglst">{item.num_of_likes}</span>
                      <Image src={heart} width="80%" />
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
             
            );
          })}
         
          {/* <div
            style={{ borderRadius: "15px" }}
            className="w-15rem h-22rem flex flex-column"
            onClick={()=>navigate("/self-learning/read-blog")}
          >
            <div className="w-full h-10rem border-round-2xl border-noround-bottom">
              <img
                src="https://cdn.britannica.com/03/130603-050-37F7F535/Alliman-Administration-Center-Hesston-College-Mennonite-college.jpg"
                style={{
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: "#0E8E60",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
              className="w-full h-12rem flex flex-column gap-2 p-2"
            >
              <h5 className="bgttl2 text-left my-0">May 18, 2021 . 3 min</h5>
              <div className="w-full  ">
                <h1 className="bgttl3 text-left my-0">
                  Real Interpretation and Future ...
                </h1>
              </div>
              <div className="w-full">
                <h3 className="bgttl4 text-left my-0">
                  Artificial intelligence is a field of Computer science in
                  whi..
                </h3>
              </div>
              <hr className="bg-white my-2"></hr>
              <div className="flex justify-content-between align-items-center w-full">
                <div className="flex w-4 gap-2">
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={eye} width="100%" />
                    <span className="bglst">81</span>
                  </div>
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={message} width="100%" />
                    <span className="bglst">0</span>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center gap-1">
                  <span className="bglst">1</span>
                  <Image src={heart} width="80%" />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ borderRadius: "15px" }}
            className="w-15rem h-22rem flex flex-column"
            onClick={()=>navigate("/self-learning/read-blog")}
          >
            <div className="w-full h-10rem border-round-2xl border-noround-bottom">
              <img
                src="https://cdn.britannica.com/03/130603-050-37F7F535/Alliman-Administration-Center-Hesston-College-Mennonite-college.jpg"
                style={{
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: "#0E8E60",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
              className="w-full h-12rem flex flex-column gap-2 p-2"
            >
              <h5 className="bgttl2 text-left my-0">May 18, 2021 . 3 min</h5>
              <div className="w-full  ">
                <h1 className="bgttl3 text-left my-0">
                  Real Interpretation and Future ...
                </h1>
              </div>
              <div className="w-full">
                <h3 className="bgttl4 text-left my-0">
                  Artificial intelligence is a field of Computer science in
                  whi..
                </h3>
              </div>
              <hr className="bg-white my-2"></hr>
              <div className="flex justify-content-between align-items-center w-full">
                <div className="flex w-4 gap-2">
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={eye} width="100%" />
                    <span className="bglst">81</span>
                  </div>
                  <div className="flex justify-content-center align-items-center gap-1">
                    <Image src={message} width="100%" />
                    <span className="bglst">0</span>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center gap-1">
                  <span className="bglst">1</span>
                  <Image src={heart} width="80%" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex justify-content-center mt-4">
          <Paginator
            first={first}
            template={template3}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            className="p-mt-4 p-paginator p-paginator-pages p-paginator-page custom-paginator"
          ></Paginator>
        </div>
      </div>}
      <Footer />
    </div>
  );
}
