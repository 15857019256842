import "./privacypolicy.css";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";
import { useEffect } from "react";

function OnlinePrivacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="flex flex-column justify-content-between align-items-center h-full w-full overflow-y-auto">
      <Navbar />
      <div className="flex w-11 flex-column px-2 md:px-5 text-left pb-6 ">
        <h1 className="text-5xl font text_color my-4 md:my-6">
          Privacy Policy
        </h1>
        <p className="font para-lines my-1">
          At BrainyBits, we are committed to protecting your privacy. This
          privacy policy (“Policy”) describes the types of information we
          collect from you when you use our online e-learning platform,
          BrainyBits.in (“Platform”), and how we use and share that information.
        </p>
        <h4 className="font text_color para-lines font-bold">
          Information we collect
        </h4>
        <p className="font para-lines my-1">
          We collect two types of information from you when you use our
          Platform: a. Personal Information: We collect personal information
          that you voluntarily provide to us, such as your name, email address,
          phone number, and billing information. b. Usage Information: We also
          collect usage information when you use our Platform, such as your IP
          address, browser type, and operating system.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          How we use your informationh
        </h4>
        <p className="font para-lines my-1">
          We use your personal information to provide you with access to our
          Platform, process your payments, and communicate with you about our
          services. We also use your personal information to improve our
          Platform and to personalize your user experience. We use your usage
          information to monitor and analyse the use of our Platform, diagnose
          technical problems, and to administer and improve our Platform.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          How we share your information
        </h4>
        <p className="font para-lines my-1">
          We may share your personal information with third-party service
          providers that assist us with our business operations, such as payment
          processing, customer support, and data analysis. We also may share
          your personal information with third parties if required by law or to
          protect our legal rights. We do not sell or rent your personal
          information to third parties for marketing purposes.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Data Security
        </h4>
        <p className="font para-lines my-1">
          We take appropriate measures to protect your personal information from
          unauthorized access, alteration, disclosure, or destruction. We use
          industry-standard security technologies and procedures to safeguard
          your personal information. Cookies We use cookies on our Platform to
          collect usage information and to personalize your user experience. A
          cookie is a small file that is sent to your browser and stored on your
          device. You can control cookies through your browser settings.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Third-Party Websites
        </h4>
        <p className="font para-lines my-1">
          Our Platform may contain links to third-party websites that are not
          controlled by us. This Policy does not apply to third-party websites,
          and we are not responsible for the privacy practices of those
          websites. We encourage you to review the privacy policies of those
          websites.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Children’s Privacy
        </h4>
        <p className="font para-lines my-1">
          Our Platform is not intended for use by children under the age of 13.
          We do not knowingly collect personal information from children under
          the age of 13. If you are a parent or guardian and believe that your
          child has provided us with personal information, please contact us at
          the email address below.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Changes to this Policy
        </h4>
        <p className="font para-lines my-1 mb-5">
          We reserve the right to update or modify this Policy at any time,
          without prior notice. Your continued use of our Platform after any
          changes to this Policy will constitute your acceptance of such
          changes. Contact Us If you have any questions or comments about this
          Policy, please contact us at info@brainybits.in. By using our
          Platform, you acknowledge that you have read and understood this
          Policy and agree to be bound by it.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default OnlinePrivacypolicy;
