import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import React, { useEffect, useState } from 'react'
import girlintrain from "../../asserts/GirlIntrain.png"
import robotplaying from "../../asserts/robotPlaying.png"
import bulb from "../../asserts/Bulb.png"
import magnet from "../../asserts/magnetism.png"
import trading from "../../asserts/trading.png"
import './DynamicCourses.css'
import Navbar from '../navbar/navbar'
import Footer from '../Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { Paginator } from 'primereact/paginator'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { AddToCartFunction } from '../../Redux/SelfLearning/SelfAction/Cart.action'
import { OlFetchDynamicCourse } from '../../Redux/Online/OnlineAction/OnlineCourse.action'
import brain from "../../brain.gif"

  
export default function DynamicCourses() {
 
  const params = useParams();
  const [first, setFirst] = useState(0);
const [rows, setRows] = useState(5); 
const [totalRecords, setTotalRecords] = useState(0);
const dispatch=useDispatch()
const {dynamicCourses}=useSelector(store=>store.OnlineCourse)
const onPageChange = (event) => {
  setFirst(event.first);
  setRows(event.rows);
  window.scrollTo(0, 0)
};
useEffect(() => {
  window.scrollTo(0, 0)
}, [])

const CartAddfunction=(id,el)=>{
  dispatch(AddToCartFunction(id,{...el,course_type:"OL"}))
  navigate('/online-classes/cart')
}
const template3 = {
  layout:
    "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",

  CurrentPageReport: (options) => {
    return (
      <span
        style={{
          width: "120px",
          textAlign: "center",
        }}
      >
        {/* {options.first}  */}
      </span>
    );
  },
};
   console.log(params,dynamicCourses);
  const navigate=useNavigate()
  useEffect(()=>{
   dispatch(OlFetchDynamicCourse(params.course));
  },[params])
 


  const itemtemplate=(el)=>{
    return(
      <>
      <div  className='w-full hidden md:flex p-2 ml-8 mr-6 gap-5 mb-4 cursor-pointer'>
      <div className='w-3 h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_photo} onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>
      <div  className='w-9 flex justify-content-between pt-1 flex-column'>
        <h3 className='dataviewname text-left ' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>{el.course_name}</h3>
        <span className='dataviewdesc' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>{el.short_description.substring(0,255) || "We believe in empowering individuals with the knowledge and skills they need to become lifelong learners. Our self-learning website offers a diverse range of high-quality, interactive courses."}...</span>
        {/* <h4 className='dataviewmentor text-left '>{el.instructor_name}</h4> */}
        <div className='flex justify-content-between align-items-center'>
          <div className='flex flex-column ' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}> <div className='flex align-items-center gap-2'> <span className='dataviewrating' >{el.ratings}</span>  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="31" viewBox="0 0 55 11" fill="none">
          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.ratings)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.ratings)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.ratings)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.ratings)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.ratings)>4?"#0E8E60":"#E0E0E0"}/>
                       </svg></div>
      <h6 className='dataviewtime mt-1' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}> {el.num_of_videos} Chapters</h6>
      </div>
      <div className='flex flex-column -mt-6'>
      <span className='dataviewPrice' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</span>
      <Button className='dataviewbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button>
      </div>
        </div>
      
      
      </div>
      
         </div>
         <div  className='w-full mb-3 flex md:hidden  '>
         <div className='w-6 h-8rem flex justify-content-center align-items-center'  ><img src={el.course_photo} style={{width:"100%",height:"100%",borderRadius:"10px"}} alt={el.course_name}/></div>
      <div  className='w-6 flex justify-content-between  flex-column pl-2'>
        <h3 className='dataviewname0 text-left mt-0 mb-1'  onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>{el.course_name.substring(0,50) }...</h3>
        <span className='dataviewdesc0 '  onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>{el.short_description.substring(0,200) || "We believe in empowering individuals with the knowledge and skills they need to become lifelong learners. Our self-learning website offers a diverse range of high-quality, interactive courses."}...</span>
        {/* <h4 className='dataviewmentor0 text-left mt-1 mb-0 w-7' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}} onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>{el.instructor_name}</h4> */}
        <div className='flex justify-content-between align-items-center'>
          <div className='flex flex-column' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}> <div className='flex align-items-center gap-1'><span className='dataviewrating0 '>{el.ratings}</span> <svg xmlns="http://www.w3.org/2000/svg" width="55" height="11" viewBox="0 0 55 11" fill="none">
          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.ratings)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.ratings)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.ratings)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.ratings)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.ratings)>4?"#0E8E60":"#E0E0E0"}/>
                        </svg></div> 
      <h6 className='dataviewtime0 mt-1 w-full'> {el.num_of_videos} Chapters</h6></div>
      <div  className='flex flex-column gap-2 '>
      <span className='dataviewPrice0 -mt-2'onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</span>
      <Button className='dataviewbuybtn0' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button>
      </div>
        </div>
      
      
      </div>
      
         </div>
      </>   
    )
   }


  return (
    <> 
      <Navbar /> 
      {dynamicCourses.length==0?<div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
      <div className="hidden md:block pl-8 pr-8">
        <h1 className="coursePopular capitalize text-left py-2">
          {params.course} Courses{" "}
        </h1>

        <div className="card">
        <DataView value={dynamicCourses&& dynamicCourses.slice(first, first + rows)} itemTemplate={itemtemplate}   />
            <div className="flex justify-content-end align-items-center  pr-5"   >
                <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={dynamicCourses&& dynamicCourses.length}
                  onPageChange={onPageChange}
                  className="p-mt-4 w-2 p-link p-paginator p-paginator-pages p-paginator-page custom-paginator justify-self-end"
                ></Paginator>
                {/* <Dropdown
                  value={rows}
                  options={rowsPerPageOptions}
                  onChange={onRowsPerPageChange}
                  placeholder="Rows per page"
                  className="p-mr-2 pge"
                /> */}
              </div>
        </div>
      </div>}
      {dynamicCourses.length==0?<div className="flex md:hidden justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:
      <div className="block md:hidden pl-4 pr-4 ">
        <h1 className="coursePopular0 capitalize my-2  text-left ">
          {params.course} Courses{" "}
        </h1>

        <div className="card mt-4">
        <DataView value={dynamicCourses&& dynamicCourses.slice(first, first + rows)} itemTemplate={itemtemplate}  rows={rows} />

          <div className="flex justify-content-center align-items-center">
          <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={dynamicCourses&& dynamicCourses.length}
                  onPageChange={onPageChange}
                  className="p-mt-4 w-10 p-link p-paginator p-paginator-pages p-paginator-page custom-paginator justify-self-center mb-2 "
                ></Paginator>
                {/* <Dropdown
                  value={rows}
                  options={rowsPerPageOptions}
                  onChange={onRowsPerPageChange}
                  placeholder="Rows per page"
                  className="p-mr-2 pge"
                /> */}
              </div>
        </div>
      </div>}

      <Footer />
    </>
  );
}
