import "./contact.css";
import Navbar from "../SelfNavbar";
import { ProgressSpinner } from "primereact/progressspinner";

import  Girlwithheadphones  from "../../../asserts/contactWomen.png";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import twitter from "../../../asserts/Twitter2.png"
import { Image } from "primereact/image";
import Insta2 from "../../../asserts/Insta2.png"
import linkedIn from "../../../asserts/linkedIn.png"
import Footer from "../Foot";
import axios from "axios";
import { enquiryApi, environment } from "../../../Api";


function SelfContactUs() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const toast = useRef(null);
  const [loading,setLoading]=useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cancel = () => {
    toast.current.show({ severity: 'danger', summary: 'Rejected', detail: 'Something went wrong', life: 3000 });
}

  const reject = () => {
      toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Please fill all fields', life: 3000 });
  }

  const fetchRhedata = async (e) => {
    e.preventDefault();
    console.log(name,email,number,message);
if(name===""){
  reject()
}
else if(email===""){
  reject()
}
else if(number==="" ){
  reject()
}else if(message===""){
  reject()
}
 else{
  setLoading(true)
   try{
    let res=await axios.post(enquiryApi,{environment:environment,full_name: name, mobile: number, email_id: email, message: message,})
    console.log(res.data)
    setLoading(false)
  if(res.data){
    setVisible(true);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }
  else{
    cancel()
  }
   }catch(e){
    cancel()

   }


  

  }
  };

 
  return (
    <>
                    <Toast ref={toast} />

    <div className="h-full w-full flex flex-column">
    <Navbar background={"green"}/>
     
    <div className="hidden pl-8 pr-8 pt-8  pb-5 md:flex flex-column gap-5"  >
<div  className=" mb-5 mt-2 flex  pl-5 pr-4 justify-content-between align-items-start" >
   <div  className="w-9 flex gap-4  pr-6 pl-4 justify-content-between align-items-center" >
   <div className="headphoneimage  "><img src={Girlwithheadphones} style={{borderBottomLeftRadius:"70px"}}  /></div>
   <div className="w-5 flex flex-column">
   <section   className="w-full flex flex-column formcontainer text-left  mr-1 pt-4 ">
        <h1 className="text-left   contacts1 font">Contact Us</h1>
        <form
          className="flex flex-column w-full my-4"
          onSubmit={fetchRhedata}
        >
          <label
            htmlFor="fullname"
            className="contactlabel mt-2"
          >
            Full name
          </label>
          
          <input value={name} id="fullname" className="chck inputformel h-2rem mb-0 w-10" style={{border:"0px",backgroundColor:"white !important"}}                 type="text" onChange={(e) => { setName(e.target.value);}}/>
          <hr className="w-10 m-0  mt-0" />
          <label
            htmlFor="email"
            className="contactlabel mt-2"
          >
            Email
          </label>
          <input
            id="email"
            value={email}
            className="h-2rem inputformel chck w-10"
            
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{border:"0px"}}
          />
          <hr className="w-10 m-0  mt-0" />
          <label
            htmlFor="message"
            className="contactlabel mt-2"
          >
            Number
          </label>
          <input
            type="tel"                value={number}
            id="message"
            className="h-2rem inputformel chck w-10"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            style={{border:"0px"}}
            maxLength={10}
          />
          <hr className="w-10 m-0  mt-0" />
          <label
            htmlFor="message"
            className="contactlabel mt-2"
          >
            Message
          </label>
          <input
            value={message}
            id="message"
            className=" h-2rem inputformel chck w-10"
            type="text"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            style={{border:"0px"}}
          />
          <hr className="w-10 m-0 mt-0" />
          <div className="flex align-items-center gap-3">
          {loading? 
           (<> <button
            type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}}
            className=" mt-6 border-none   bg-white subbtn cursor-pointer"
          >  Submit
          </button>
<span className=" mt-6"><i className="pi pi-spin pi-spinner" style={{ fontSize: '1.3rem',color:"#0E8E60" }}></i></span>
</>)
        :  

         (<><button
            type="submit"
            className=" mt-6 border-none   bg-white subbtn cursor-pointer"
          >
            Submit
          </button>
          <span onClick={fetchRhedata} className="-ml-3 mt-6 cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 45 45" fill="none">
<path d="M22.6065 28.2897L25.4988 31.1819L34.1797 22.5031L25.5008 13.8242L22.6086 16.7185L26.3456 20.4576H10.929V24.5485H26.3456L22.6065 28.2897Z" fill="#0E8E60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.4095 38.4095C47.1968 29.6223 47.1968 15.3777 38.4095 6.59045C29.6223 -2.19682 15.3777 -2.19682 6.59045 6.59045C-2.19682 15.3777 -2.19682 29.6223 6.59045 38.4095C15.3777 47.1968 29.6223 47.1968 38.4095 38.4095ZM35.5173 35.5173C38.9697 32.0649 40.9092 27.3824 40.9092 22.5C40.9092 17.6176 38.9697 12.9351 35.5173 9.48273C32.0649 6.03033 27.3824 4.0908 22.5 4.0908C17.6176 4.0908 12.9351 6.03033 9.48273 9.48273C6.03033 12.9351 4.0908 17.6176 4.0908 22.5C4.0908 27.3824 6.03033 32.0649 9.48273 35.5173C12.9351 38.9697 17.6176 40.9092 22.5 40.9092C27.3824 40.9092 32.0649 38.9697 35.5173 35.5173Z" fill="#0E8E60"/>
</svg></span></> )}
          </div>
          {/* <span type="submit">Submit</span> */}
        </form>
      </section>
      <div className="flex mt-8  align-items-center gap-4  "  >
 
 <span className=" cursor-pointer"><Image src={Insta2} width='42' alt="twitter" className='cursor-pointer' onClick={()=>window.open('https://www.instagram.com/brainybits.india/', '_blank')}  /></span>
 
 <span className=" cursor-pointer"><Image src={twitter} width='42' alt="twitter" className='cursor-pointer' onClick={()=>window.open('https://twitter.com/BrainyBits23', '_blank')}  /></span>
 <span className=" cursor-pointer"><Image src={linkedIn} width='42' alt="linkedin" className='cursor-pointer' onClick={()=>window.open('https://www.linkedin.com/company/brainybits/', '_blank')}  /></span>
 
 <span className=' cursor-pointer' onClick={()=>window.open('https://www.youtube.com/channel/UCbLz1MOl-sfCzyPHkTMr5WA', '_blank')} ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
 <circle cx="25" cy="25" r="25" fill="#0E8E60"/>
 <path d="M36.4105 17.4925C36.2965 17.0455 35.9725 16.708 35.602 16.6045C34.945 16.42 31.7515 16 25.0015 16C18.2515 16 15.0595 16.42 14.398 16.6045C14.032 16.7065 13.708 17.044 13.5925 17.4925C13.429 18.1285 13.0015 20.7925 13.0015 25C13.0015 29.206 13.429 31.87 13.5925 32.509C13.7065 32.9545 14.0305 33.292 14.3995 33.394C15.0595 33.58 18.25 34 25 34C31.75 34 34.9435 33.58 35.6035 33.3955C35.9695 33.2935 36.2935 32.956 36.409 32.5075C36.574 31.8715 37 29.2 37 25C37 20.8 36.574 18.13 36.4105 17.4925ZM39.316 16.747C40.0015 19.42 40.0015 25 40.0015 25C40.0015 25 40.0015 30.58 39.316 33.253C38.935 34.7305 37.8205 35.893 36.409 36.286C33.8455 37 25 37 25 37C25 37 16.1605 37 13.5925 36.286C12.175 35.887 11.062 34.726 10.6855 33.253C10 30.58 10 25 10 25C10 25 10 19.42 10.6855 16.747C11.0665 15.2695 12.181 14.107 13.5925 13.714C16.162 13 25.0015 13 25.0015 13C25.0015 13 33.8455 13 36.409 13.714C37.825 14.113 38.9395 15.274 39.316 16.747ZM22.0015 30.25V19.75L31.0015 25L22.0015 30.25Z" fill="white"/>
 </svg></span>
 <span onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092299548567', '_blank')} className='cursor-pointer'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48" fill="none">
 <g clip-path="url(#clip0_4240_10489)">
 <path d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 35.9789 8.77641 45.908 20.25 47.7084V30.9375H14.1562V24H20.25V18.7125C20.25 12.6975 23.8331 9.375 29.3152 9.375C31.9402 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6613C28.68 15.75 27.75 17.6002 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7084C39.2236 45.908 48 35.9789 48 24Z" fill="#0E8E60"/>
 </g>
 <defs>
 <clipPath id="clip0_4240_10489">
   <rect width="48" height="48" fill="white"/>
 </clipPath>
 </defs>
 </svg></span>
 <span className="cursor-pointer" onClick={()=>window.open('https://wa.aisensy.com/E39QBf', '_blank')}><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
 <circle cx="25" cy="25" r="25" fill="#0E8E60"/>
 <path d="M34.2424 15.7527C33.0405 14.5448 31.609 13.5871 30.0313 12.9354C28.4535 12.2838 26.7612 11.9512 25.0529 11.9571C17.8954 11.9571 12.0619 17.7614 12.0619 24.8832C12.0619 27.1658 12.6649 29.3831 13.7923 31.3397L11.957 38.044L18.8393 36.244C20.7401 37.2744 22.8768 37.8222 25.0529 37.8222C32.2105 37.8222 38.044 32.0179 38.044 24.8962C38.044 21.4397 36.6938 18.1919 34.2424 15.7527ZM25.0529 35.631C23.1128 35.631 21.212 35.1092 19.5472 34.131L19.1539 33.8962L15.0639 34.9657L16.1519 31.0005L15.8897 30.5962C14.8118 28.8835 14.2395 26.9039 14.238 24.8832C14.238 18.9614 19.0883 14.1354 25.0398 14.1354C27.9238 14.1354 30.6374 15.2571 32.6693 17.2919C33.6754 18.2883 34.4727 19.4736 35.015 20.7789C35.5573 22.0843 35.8338 23.4837 35.8286 24.8962C35.8548 30.8179 31.0044 35.631 25.0529 35.631ZM30.9782 27.5962C30.6505 27.4397 29.0512 26.6571 28.7628 26.5397C28.4613 26.4353 28.2515 26.3832 28.0287 26.6962C27.8058 27.0223 27.1897 27.7527 27.0062 27.9614C26.8227 28.1831 26.626 28.2092 26.2983 28.0397C25.9706 27.8831 24.9219 27.531 23.6896 26.4353C22.7195 25.5745 22.0772 24.5179 21.8806 24.1919C21.697 23.8658 21.8543 23.6962 22.0248 23.5266C22.169 23.3832 22.3525 23.1484 22.5098 22.9658C22.6671 22.7832 22.7326 22.6397 22.8375 22.431C22.9424 22.2093 22.89 22.0266 22.8113 21.8701C22.7326 21.7136 22.0772 20.1223 21.815 19.4701C21.5528 18.844 21.2775 18.9223 21.0809 18.9093H20.4517C20.2288 18.9093 19.888 18.9875 19.5865 19.3136C19.2981 19.6397 18.4591 20.4223 18.4591 22.0136C18.4591 23.6049 19.6258 25.144 19.7831 25.3527C19.9404 25.5745 22.0772 28.8353 25.3282 30.231C26.1017 30.5701 26.7047 30.7657 27.1766 30.9092C27.95 31.1571 28.6579 31.1179 29.2216 31.0397C29.8508 30.9484 31.1486 30.2571 31.4108 29.5005C31.6861 28.744 31.6861 28.1049 31.5943 27.9614C31.5026 27.8179 31.3059 27.7527 30.9782 27.5962Z" fill="white"/>
 </svg></span>
 
   </div>
      </div>
   </div>
      <div    className="w-3 mt-4 flex flex-column gap-4    justify-content-between align-items-start" >
      <div className=" flex flex-column">
    <h3 className="cntctlbl2 text-left mb-2">Based in</h3>
    <h3 className="cntctlbl3 text-left mt-1">Plot No 65, Nishikethan Apartments,<br/>

6th Floor, near Parvath Nagar,<br/>

Survey of India Colony, Madhapur,<br/>

Hyderabad, Telangana, India 500081 <br/></h3>
  </div>
  <div  className=" flex flex-column " >
    <h3 className="cntctlbl2 text-left mb-1">Contact</h3>
    <h3 className="cntctlbl3 text-left mt-1">info@lstms.com</h3>
    <h3 className="cntctlbl3 text-left mt-1">63030 75924</h3>

  </div>

 <div className="w-full">
 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.0983936417842!2d78.39343117462839!3d17.455004100854826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91448fe3527d%3A0x6488e0c69de7acca!2sBrainyBits!5e0!3m2!1sen!2sin!4v1707887894988!5m2!1sen!2sin"
  width="100%" height="200"
   style={{border:"0"}}
    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 </div>

</div>
</div>

</div>

<div className="flex pl-4 pr-4 pt-4  pb-5 md:hidden flex-column gap-5"  >
    <div   className=" mb-5 mt-2 flex flex-column   justify-content-between align-items-start" >
       <div className="w-full flex flex-column   justify-content-between align-items-center">
       <div className="headphoneimage  w-full"><img src={Girlwithheadphones} style={{borderBottomLeftRadius:"100px"}}  /></div>
       <section   className="w-full mt-5 flex flex-column formcontainer text-left ">
            <h1 className="text-left ml-4  contacts1 font">Contact Us</h1>
            <form
              className="flex flex-column   w-full pl-5 my-4"
              onSubmit={fetchRhedata}
            >
              <label
                htmlFor="fullname"
                className="contactlabel mt-2"
              >
                Full name
              </label>
              <input value={name} id="fullname" className="chck h-2rem mb-0 w-10" style={{border:"0px"}} type="text" onChange={(e) => { setName(e.target.value);}}/>
              <hr className="w-10 m-0  mt-0" />
              <label
                htmlFor="email"
                className="contactlabel mt-2"
              >
                Email
              </label>
              <input
                id="email"
                value={email}
                className="h-2rem chck w-10"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{border:"0px"}}
              />
              <hr className="w-10 m-0  mt-0" />
              <label
                htmlFor="message"
                className="contactlabel mt-2"
              >
                Number
              </label>
              <input
                type="tel"                value={number}
                id="message"
                className="h-2rem chck w-10"
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
                style={{border:"0px"}}
                maxLength={10}

              />
              <hr className="w-10 m-0  mt-0" />
              <label
                htmlFor="message"
                className="contactlabel mt-2"
              >
                Message
              </label>
              <input
                value={message}
                id="message"
                className=" h-2rem chck w-10"
                type="text"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                style={{border:"0px"}}
              />
              <hr className="w-10 m-0 mt-0" />
              <div className="flex align-items-center gap-3">
              {loading? 
           (<> <button
            type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}}
            className="h-3rem my-3 border-none   bg-white subbtn cursor-pointer"
          >  Submit
          </button>
<span className=" mt-1"><i className="pi pi-spin pi-spinner" style={{ fontSize: '1.3rem',color:"#0E8E60" }}></i></span>
</>)
        :  

         (<><button
            type="submit"
            className="h-3rem my-3 border-none   bg-white subbtn cursor-pointer"
          >
            Submit
          </button>
          <span onClick={fetchRhedata} className="-ml-3 mt-1 cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 45 45" fill="none">
<path d="M22.6065 28.2897L25.4988 31.1819L34.1797 22.5031L25.5008 13.8242L22.6086 16.7185L26.3456 20.4576H10.929V24.5485H26.3456L22.6065 28.2897Z" fill="#0E8E60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.4095 38.4095C47.1968 29.6223 47.1968 15.3777 38.4095 6.59045C29.6223 -2.19682 15.3777 -2.19682 6.59045 6.59045C-2.19682 15.3777 -2.19682 29.6223 6.59045 38.4095C15.3777 47.1968 29.6223 47.1968 38.4095 38.4095ZM35.5173 35.5173C38.9697 32.0649 40.9092 27.3824 40.9092 22.5C40.9092 17.6176 38.9697 12.9351 35.5173 9.48273C32.0649 6.03033 27.3824 4.0908 22.5 4.0908C17.6176 4.0908 12.9351 6.03033 9.48273 9.48273C6.03033 12.9351 4.0908 17.6176 4.0908 22.5C4.0908 27.3824 6.03033 32.0649 9.48273 35.5173C12.9351 38.9697 17.6176 40.9092 22.5 40.9092C27.3824 40.9092 32.0649 38.9697 35.5173 35.5173Z" fill="#0E8E60"/>
</svg></span></> )}
              {/* <button
                type="submit"
                className="h-3rem my-3 border-none   bg-white subbtn cursor-pointer"
              >
                Submit
              </button>
              <span className="-ml-3 mt-1"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="25" viewBox="0 0 45 45" fill="none">
  <path d="M22.6065 28.2897L25.4988 31.1819L34.1797 22.5031L25.5008 13.8242L22.6086 16.7185L26.3456 20.4576H10.929V24.5485H26.3456L22.6065 28.2897Z" fill="#0E8E60"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4095 38.4095C47.1968 29.6223 47.1968 15.3777 38.4095 6.59045C29.6223 -2.19682 15.3777 -2.19682 6.59045 6.59045C-2.19682 15.3777 -2.19682 29.6223 6.59045 38.4095C15.3777 47.1968 29.6223 47.1968 38.4095 38.4095ZM35.5173 35.5173C38.9697 32.0649 40.9092 27.3824 40.9092 22.5C40.9092 17.6176 38.9697 12.9351 35.5173 9.48273C32.0649 6.03033 27.3824 4.0908 22.5 4.0908C17.6176 4.0908 12.9351 6.03033 9.48273 9.48273C6.03033 12.9351 4.0908 17.6176 4.0908 22.5C4.0908 27.3824 6.03033 32.0649 9.48273 35.5173C12.9351 38.9697 17.6176 40.9092 22.5 40.9092C27.3824 40.9092 32.0649 38.9697 35.5173 35.5173Z" fill="#0E8E60"/>
</svg></span> */}
              </div>
              {/* <span type="submit">Submit</span> */}
            </form>
          </section>
       </div>
          <div    className="w-full  mt-4 flex flex-column gap-4    justify-content-between align-items-center" >
          <div className="w-10 flex flex-column">
        <h3 className="cntctlbl2 text-left mb-2">Based in</h3>
        <h3 className="cntctlbl3 text-left mt-1">Plot No 65, Nishikethan Apartments,<br/>

6th Floor, near Parvath Nagar,<br/>

Survey of India Colony, Madhapur,<br/>

Hyderabad, Telangana, India 500081 <br/></h3>
      </div>
      <div  className="w-10 flex flex-column " >
        <h3 className="cntctlbl2 text-left mb-1">Contact</h3>
        <h3 className="cntctlbl3 text-left mt-1">info@lstms.com</h3>
        <h3 className="cntctlbl3 text-left mt-1">63030 75924</h3>

      </div>
      <div className="w-10">
 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.0983936417842!2d78.39343117462839!3d17.455004100854826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91448fe3527d%3A0x6488e0c69de7acca!2sBrainyBits!5e0!3m2!1sen!2sin!4v1707887894988!5m2!1sen!2sin"
  width="100%" height="230"
   style={{border:"0"}}
    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 </div>
      <div className="flex mt-3  align-items-center gap-3"  >
     
<span className=" cursor-pointer"><Image src={Insta2} width='42' alt="twitter" className='cursor-pointer' onClick={()=>window.open('https://www.instagram.com/brainybits.india/', '_blank')}  /></span>

<span className=" cursor-pointer"><Image src={twitter} width='42' alt="twitter" className='cursor-pointer' onClick={()=>window.open('https://twitter.com/BrainyBits23', '_blank')}  /></span>
<span className=" cursor-pointer"><Image src={linkedIn} width='42' alt="linkedin" className='cursor-pointer' onClick={()=>window.open('https://www.linkedin.com/company/brainybits/', '_blank')}  /></span>

<span className=' cursor-pointer' onClick={()=>window.open('https://www.youtube.com/channel/UCbLz1MOl-sfCzyPHkTMr5WA', '_blank')} ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="#0E8E60"/>
  <path d="M36.4105 17.4925C36.2965 17.0455 35.9725 16.708 35.602 16.6045C34.945 16.42 31.7515 16 25.0015 16C18.2515 16 15.0595 16.42 14.398 16.6045C14.032 16.7065 13.708 17.044 13.5925 17.4925C13.429 18.1285 13.0015 20.7925 13.0015 25C13.0015 29.206 13.429 31.87 13.5925 32.509C13.7065 32.9545 14.0305 33.292 14.3995 33.394C15.0595 33.58 18.25 34 25 34C31.75 34 34.9435 33.58 35.6035 33.3955C35.9695 33.2935 36.2935 32.956 36.409 32.5075C36.574 31.8715 37 29.2 37 25C37 20.8 36.574 18.13 36.4105 17.4925ZM39.316 16.747C40.0015 19.42 40.0015 25 40.0015 25C40.0015 25 40.0015 30.58 39.316 33.253C38.935 34.7305 37.8205 35.893 36.409 36.286C33.8455 37 25 37 25 37C25 37 16.1605 37 13.5925 36.286C12.175 35.887 11.062 34.726 10.6855 33.253C10 30.58 10 25 10 25C10 25 10 19.42 10.6855 16.747C11.0665 15.2695 12.181 14.107 13.5925 13.714C16.162 13 25.0015 13 25.0015 13C25.0015 13 33.8455 13 36.409 13.714C37.825 14.113 38.9395 15.274 39.316 16.747ZM22.0015 30.25V19.75L31.0015 25L22.0015 30.25Z" fill="white"/>
</svg></span>
<span onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092299548567', '_blank')} className='cursor-pointer'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48" fill="none">
  <g clip-path="url(#clip0_4240_10489)">
    <path d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 35.9789 8.77641 45.908 20.25 47.7084V30.9375H14.1562V24H20.25V18.7125C20.25 12.6975 23.8331 9.375 29.3152 9.375C31.9402 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6613C28.68 15.75 27.75 17.6002 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7084C39.2236 45.908 48 35.9789 48 24Z" fill="#0E8E60"/>
  </g>
  <defs>
    <clipPath id="clip0_4240_10489">
      <rect width="48" height="48" fill="white"/>
    </clipPath>
  </defs>
</svg></span>
<span className="cursor-pointer" onClick={()=>window.open('https://wa.aisensy.com/E39QBf', '_blank')}><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="#0E8E60"/>
  <path d="M34.2424 15.7527C33.0405 14.5448 31.609 13.5871 30.0313 12.9354C28.4535 12.2838 26.7612 11.9512 25.0529 11.9571C17.8954 11.9571 12.0619 17.7614 12.0619 24.8832C12.0619 27.1658 12.6649 29.3831 13.7923 31.3397L11.957 38.044L18.8393 36.244C20.7401 37.2744 22.8768 37.8222 25.0529 37.8222C32.2105 37.8222 38.044 32.0179 38.044 24.8962C38.044 21.4397 36.6938 18.1919 34.2424 15.7527ZM25.0529 35.631C23.1128 35.631 21.212 35.1092 19.5472 34.131L19.1539 33.8962L15.0639 34.9657L16.1519 31.0005L15.8897 30.5962C14.8118 28.8835 14.2395 26.9039 14.238 24.8832C14.238 18.9614 19.0883 14.1354 25.0398 14.1354C27.9238 14.1354 30.6374 15.2571 32.6693 17.2919C33.6754 18.2883 34.4727 19.4736 35.015 20.7789C35.5573 22.0843 35.8338 23.4837 35.8286 24.8962C35.8548 30.8179 31.0044 35.631 25.0529 35.631ZM30.9782 27.5962C30.6505 27.4397 29.0512 26.6571 28.7628 26.5397C28.4613 26.4353 28.2515 26.3832 28.0287 26.6962C27.8058 27.0223 27.1897 27.7527 27.0062 27.9614C26.8227 28.1831 26.626 28.2092 26.2983 28.0397C25.9706 27.8831 24.9219 27.531 23.6896 26.4353C22.7195 25.5745 22.0772 24.5179 21.8806 24.1919C21.697 23.8658 21.8543 23.6962 22.0248 23.5266C22.169 23.3832 22.3525 23.1484 22.5098 22.9658C22.6671 22.7832 22.7326 22.6397 22.8375 22.431C22.9424 22.2093 22.89 22.0266 22.8113 21.8701C22.7326 21.7136 22.0772 20.1223 21.815 19.4701C21.5528 18.844 21.2775 18.9223 21.0809 18.9093H20.4517C20.2288 18.9093 19.888 18.9875 19.5865 19.3136C19.2981 19.6397 18.4591 20.4223 18.4591 22.0136C18.4591 23.6049 19.6258 25.144 19.7831 25.3527C19.9404 25.5745 22.0772 28.8353 25.3282 30.231C26.1017 30.5701 26.7047 30.7657 27.1766 30.9092C27.95 31.1571 28.6579 31.1179 29.2216 31.0397C29.8508 30.9484 31.1486 30.2571 31.4108 29.5005C31.6861 28.744 31.6861 28.1049 31.5943 27.9614C31.5026 27.8179 31.3059 27.7527 30.9782 27.5962Z" fill="white"/>
</svg></span>

      </div>

    </div>
    </div>
    
</div>

   
        <Dialog  header={false}  className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0   "  onHide={() => { setVisible(false)}} visible={visible}   breakpoints={{ '960px': '75vw', '638px': '100vw' }}>
      <style>
    {`
      .p-dialog .p-dialog-header {
        display: none !important;
      }
      .p-dialog .p-dialog-content:last-of-type {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    `}
  </style>
                           <h2 className="text-center pt-5"> Thank You! </h2>
              <h3 className="mt-2  m-auto text-center " >Our Team Will Contact You in 24 Hours! </h3>
              <div className="w-full flex justify-content-center align-items- mt-4" >
        <Button label="OK" icon="pi pi-check"  onClick={() => {
          setVisible(false);
          setNumber("");
          setMessage("");
          setEmail("");
          setName("");
        }} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
              </Dialog>
      {/* <Latestnews /> */}
      <Footer />
    </div>
    </>
  );
}

export default SelfContactUs;
