import "./navbar.css";
import { Button } from "primereact/button";
import WebsiteLogo from "../../../asserts/WebsiteLogo.png";
import bbcolor from "../../../asserts/bbColor.png";
import { Sidebar } from "primereact/sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Signup from "../SelfSignup/Signup";

import Profile from "../Profile";
import Profilesmall from "../Profilesmall";
function Navbar({ background }) {
  const isSmallScreen = window.innerWidth < 768;
  console.log(background);
  const [visibleRight, setVisibleRight] = useState(false);
  const navigate=useNavigate()
  return (
    <nav
      className={`w-full p-4 md:px-8  h-4rem md:h-4rem flex align-items-center  justify-content-between  ${
        background === "green" ? "navdiv" : "navdiv2"
      }`}
    >
     
        {background === "green" ? (
          <img src={WebsiteLogo} className="h-3rem " alt="logo"  onClick={()=>navigate("/self-learning")}/>
        ) : (
          <img src={bbcolor} className="h-2rem " alt="logo" onClick={()=>navigate("/self-learning")}/>
        )}
   
      <ul className="list-none hidden w-6 justify-content-between  md:flex text-white">
        <Link to="/self-courses" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Courses
          </li>
        </Link>
        <Link to="/" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Blog
          </li>
        </Link>
        <Link to="/" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            About Us
          </li>
        </Link>

        <Link to="/" className="no-underline text-white">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Contact Us
          </li>
        </Link>
        <Link to="/self-cart" className="no-underline text-white ">
          <li
            className={`${
              background === "green" ? "headers" : "headers2"
            } text-lg no-underline`}
          >
            Cart
          </li>
        </Link>
      </ul>
      <div
        style={{
          display: isSmallScreen ? "none" : "",
        }}
      >
        <Profile />
      </div>

      {/* <Button className={`${background==='green'?'signupbtn' : 'signupbtn2'} hidden md:block pt-2`} >Sign up</Button>  */}
      {/* <Signup background={background} /> */}

      <Button
        icon="pi pi-bars"
        rounded
        text
        severity="success"
        aria-label="Cancel"
        className={`md:hidden flex ${
          background === "green" ? "text-white" : ""
        }  text-2xl`}
        onClick={(e) => {
          setVisibleRight(true);
        }}
      />
      <Sidebar
        visible={visibleRight}
        closeIcon={<i className="pi pi-times" style={{ color: "white" }}></i>}
        position="right"
        className="sidebarofmobile w-6 navdiv"
        onHide={() => setVisibleRight(false)}
      >
        <ul className="list-none flex flex-column w-full h-10rem justify-content-between p-0  text-white">
          <Link to="/ " className="no-underline  text-white">
            <li className="headers text-sm">Blog</li>
          </Link>
          <Link to="/self-courses" className="no-underline  text-white">
            <li className="headers text-sm">Courses</li>
          </Link>
          <Link to="/" className="no-underline  text-white">
            <li className="headers text-sm">About Us</li>
          </Link>
          <Link to="/" className="no-underline text-white">
            <li className="headers text-sm">Contact Us</li>
          </Link>
          <Link to="/self-cart" className="no-underline text-white ">
            <li className="headers text-lg">Cart</li>
          </Link>
        </ul>
        <Profilesmall />
        <Button className="signupbtn ">Sign up</Button>
      </Sidebar>
    </nav>
  );
}

export default Navbar;
