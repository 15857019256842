// import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./Signin.css";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { LogInfunction, SignupReset } from "../../../Redux/SelfLearning/SelfAction/Auth.action";
import ReactCardFlip from "react-card-flip";
import axios from "axios";
import { forgotPasswordApi } from "../../../Api";
const initial={
  email_id: "",
  password: "",
}
export default function SignIn({ background }) {
  const [loginData,setLoginData]=useState(initial) 
  const [visible, setVisible] = useState(false);
  const [check,setCheck]=useState(false)
  const toast = useRef(null);
  const dispatch=useDispatch()
  const {message,token,loading} =useSelector(store=>store.auth)
    console.log("login",token,"loading",loading)
    const handleChange=(e)=>{
      setCheck(false)
      const {name,value}=e.target 
      setLoginData({...loginData,[name]:value})
      }

      const handleSubmit=(e)=>{
        e.preventDefault()
        console.log(loginData);
        setCheck(true)
        if(loginData.password !== "" && loginData.email_id !== ""){ 
          dispatch(LogInfunction(loginData))
        //  setVisible(false)
        }
       
        }


        const showError = (message) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
          });
        };
      
        const showSuccess = (message) => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
          });
        };

useEffect(()=>{
  console.log(message);
if(message){ showError(message)
dispatch(SignupReset())
}
else{
  setVisible(false)
  // setVisible2(true)
}
},[message])

const [flip, setFlip] = useState(false);
const [flip2,setFlip2]=useState(false)
const [forgetEmail,SetForgetEmail]=useState("")
const [fgloader,setForgotLoader]=useState(false)
const handleForget=async()=>{
  if(forgetEmail==""){
    setFlip2(true)
  }
  else{
    setForgotLoader(true)
    console.log(forgetEmail);
    let payload={
      environment: "dev",
      email_id: forgetEmail
  }
    let response=await axios.post(forgotPasswordApi,payload)
    console.log(response.data);
    if(response.data.key=='email_sent'){
      showSuccess('Email_sent')
      setFlip(false)
      setVisible(false)
      setForgotLoader(false)
    }
    else{
      setVisible(true)
      showError("Something went wrong")
      setForgotLoader(false)
    }
  }
}




  return (
    <> 
      <div className=" flex ">
        {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
        <Button className={`${background=="green"?"loginbtnclr":"loginbtnclr2"} mt-4 -ml-2 md:ml-0 md:mt-0`} onClick={(e) => { setVisible(true) }}>Log in </Button>
        <Toast ref={toast} />
     
        <Dialog
          header={flip?`Forgot Password!`:`Log in your Account!`}
          className=".p-dialog .p-dialog-header .p-dialog-title md:w-3 dghdr mb-0  "
          visible={visible}
          onHide={() =>{ setVisible(false); setFlip(false)}}
          // maximizable
          breakpoints={{ "960px": "75vw", "638px": "100vw" }}
        >
           <ReactCardFlip isFlipped={flip}
            flipDirection="vertical">
            <>
          <span className="my-2  dgdesc">To enjoy all of our cool features</span>
          <form className="mt-3" onSubmit={handleSubmit}>
            <div className=" w-full flex flex-column gap-2">
              <label htmlFor="" className="lbl mt-3">
                Email ID <span className="lblimp">*</span>
              </label>
              <InputText
                id="username"
                aria-describedby="username-help"
                className="h-3rem border-round" name="email_id" onChange={handleChange}
              />
                   <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.email_id==""?"Email is missing":"":""}</span> 
            </div>
            <div className=" w-full flex flex-column gap-2">
              <label htmlFor="" className="lbl mt-3">
                Password <span className="lblimp">*</span>
              </label>
              <InputText
                id="password"
                aria-describedby="username-help" type="password"
                className="h-3rem border-round" name="password" onChange={handleChange}
              />
                   <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.password==""?"Password is missing":"":""}</span> 
            </div>
            <span className=" text-sm cursor-pointer my-4" style={{color:"#0E8E60"}} onClick={() => setFlip(!flip)}>
                Forgot your password ?
              </span>
              {loading? <Button className="dgsignupbtn w-full mt-5" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} />:   <Button label="Sign in"  type="submit" className="dgsignupbtn w-full mt-5"/>}

            {/* <Button className="dgsignupbtn w-full mt-5" type="submit">{loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Sign in"}</Button> */}
          </form>
          </>
          <>
          <span className="my-0 dgdesc"> Don’t worry we can help you out! if you still remember <br/>your email
            address you can quickly reset your password.</span>
            <div className=" w-full flex flex-column gap-2">
              <label htmlFor="" className="lbl mt-3">
                Email ID <span className="lblimp">*</span>
              </label>
              <InputText
                id="username"
                aria-describedby="username-help"
                className="h-3rem border-round" name="email_id" value={forgetEmail} onChange={(e)=>{SetForgetEmail(e.target.value);setFlip2(false)}}
              />
                   <span className="-mt-2 text-xs" style={{color:"red"}}>{flip2==true?forgetEmail==""?"Email is missing":"":""}</span> 
            </div>
           
            <span className=" text-sm cursor-pointer my-4" style={{color:"#0E8E60"}} onClick={() => setFlip(!flip)}>
                Back to Login !
              </span>
            <Button className="dgsignupbtn w-full mt-3" style={{color:"#0E8E60"}} onClick={handleForget}>{fgloader?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Request change"}</Button>
         
          </>
          {/* <div>
          <ReactCardFlip isFlipped={flip2}
            flipDirection="vertical">
          <>
          <span className="my-0 dgdesc"> Don’t worry we can help you out! if you still remember <br/>your email
            address you can quickly reset your password.</span>
            <div className=" w-full flex flex-column gap-2">
              <label htmlFor="" className="lbl mt-3">
                Email ID <span className="lblimp">*</span>
              </label>
              <InputText
                id="username"
                aria-describedby="username-help"
                className="h-3rem" name="email_id" onChange={handleChange}
              />
                   <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.email_id==""?"Email is missing":"":""}</span> 
            </div>
           
            <span className=" text-sm cursor-pointer my-4" style={{color:"#0E8E60"}} onClick={() => setFlip(!flip)}>
                Back to Login !
              </span>
            <Button className="dgsignupbtn w-full mt-3" style={{color:"#0E8E60"}} onClick={() => setFlip2(!flip2)}>Request change</Button>
         
          </>
          <>
          <span className="my-0 dgdesc"> Change Password</span>
            <div className=" w-full flex flex-column gap-2">
              <label htmlFor="" className="lbl mt-3">
                Email ID <span className="lblimp">*</span>
              </label>
              <InputText
                id="username"
                aria-describedby="username-help"
                className="h-3rem" name="email_id" onChange={handleChange}
              />
                   <span className="-mt-2 text-xs" style={{color:"red"}}>{check==true?loginData.email_id==""?"Email is missing":"":""}</span> 
            </div>
           
          
            <Button className="dgsignupbtn w-full mt-3"  onClick={() => setFlip2(!flip2)}>Change Password</Button>
          </>
          </ReactCardFlip>

          </div> */}
          
          </ReactCardFlip>

        </Dialog>
       

      </div>
    </>
  );
}
