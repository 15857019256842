import "../privacypolicy/privacypolicy.css";
import Navbar from "../navbar/navbar";
import Footer from "../Footer/Footer";
import { useEffect } from "react";

function OfflineTermsandConditions() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="flex flex-column justify-content-between align-items-center h-full w-full overflow-y-auto">
      <Navbar />
      <div className="flex w-11 flex-column px-2 md:px-5 text-left pb-6 ">
        <h1 className="text-5xl font text_color my-4 md:my-6">
          Terms and Conditions
        </h1>
        <p className="font para-lines my-1">
          Welcome to BrainyBits, an Offline platform that provides e-learning
          services. These terms and conditions (“Terms”) govern your access to
          and use of the BrainyBits platform, so please read them carefully.
          These terms and conditions (“Terms and Conditions”) control your use
          of this website BrainyBits.com (“Website”). In these Terms and
          Conditions, “BrainyBits” is referred to as the “Company”, “us,” or
          “we.” ‘You’ refers as user or a paying customer. If you are a company
          or another person who gives access to company products, you agree to
          take responsibility in full in case of damages or indemnification that
          could properly lie against the customer.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Acceptance of Terms
        </h4>
        <p className="font para-lines my-1">
          By accessing or using the BrainyBits platform, you agree to these
          Terms. If you do not agree to these Terms, you may not use the
          platform.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Registration and Account
        </h4>
        <p className="font para-lines my-1">
          To access certain features of the platform, you may be required to
          create an account. You agree to provide accurate and complete
          information when registering and to update your information promptly
          if there are any changes. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account. You agree to notify us immediately if
          you become aware of any unauthorized use of your account.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Use of the Platform
        </h4>
        <p className="font para-lines my-1">
          BrainyBits provides Offline courses, tutorials, and other educational
          materials. You are granted a non-exclusive, non-transferable, limited
          license to access and use the platform for your personal and
          non-commercial use only. You are not permitted to reproduce,
          distribute, modify, or create derivative works of the platform or any
          content on the platform without our prior written consent.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Payment and Fees
        </h4>
        <p className="font para-lines my-1">
          Access to our platform is subject to payment of applicable fees. All
          fees are non-refundable except as required by law. We reserve the
          right to change our fees at any time, but we will provide you with
          reasonable notice of any fee changes.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">Content</h4>
        <p className="font para-lines my-1">
          All content on our platform, including but not limited to text,
          graphics, images, videos, and audio, is owned by us or our licensors
          and is protected by intellectual property laws. You may not copy,
          reproduce, distribute, or display any content on our platform without
          our prior written consent.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          User Conduct
        </h4>
        <p className="font para-lines my-1">
          You agree to use our platform only for lawful purposes and in
          accordance with these Terms. You may not use our platform to:
        </p>
        <ul className="pl-4">
          <li className="font para-lines my-1">
            Post or transmit any content that is unlawful, harmful, threatening,
            abusive, harassing, defamatory, vulgar, obscene, or otherwise
            objectionable.
          </li>
          <li className="font para-lines my-1">
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
          </li>
          <li className="font para-lines my-1">
            Attempt to gain unauthorized access to our platform, or any servers,
            computer systems, or networks connected to our platform; or
          </li>
          <li className="font para-lines my-1">
            Interfere with the proper working of our platform or any activities
            conducted on our platform.
          </li>
        </ul>
        <h4 className="font text_color para-lines font-bold mt-4">
          Termination
        </h4>
        <p className="font para-lines my-1 mb-5">
          We reserve the right to terminate your access to our platform, with or
          without cause, at any time and without prior notice. Upon termination,
          you must immediately cease all use of our platform. Disclaimer of
          Warranties Our platform is provided on an “as is” and “as available”
          basis without any warranties of any kind, either express or implied.
          We do not warrant that our platform will be uninterrupted, error-free,
          or free from viruses or other harmful components.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Limitation of Liability
        </h4>
        <p className="font para-lines my-1 mb-5">
          In no event shall we be liable for any indirect, incidental, special,
          or consequential damages arising out of or in connection with your use
          of our platform. Our total liability for all claims arising out of or
          in connection with these Terms shall not exceed the total amount of
          fees paid by you to us for access to our platform during the twelve
          (12) months immediately preceding the event giving rise to the claim.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Governing Law and Jurisdiction
        </h4>
        <p className="font para-lines my-1 mb-5">
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which our platform is registered. Any
          legal action or proceeding arising out of or in connection with these
          Terms shall be brought in the courts of that jurisdiction.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">Changes</h4>
        <p className="font para-lines my-1 mb-5">
          We reserve the right to update or modify these Terms at any time,
          without prior notice. Your continued use of our platform after any
          changes to these Terms will constitute your acceptance of such
          changes.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Entire Agreement
        </h4>
        <p className="font para-lines my-1 mb-5">
          These Terms, together with any other policies or agreements referenced
          herein, constitute the entire agreement between you and BrainyBits
          with respect to your use of our platform and supersede all prior or
          contemporaneous communications and proposals, whether oral or written,
          between you and BrainyBits.
        </p>
        <p className="font para-lines my-1 mb-5">Severability</p>
        <p className="font para-lines my-1 mb-5">
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions shall remain in full force and
          effect.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Pricing Disclaimer
        </h4>
        <p className="font para-lines my-1 mb-5">
          All prices, products and offers of BrainyBits website are subject to
          change without notice. While we make sure to provide most accurate and
          up-to-date information, in some cases one or more items on our web
          site may be priced incorrectly. This might happen due to human errors,
          digital images, technical error or a mismatch in pricing information
          received from our suppliers.
        </p>
        <p className="font para-lines my-1 mb-5">
          BrainyBits reserves the right to change prices for all our products,
          offers or deals. These changes are done due to market conditions,
          course termination, providers, price changes, errors in advertisements
          and other mitigating circumstances. However, the price you paid at the
          time of purchase still holds for you.
        </p>
        <p className="font para-lines my-1 mb-5">
          Proprietary Use of BrainyBits Study Material
        </p>
        <p className="font para-lines my-1 mb-5">
          BrainyBits reserves the right to change prices for all our products,
          offers or deals. These changes are done due to market conditions,
          course termination, providers, price changes, errors in advertisements
          and other mitigating circumstances. However, the price you paid at the
          time of purchase still holds for you.
        </p>
        <h4 className="font text_color para-lines font-bold mt-4">
          Contact Us
        </h4>
        <p className="font para-lines my-1 mb-5">
          If you have any questions or comments about these Terms, please
          contact us at info@brainybits.in
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default OfflineTermsandConditions;
