import axios from "axios"
import { INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS, OLDYNAMICCOURSES_SUCCESS } from "../OnlineTypes/OnlineCourse.types"
import { environment, onlineDynamicCourseApi, onlineIndividualCourseDetailsApi } from "../../../Api"



export const IndividualFetchCourse=(id)=>async(dispatch)=>{
    dispatch({type:INDIVIDUALCOURSEDETAILS_REQUEST})

    const payload={
        environment: environment,
        course_id: id
    }
    // console.log(id,payload)
    try{
     let response=await axios.post(onlineIndividualCourseDetailsApi,payload)
     console.log(response.data)
     dispatch({type:INDIVIDUALCOURSEDETAILS_SUCCESS,payload:response.data})
    }
    catch(e){
        console.log(e);
        dispatch({type:INDIVIDUALCOURSEDETAILS_SUCCESS,payload:[]})
    }
}


export const OlFetchDynamicCourse=(param)=>async(dispatch)=>{
    const payload={
        category: param
}
    try{
        let response=await axios.post(onlineDynamicCourseApi,payload)

        dispatch({type:OLDYNAMICCOURSES_SUCCESS,payload:response.data})
    }
    catch(e){

    }
}