import { INDIVIDUALCOURSEDETAILS_SUCCESS } from "../OnlineTypes/OnlineCourse.types";
import { FETCH_SUCCESS } from "../OnlineTypes/OnlineHome.types";

  const initial = {
   loading:false,
   error:false,
   data:[],
  };
  
  export const OnlineHomeReducer = (state = initial, { type, payload }) => {
    switch (type) {
     
    //   case LOGOUT: {
    //     console.log("logout")
    //     localStorage.removeItem("token");
    //     return {
    //       ...state,
    //       isAuth: false,
    //       token: ""
    //     };
    //   }
    case FETCH_SUCCESS:{
      return{
        ...state,data:payload
      }
    }

      default:
        return state;
    }
  };
  