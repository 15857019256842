import OfflineFooter from "../Footer/Footer";
import OfflineNavbar from "../navbar/navbar";
import Latestnews from "../LatestNews/LatestNews";

import Empoweringggg from "../../asserts/Empoweringggg.png"
import Boysjumping from "../../asserts/Boysjumping.png"
import Educationisthepassporttofuture from "../../asserts/Educationisthepassporttothefuture.png"

import "./motivation.css";
import { useEffect } from "react";

function OfflineMotivation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="w-full h-full">
        <OfflineNavbar background={"green"}/>
        <div className="h-auto w-full flex flex-column px-9 align-items-center mdastersindatasceince">
        <div className="w-11 md:px-8 h-auto md:h-auto  flex flex-column md:flex-row gap-4 md:justify-content-between greenbaground my-6 md:my-7">
          <div className="flex flex-column font-family md:w-6 w-full md:my-5 text-left  justify-content-start">
            <h1 className="master-heading hidden md:block">
              Empowering minds through online education{" "}
            </h1>
            <h1 className="master-heading0 block md:hidden ">
              Empowering minds through online education{" "}
            </h1>
            <p className="master-pragraph hidden md:block my-3">
              Education is the key to a brighter future, and we're dedicated to
              making that future accessible to everyone.Our online courses cover
              a wide range of subjects, from business and technology to the
              humanities and the arts. We offer courses for learners of all
              levels, from beginners to experts, and we're committed to
              providing a supportive, engaging learning environment that helps
              you stay motivated and on track. When you learn with us, you'll
              join a community of learners from aroundthe world who share your
              passion for education and your commitment to personal growth.
            </p>
            <p className="master-pragraph0 block md:hidden my-3">
              Education is the key to a brighter future, and we're dedicated to
              making that future accessible to everyone.Our online courses cover
              a wide range of subjects, from business and technology to the
              humanities and the arts. We offer courses for learners of all
              levels, from beginners to experts, and we're committed to
              providing a supportive, engaging learning environment that helps
              you stay motivated and on track. When you learn with us, you'll
              join a community of learners from aroundthe world who share your
              passion for education and your commitment to personal growth.
            </p>
          </div>
          <img
            src={Empoweringggg}
            alt="img"
            className="w-8  align-self-center md:flex justify-content-end md:w-4 "
          />
        </div>
        <div className="w-11 px-3 md:px-8 aboutdetailscontainer h-auto md:h-auto  flex flex-column md:flex-row md:justify-content-between md:gap-3 mb-5 md:mb-0 md:my-7">
        <h1 className="heading block md:hidden w-full text-left text-xl md:text-3xl my-0 md:w-9">
              Alone we can do so little, together we can do so much
            </h1>
          <img
            src={Boysjumping}
            alt="img"
            className="w-10 align-self-center md:w-4"
          />
          <div className="flex font-family flex-column md:w-6 w-full md:my-5 text-color text-left  justify-content-center">
            <h1 className="heading hidden md:block w-full text-xl md:text-3xl  md:w-9">
              Alone we can do so little, together we can do so much
            </h1>
            <p className="master-pragraph hidden md:block my-3 text-color">
              We're a group of software developers who share a passion for
              education and a commitment to making it accessible to everyone.
              That's why we've created an online learning platform that's
              designed to meet the needs of learners of all levels and
              backgrounds. With our platform, you can explore new subjects,
              develop new skills, and connect with a community of learners who
              share your interests and aspirations. Whether you're looking to
              advance your career or simply learn something new, we're here to
              help you achieve your goals.
            </p>
            <p className="master-pragraph0 block md:hidden my-3 text-color">
              We're a group of software developers who share a passion for
              education and a commitment to making it accessible to everyone.
              That's why we've created an online learning platform that's
              designed to meet the needs of learners of all levels and
              backgrounds. With our platform, you can explore new subjects,
              develop new skills, and connect with a community of learners who
              share your interests and aspirations. Whether you're looking to
              advance your career or simply learn something new, we're here to
              help you achieve your goals.
            </p>
          </div>
        </div>
        <div className="w-11 px-3 md:px-8 aboutdetailscontainer h-auto md:h-auto  flex flex-column md:flex-row md:justify-content-between md:gap-4 mb-5 md:mb-0 md:my-8">
        <h1 className="heading block md:hidden text-left  w-full text-xl md:text-3xl  md:w-9">
              Education is the passport to the future
            </h1>
          <img
            src={Educationisthepassporttofuture}
            alt="img"
            className="w-10 align-self-center md:w-4 flex md:hidden "
          />
          <div className="flex font-family flex-column md:w-6 w-full md:my-5 text-color text-left justify-content-center">
            <h1 className="heading hidden md:block  w-full text-xl md:text-3xl  md:w-9">
              Education is the passport to the future
            </h1>
            <p className="master-pragraph hidden md:block my-3 text-color">
              Careers in learning software technologies can be highly rewarding
              and offer a range of opportunities for personal and professional
              growth. One of the main achievements of pursuing a career in this
              field is the ability to create products and services that have a
              positive impact on people's lives. Learning software technologies
              can be used to build educational platforms, e-learning tools, and
              other software applications that help individuals acquire new
              skills, expand their knowledge base, and improve their career
              prospects. In addition to the satisfaction of developing such
              products, careers in learning software technologies can also offer
              lucrative salaries, flexible work arrangements, and opportunities
              for continuous learning and development. As technology continues
              to evolve and transform the way we learn, professionals in this
              field have the chance to be at the forefront of innovation and
              contribute to shaping the future of education and training.
            </p>
            <p className="master-pragraph0 block md:hidden my-3 text-color">
              Careers in learning software technologies can be highly rewarding
              and offer a range of opportunities for personal and professional
              growth. One of the main achievements of pursuing a career in this
              field is the ability to create products and services that have a
              positive impact on people's lives. Learning software technologies
              can be used to build educational platforms, e-learning tools, and
              other software applications that help individuals acquire new
              skills, expand their knowledge base, and improve their career
              prospects. In addition to the satisfaction of developing such
              products, careers in learning software technologies can also offer
              lucrative salaries, flexible work arrangements, and opportunities
              for continuous learning and development. As technology continues
              to evolve and transform the way we learn, professionals in this
              field have the chance to be at the forefront of innovation and
              contribute to shaping the future of education and training.
            </p>
          </div>
          <img
            src={Educationisthepassporttofuture}
            alt="img"
            className="w-6 align-self-center md:w-4 hidden md:flex abouteeducationimportnt"
          />
        </div>
      </div>
      <Latestnews />
      <OfflineFooter />
    </div>
  );
}

export default OfflineMotivation;
