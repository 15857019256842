import React from "react";
import "./component.css";
import fb from "../../../asserts/fb.jpg";
import insta from "../../../asserts/Insta.png";
import twitter from "../../../asserts/Twitter.png";
import youtube from "../../../asserts/YTube.png";
import copyright from "../../../asserts/copyright.png";
import linkedIn from "../../../asserts/linkedIn2.png"
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <> 
      <div
        style={{ backgroundColor: "#0E8E60",marginTop:"auto" }}
        className="hidden md:flex  w-full flex-column h-20rem  p-6"
      >
        <div className="flex w-full">
          <div className="w-5 flex flex-column pr-8 pl-8 gap-1">
            <span className="footerlogo">BrainyBits</span>
            <span className="footerdesc">
              Join BrainyBits today and start <br /> your journey towards
              mastering <br /> the intelligence!
            </span>
          </div>
          <div className="w-4 flex p-2 ml-8">
            <div className="w-6 flex flex-column pl-5">
              <h3 className="footerhead w-full  mt-0">General</h3>
              <h3
                className="footerbody w-full cursor-pointer mt-0 mb-1"
                onClick={() => navigate("/self-learning/about-us")}
              >
                About Us
              </h3>
              <h3
                className="footerbody w-full cursor-pointer mt-0 mb-1"
                onClick={() => navigate("/self-learning/contact-us")}
              >
                Contact Us
              </h3>
              <h3
                className="footerbody w-full cursor-pointer mt-0"
                onClick={() => navigate("/self-learning/blog")}
              >
                Blog
              </h3>
            </div>
            <div className="w-6 flex flex-column ">
              <h3 className="footerhead w-full mt-0">Policies</h3>
              <h3 className="footerbody w-full mt-0 mb-1 cursor-pointer" onClick={() => navigate("/self-learning/refund-policy")}>Refund Policy</h3>
              <h3 className="footerbody w-full mt-0 mb-1 cursor-pointer" onClick={() => navigate("/self-learning/terms-and-condition")}>
                Terms & conditions
              </h3>
              <h3 className="footerbody w-full mt-0 cursor-pointer" onClick={() => navigate("/self-learning/privacy-policy")}>Privacy Policy</h3>
            </div>
          </div>
          <div className="w-4 flex flex-column pt-1">
            <h3 className="footerhead w-full mt-0">Get in touch</h3>
            <h3 className="footerbody w-full mt-0">
              Follow us on social media and <br /> stay updated with the latest
              <br /> information about our services
            </h3>
            <div className="flex mt-2 gap-2">
              <Image
                src={insta}
                width="35"
                alt="insta"
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/brainybits.india/",
                    "_blank"
                  )
                }
              />
              
              <Image
                src={twitter}
                width="35"
                alt="twitter"
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://twitter.com/BrainyBits23", "_blank")
                }
              />
              <Image
                src={linkedIn}
                width="35"
                alt="twitter"
                className="cursor-pointer"
                onClick={() =>
                  window.open("https://www.linkedin.com/company/brainybits/", "_blank")
                }
              />
             
              <svg   className="cursor-pointer"  onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCbLz1MOl-sfCzyPHkTMr5WA",
                    "_blank"
                  )
                } xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M36.4105 17.4925C36.2965 17.0455 35.9725 16.708 35.602 16.6045C34.945 16.42 31.7515 16 25.0015 16C18.2515 16 15.0595 16.42 14.398 16.6045C14.032 16.7065 13.708 17.044 13.5925 17.4925C13.429 18.1285 13.0015 20.7925 13.0015 25C13.0015 29.206 13.429 31.87 13.5925 32.509C13.7065 32.9545 14.0305 33.292 14.3995 33.394C15.0595 33.58 18.25 34 25 34C31.75 34 34.9435 33.58 35.6035 33.3955C35.9695 33.2935 36.2935 32.956 36.409 32.5075C36.574 31.8715 37 29.2 37 25C37 20.8 36.574 18.13 36.4105 17.4925ZM39.316 16.747C40.0015 19.42 40.0015 25 40.0015 25C40.0015 25 40.0015 30.58 39.316 33.253C38.935 34.7305 37.8205 35.893 36.409 36.286C33.8455 37 25 37 25 37C25 37 16.1605 37 13.5925 36.286C12.175 35.887 11.062 34.726 10.6855 33.253C10 30.58 10 25 10 25C10 25 10 19.42 10.6855 16.747C11.0665 15.2695 12.181 14.107 13.5925 13.714C16.162 13 25.0015 13 25.0015 13C25.0015 13 33.8455 13 36.409 13.714C37.825 14.113 38.9395 15.274 39.316 16.747ZM22.0015 30.25V19.75L31.0015 25L22.0015 30.25Z" fill="#0E8E60"/>
</svg>
             <svg  onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092299548567', '_blank')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M27.6667 27.25H31.4762L33 21.25H27.6667V18.25C27.6667 16.705 27.6667 15.25 30.7143 15.25H33V10.21C32.5032 10.1455 30.6274 10 28.6465 10C24.5093 10 21.5714 12.4855 21.5714 17.05V21.25H17V27.25H21.5714V40H27.6667V27.25Z" fill="#0E8E60"/>
</svg>
<svg className="cursor-pointer" onClick={()=>window.open('https://wa.aisensy.com/E39QBf', '_blank')} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M34.2424 15.7527C33.0405 14.5448 31.609 13.5871 30.0313 12.9354C28.4535 12.2838 26.7612 11.9512 25.0529 11.9571C17.8954 11.9571 12.0619 17.7614 12.0619 24.8832C12.0619 27.1658 12.6649 29.3831 13.7923 31.3397L11.957 38.044L18.8393 36.244C20.7401 37.2744 22.8768 37.8222 25.0529 37.8222C32.2105 37.8222 38.044 32.0179 38.044 24.8962C38.044 21.4397 36.6938 18.1919 34.2424 15.7527ZM25.0529 35.631C23.1128 35.631 21.212 35.1092 19.5472 34.131L19.1539 33.8962L15.0639 34.9657L16.1519 31.0005L15.8897 30.5962C14.8118 28.8835 14.2395 26.9039 14.238 24.8832C14.238 18.9614 19.0883 14.1354 25.0398 14.1354C27.9238 14.1354 30.6374 15.2571 32.6693 17.2919C33.6754 18.2883 34.4727 19.4736 35.015 20.7789C35.5573 22.0843 35.8338 23.4837 35.8286 24.8962C35.8548 30.8179 31.0044 35.631 25.0529 35.631ZM30.9782 27.5962C30.6505 27.4397 29.0512 26.6571 28.7628 26.5397C28.4613 26.4353 28.2515 26.3832 28.0287 26.6962C27.8058 27.0223 27.1897 27.7527 27.0062 27.9614C26.8227 28.1831 26.626 28.2092 26.2983 28.0397C25.9706 27.8831 24.9219 27.531 23.6896 26.4353C22.7195 25.5745 22.0772 24.5179 21.8806 24.1919C21.697 23.8658 21.8543 23.6962 22.0248 23.5266C22.169 23.3832 22.3525 23.1484 22.5098 22.9658C22.6671 22.7832 22.7326 22.6397 22.8375 22.431C22.9424 22.2093 22.89 22.0266 22.8113 21.8701C22.7326 21.7136 22.0772 20.1223 21.815 19.4701C21.5528 18.844 21.2775 18.9223 21.0809 18.9093H20.4517C20.2288 18.9093 19.888 18.9875 19.5865 19.3136C19.2981 19.6397 18.4591 20.4223 18.4591 22.0136C18.4591 23.6049 19.6258 25.144 19.7831 25.3527C19.9404 25.5745 22.0772 28.8353 25.3282 30.231C26.1017 30.5701 26.7047 30.7657 27.1766 30.9092C27.95 31.1571 28.6579 31.1179 29.2216 31.0397C29.8508 30.9484 31.1486 30.2571 31.4108 29.5005C31.6861 28.744 31.6861 28.1049 31.5943 27.9614C31.5026 27.8179 31.3059 27.7527 30.9782 27.5962Z" fill="#0E8E60"/>
</svg>
            </div>
          </div>
        </div>
        <div className="flex justify-content-center align-items-center mt-6 mb-1 w-full">
          {/* <Image src={copyright} alt="copyright" className="-mt-1" /> */}
          <h1 className="footerlast">© 2024 - BrainyBits, All Rights Reserved.</h1>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#0E8E60",position:"relative",bottom:0 }}
        className="flex flex-column md:hidden  w-full    "
      >
        <div className="flex w-full pr-2">
          <div className="w-7 flex flex-column gap-1">
            <span className="footerlogo0 pl-3 pt-2">BrainyBits</span>
            <span className="footerdesc0 pl-3">
              Join BrainyBits today and start <br /> your journey towards
              mastering <br /> the intelligence!
            </span>
            <div className=" flex ">
              <div className="w-6 flex flex-column p-2  ">
                <h3 className="footerhead0 w-full mt-0 text-left ml-2">General</h3>
                <h3 className="footerbody0 w-full mt-0 mb-1 text-left ml-2"  onClick={() => navigate("/self-learning/about-us")}>About Us</h3>
                <h3 className="footerbody0 w-full mt-0 mb-1 text-left ml-2"  onClick={() => navigate("/self-learning/contact-us")}>Contact Us </h3>
                <h3 className="footerbody0 w-full mt-0 text-left ml-2"  onClick={() => navigate("/self-learning/blog")}>Blog</h3>
              </div>
              <div className="w-6 flex flex-column  p-2 ">
                <h3 className="footerhead0 w-full mt-0 text-left -ml-2">Policies</h3>
                <h3 className="footerbody0 w-full mt-0 mb-1 text-left -ml-2 " onClick={() => navigate("/self-learning/refund-policy")}>
                  Refund Policy
                </h3>
                <h3 className="footerbody0 w-full mt-0 mb-1 -ml-2 text-left" onClick={() => navigate("/self-learning/terms-and-condition")}>
                  Terms & conditions
                </h3>
                <h3 className="footerbody0 w-full mt-0 -ml-3" onClick={() => navigate("/self-learning/privacy-policy")}>
                  Privacy Policy
                </h3>
              </div>
            </div>
          </div>
         

          <div  className="w-5 flex flex-column pt-1 ">
            <h3 className="footerhead0 w-full mb-0 mt-3 text-left">
              Get in touch
            </h3>
            <h3 className="footerbody0 text-left w-full mb-0 mt-0">
              Follow us on social media and  stay updated with the latest
              information about our services
            </h3>
            <div className="flex flex-wrap align-items-center gap-1 mt-3">
                <Image
                  src={insta}
                  alt="insta"
                  width="29vw"
                  className="cursor-pointer mt-1"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/brainybits.india/",
                      "_blank"
                    )
                  }
                />
           
                <Image
                  src={twitter}
                  alt="twitter"
                  width="29vw"
                  className="cursor-pointer mt-1"
                  onClick={() =>
                    window.open("https://twitter.com/BrainyBits23", "_blank")
                  }
                />
                <Image
                  src={linkedIn}
                  alt="twitter"
                  width="29vw"
                  className="cursor-pointer mt-1"
                  onClick={() =>
                    window.open("https://www.linkedin.com/company/brainybits/", "_blank")
                  }
                />
           
                
                <svg   className="cursor-pointer"  onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCbLz1MOl-sfCzyPHkTMr5WA",
                    "_blank"
                  )
                } xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M36.4105 17.4925C36.2965 17.0455 35.9725 16.708 35.602 16.6045C34.945 16.42 31.7515 16 25.0015 16C18.2515 16 15.0595 16.42 14.398 16.6045C14.032 16.7065 13.708 17.044 13.5925 17.4925C13.429 18.1285 13.0015 20.7925 13.0015 25C13.0015 29.206 13.429 31.87 13.5925 32.509C13.7065 32.9545 14.0305 33.292 14.3995 33.394C15.0595 33.58 18.25 34 25 34C31.75 34 34.9435 33.58 35.6035 33.3955C35.9695 33.2935 36.2935 32.956 36.409 32.5075C36.574 31.8715 37 29.2 37 25C37 20.8 36.574 18.13 36.4105 17.4925ZM39.316 16.747C40.0015 19.42 40.0015 25 40.0015 25C40.0015 25 40.0015 30.58 39.316 33.253C38.935 34.7305 37.8205 35.893 36.409 36.286C33.8455 37 25 37 25 37C25 37 16.1605 37 13.5925 36.286C12.175 35.887 11.062 34.726 10.6855 33.253C10 30.58 10 25 10 25C10 25 10 19.42 10.6855 16.747C11.0665 15.2695 12.181 14.107 13.5925 13.714C16.162 13 25.0015 13 25.0015 13C25.0015 13 33.8455 13 36.409 13.714C37.825 14.113 38.9395 15.274 39.316 16.747ZM22.0015 30.25V19.75L31.0015 25L22.0015 30.25Z" fill="#0E8E60"/>
</svg>
             
              {/* <img src={fb} alt="facebook" className='cursor-pointer' onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092299548567', '_blank')} style={{borderRadius:"50%",height:"75%"}} /> */}
              <svg  onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092299548567', '_blank')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M27.6667 27.25H31.4762L33 21.25H27.6667V18.25C27.6667 16.705 27.6667 15.25 30.7143 15.25H33V10.21C32.5032 10.1455 30.6274 10 28.6465 10C24.5093 10 21.5714 12.4855 21.5714 17.05V21.25H17V27.25H21.5714V40H27.6667V27.25Z" fill="#0E8E60"/>
</svg>
<svg className="cursor-pointer" onClick={()=>window.open('https://wa.aisensy.com/E39QBf', '_blank')} xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 50 50" fill="none">
  <circle cx="25" cy="25" r="25" fill="white"/>
  <path d="M34.2424 15.7527C33.0405 14.5448 31.609 13.5871 30.0313 12.9354C28.4535 12.2838 26.7612 11.9512 25.0529 11.9571C17.8954 11.9571 12.0619 17.7614 12.0619 24.8832C12.0619 27.1658 12.6649 29.3831 13.7923 31.3397L11.957 38.044L18.8393 36.244C20.7401 37.2744 22.8768 37.8222 25.0529 37.8222C32.2105 37.8222 38.044 32.0179 38.044 24.8962C38.044 21.4397 36.6938 18.1919 34.2424 15.7527ZM25.0529 35.631C23.1128 35.631 21.212 35.1092 19.5472 34.131L19.1539 33.8962L15.0639 34.9657L16.1519 31.0005L15.8897 30.5962C14.8118 28.8835 14.2395 26.9039 14.238 24.8832C14.238 18.9614 19.0883 14.1354 25.0398 14.1354C27.9238 14.1354 30.6374 15.2571 32.6693 17.2919C33.6754 18.2883 34.4727 19.4736 35.015 20.7789C35.5573 22.0843 35.8338 23.4837 35.8286 24.8962C35.8548 30.8179 31.0044 35.631 25.0529 35.631ZM30.9782 27.5962C30.6505 27.4397 29.0512 26.6571 28.7628 26.5397C28.4613 26.4353 28.2515 26.3832 28.0287 26.6962C27.8058 27.0223 27.1897 27.7527 27.0062 27.9614C26.8227 28.1831 26.626 28.2092 26.2983 28.0397C25.9706 27.8831 24.9219 27.531 23.6896 26.4353C22.7195 25.5745 22.0772 24.5179 21.8806 24.1919C21.697 23.8658 21.8543 23.6962 22.0248 23.5266C22.169 23.3832 22.3525 23.1484 22.5098 22.9658C22.6671 22.7832 22.7326 22.6397 22.8375 22.431C22.9424 22.2093 22.89 22.0266 22.8113 21.8701C22.7326 21.7136 22.0772 20.1223 21.815 19.4701C21.5528 18.844 21.2775 18.9223 21.0809 18.9093H20.4517C20.2288 18.9093 19.888 18.9875 19.5865 19.3136C19.2981 19.6397 18.4591 20.4223 18.4591 22.0136C18.4591 23.6049 19.6258 25.144 19.7831 25.3527C19.9404 25.5745 22.0772 28.8353 25.3282 30.231C26.1017 30.5701 26.7047 30.7657 27.1766 30.9092C27.95 31.1571 28.6579 31.1179 29.2216 31.0397C29.8508 30.9484 31.1486 30.2571 31.4108 29.5005C31.6861 28.744 31.6861 28.1049 31.5943 27.9614C31.5026 27.8179 31.3059 27.7527 30.9782 27.5962Z" fill="#0E8E60"/>
</svg>
            </div>
          </div>
        </div>
        <div className="flex justify-content-center align-items-center mt-4 mb-4 ">
          {/* <Image src={copyright} alt="copyright" className="-mt-1" /> */}
          <h1 className="footerlast">© 2024 - BrainyBits, All Rights Reserved.</h1>
        </div>
      </div>
    </>
  );
}
