export const ADDCART_REQUEST = "ADDCART_REQUEST";
export const ADDCART_SUCCESS = "ADDCART_SUCCESS";
export const ADDCART_FAIL = "ADDCART_FAIL";



export const FETCHCART_REQUEST='FETCHCART_REQUEST';
export const FETCHCART_SUCCESS='FETCHCART_SUCCESS';
export const FETCHCART_FAIL="FETCHCART_FAIL";




export const REMOVECART_REQUEST='REMOVECART_REQUEST';
export const REMOVECART_SUCCESS='REMOVECART_SUCCESS';
export const REMOVECART_FAIL="REMOVECART_FAIL";


export const CHECKOUT_REQUEST='CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS='CHECKOUT_SUCCESS';
export const CHECKOUT_FAIL="CHECKOUT_FAIL";

export const PRICE_SCHEDULE_SUCCESS='PRICE_SCHEDULE'