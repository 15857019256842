import axios from "axios"
import { LikeBlogApi, commentBlogApi, dislikeBlogApi, environment, fetchIndividualBlogApi, fetchblogApi, fetchblogcategorywiseApi } from "../../../Api"
import { COMMENT_ADD_SUCCESS, FETCH_BLOG_SUCCESS, FETCH_INDIVIDUAL_BLOG_REQUEST, FETCH_INDIVIDUAL_BLOG_SUCCESS, RESET } from "../SelfTypes/Blog.types"


export const FetchBlogHome=()=>async(dispatch)=>{
  dispatch({type:FETCH_INDIVIDUAL_BLOG_REQUEST})

    try{
        let response=await axios.post(fetchblogApi,{"environment":environment})
        console.log(response.data)
        dispatch({type:FETCH_BLOG_SUCCESS,payload:response.data})
       
      }catch(e){
        console.log(e)
            }


}
export const FetchBlogByCategory=(category)=>async(dispatch)=>{
  dispatch({type:FETCH_INDIVIDUAL_BLOG_REQUEST})

    try{
        let response=await axios.post(fetchblogcategorywiseApi,{"environment":environment})
        console.log(response.data)
        dispatch({type:FETCH_BLOG_SUCCESS,payload:response.data})
       
      }catch(e){
        console.log(e)
            }


}


export const FetchIndividualBlogHome=(id)=>async(dispatch)=>{
   let payload= {
        environment: "dev",
        blog_id:id
    }
    console.log("blg",payload);
    dispatch({type:FETCH_INDIVIDUAL_BLOG_REQUEST})
    try{
        let response=await axios.post(fetchIndividualBlogApi,payload)
        console.log(response.data)
        dispatch({type:FETCH_INDIVIDUAL_BLOG_SUCCESS,payload:response.data})
       
      }catch(e){
        console.log(e)
            }


}


export const LikeBlog=(id)=>async(dispatch)=>{
  let payload= {
    environment: "dev",
    blog_id:id
}
try{
  let response=await axios.post(LikeBlogApi,payload)
  console.log(response.data)
  dispatch({type:COMMENT_ADD_SUCCESS,payload:response.data.key})

}catch(e){

}


}
export const DisLikeBlog=(id)=>async(dispatch)=>{
  let payload= {
    environment: "dev",
    blog_id:id
}
try{
  let response=await axios.post(dislikeBlogApi,payload)
  console.log(response.data)
  dispatch({type:COMMENT_ADD_SUCCESS,payload:response.data.key})

}catch(e){

}


}

export const AddCommnentBlog=(data)=>async(dispatch)=>{
  let payload= {
    environment: "dev",...data
    // blog_id:id,
    // comment : "very nice nad good  blog"
}
try{
  let response=await axios.post(commentBlogApi,payload)
  console.log(response.data)
dispatch({type:COMMENT_ADD_SUCCESS,payload:response.data.key})
}catch(e){

}


}
export const ResetMessageBlog=()=>(dispatch)=>{
  dispatch({type:RESET})
}